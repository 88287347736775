import { getStorageItem } from "./Common/Utility";
import { Post } from "./Common/WebRequest";

async function handleRefreshToken() {
  try {
    const loginData = JSON.parse(localStorage.getItem("login"));
    //TODO check if seassionId =''  then delte login from storage
    console.log(new Date().toUTCString() + ": Token expired, Refreshing......");
    var loginDataIdentifier = await getStorageItem(
      "login",
      "loginDataIdentifier"
    );
    const token = loginData?.token;
    const refreshToken = loginData?.refreshToken;
    var request = { token: token, refreshToken: refreshToken };
    const response = await Post("Login/RefreshToken", request);

    console.log(new Date().toUTCString() + ": Getting new Token: ", response);

    if (response.isSuccess) {
      localStorage.setItem(
        "login",
        JSON.stringify({
          isLogin: true,
          userName: response.userName,
          userId: response.userId,
          token: response.token,
          refreshToken: response.refreshToken,
          systemGroupName: response.systemGroupName,
          sessionId: response.sessionId,
          isAdmin: response.isAdmin,
          systemType: response.systemType,
          loginDataIdentifier: loginDataIdentifier,
        })
      );
    } else {
      console.log("Token..expired");
      localStorage.removeItem("login");
      window.location.href = "/LoginPage";
      return null;
    }
  } catch (error) {
    console.error(
      "Token refresh failed! Please log in again.",
      error.toString()
    );
  }
}

export default handleRefreshToken;
