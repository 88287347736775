import { Get, Put } from "../../Common/WebRequest";
import "./SystemUpdate.css";

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  Grid,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  getStorageItem,
  toJsonDateTime,
  toJsonDateTimeWithoutMilsec,
  toUTC,
} from "../../Common/Utility";
import SyncIcon from "@mui/icons-material/Sync";
import SeenIcon from "@mui/icons-material/RemoveRedEye";
import { formatDate, formatDateTime, isToday } from "./HelperDate";

const theme = createTheme({
  typography: {
    h4: {
      color: "#2196F3", // Light blue color
      marginTop: "15px", // Add a tiny margin above the heading
    },
  },
});

const SystemUpdate = () => {
  const [updatesData, setUpdatesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUpdates = updatesData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(updatesData.length / itemsPerPage);
  const [userName, setUserName] = useState("");
  const [seenUpdates, setSeenUpdates] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setUserName(await getStorageItem("login", "userName"));
      } catch (error) {
        console.error("Error fetching UserName: ", error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!userName) return;
        setUserName(await getStorageItem("login", "userName"));
        const res = await Get(`SystemUpdate/IsActive/${userName}/true`);

        if (res.isSuccess) {
          const data = res.result;
          setUpdatesData(data);
        }
      } catch (error) {
        console.error("Error fetching SystemUpdate..data:", error);
      }
    })();
  }, [userName]);

  const handleRefresh = async () => {
    try {
      const res = await Get(`SystemUpdate/IsActive/${userName}/true`);

      if (res.isSuccess) {
        const data = res.result;
        setUpdatesData(data);
      }
    } catch (error) {
      console.error("Error fetching SystemUpdate..data:", error);
    }
  };

  async function handleUpdateStatus(updateId) {
    try {
      var seenUpdatedItem = updatesData.find(
        (update) => update.id === updateId
      );
      seenUpdatedItem.seenByUsers = userName;
      var response = await Put(
        `SystemUpdate/LastSeenUser`,
        updateId,
        seenUpdatedItem
      );
      if (response.isSuccess) {
        await handleRefresh();
        return true;
      } else return false;
    } catch (ex) {
      console.log("Error: ", ex.toString());
      return false;
    }
  }

  async function handleSeenButtonClick(updateId) {
    var result = await handleUpdateStatus(updateId);
    if (result)
      setSeenUpdates((prevSeenUpdates) => [...prevSeenUpdates, updateId]);
    else {
      setUpdatesData((prevUpdatesData) =>
        prevUpdatesData.map((update) =>
          update.id === updateId ? { ...update, error: true } : update
        )
      );
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" style={{ marginLeft: 0 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          System Updates
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SyncIcon />}
            style={{ marginLeft: "20px" }}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {currentUpdates.map((update) => (
            <Grid item xs={12} key={update.id}>
              <Card className="no-class-card">
                <CardContent className="card-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                      right: "15px",
                      top: "15px",
                    }}
                  >
                    {!seenUpdates.includes(update.id) &&
                      update.messageWithNew.length > 0 && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          endIcon={<SeenIcon />}
                          style={{ marginTop: "8px" }}
                          className={update.error ? "error-button" : ""}
                          onClick={() => handleSeenButtonClick(update.id)}
                        >
                          {update.error ? "Error" : "Mark as Seen"}
                        </Button>
                      )}
                  </div>
                  <Typography variant="h6">
                    {update.updateType}{" "}
                    <span className="smallItalicRed">
                      {update.messageWithNew}
                    </span>
                  </Typography>
                  <Typography variant="body1" className="description">
                    {update.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`date ${
                      isToday(update.activeDate) ? "greenForDate" : ""
                    }`}
                  >
                    Date: {formatDateTime(update.activeDate)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            variant={index + 1 === currentPage ? "contained" : "outlined"}
            onClick={() => handlePageChange(index + 1)}
            style={{ marginRight: "5px" }}
          >
            {index + 1}
          </Button>
        ))}
      </Container>
    </ThemeProvider>
  );
};

export default SystemUpdate;
