import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
  initialState: "",
  name: "branchSlice",
  reducers: {
    selectBranch: (state, action) => {
      return action.payload;
    },
  },
});

export const { selectBranch } = branchSlice.actions;
export default branchSlice.reducer;
