import React, { useEffect } from "react";
import { useState } from "react";
import { getValue, slice } from "../../Common/Utility";
import { Delete, Get } from "../../Common/WebRequest";
import columnsBuilder from "./columnsBuilder";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import { DownloadPdf, GetPdf } from "../../Common/WebRequestPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

import DeleteConfirmation from "../DeleteConfirmation";

import { WebRequestPdfViewer } from "../../Common/WebRequestPdfViewer";
import { data } from "jquery";

function StudentPdfFilesGrid(props) {
  const [error, setError] = useState("");
  const [pdfFilesData, setPdfFilesData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  let StudentId = 0;
  let reload = false;
  let PdfCreated = false;
  /*if (props.StudentId >= 0) {
    StudentId = props.StudentId;
  }*/
  //console.log("***** StudentId :", StudentId);
  StudentId = props.StudentId;
  reload = props.Reload;
  PdfCreated = props.PdfCreated;
  if (getValue(StudentId) === 0) {
    StudentId = 0;
  }
  useEffect(() => {
    (async () => {
      await loadPdfFilesData();
    })();
  }, [StudentId, reload, PdfCreated]);

  async function loadPdfFilesData() {
    //console.log("XXXXXX StudentId :", StudentId);
    setPdfFilesData([]);
    if (StudentId === 0) return;
    let res = {};
    let url = "Calculation/PdfShowAll/" + StudentId;

    res = await Get(url);
    if (res.isSuccess) {
      setError("");
      setPdfFilesData(res.result);
    } else {
      if (res.Error.includes("wrong/")) return;
      setError(" " + res.Status + "/" + res.Error);
    }
  }

  async function handleOnCellClick(params) {
    if (params.field === "fileName") {
      handleDownloadPdfFile(params.value);
    } else if (params.field === "actions") {
      await handleDownloadPdfFile(params.row.fileName);
    }
  }
  async function handleDownloadPdfFile(fileName) {
    console.log("fileName :", fileName);
    if (fileName.length > 0) {
      let props = { path: "Calculation/pdf/" + fileName };
      await WebRequestPdfViewer(props);
    }
  }
  function onRowsSelectionHandler(ids) {
    const selectedRowsData = ids.map((id) =>
      pdfFilesData.find((row) => row.rechnungsnummer === id)
    );
    setSelectedRows(selectedRowsData);
  }
  async function handleDeleteClick() {
    if (selectedRows.length > 0) {
      setDeleteMessage(`Möchten Sie wirklich löschen?`);
      setDisplayConfirmationModal(true);
    }
  }
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const getRowClassName = (params) => {
    if (getValue(params.row.rechnungsnummer) === 0) {
      return "red-row";
    } else {
      return "";
    }
  };

  async function doDeleteApi() {
    if (selectedRows.length > 0) {
      let i = 0;
      let AllRechnungsnummer = [];
      for (i = 0; i < selectedRows.length; i++) {
        AllRechnungsnummer.push(selectedRows[i].rechnungsnummer);
      }
      const requestBody = {
        rechnungsnummern: AllRechnungsnummer,
      };
      /* AllRechnungsnummer = AllRechnungsnummer.toString().replace(
        new RegExp(",", "g"),
        ";"
      );*/
      if (AllRechnungsnummer) {
        let res = await Delete("Calculation/DeletePdf", "", requestBody);
        if (res.isSuccess) {
          let filteredData = pdfFilesData.filter((v) => {
            props.handleShow();
            return !AllRechnungsnummer.includes(v.rechnungsnummer);
          });
          setPdfFilesData(filteredData);
        } else {
          setError(res.Error);
        }
      }
    }
  }
  async function handleDownloadClick() {
    let selectedRowsFilepath = selectedRows.map((row) => row.fileName);

    try {
      for (let filePath of selectedRowsFilepath) {
        await DownloadPdf("Calculation/download/" + filePath);
      }
    } catch (error) {
      console.error("Error fetching PDFs:", error);
      throw error;
    }
  }

  const getSumOfAmmount = () => {
    const sum = pdfFilesData.reduce((accumulator, object) => {
      return accumulator + object.sumAmount;
    }, 0);
    return sum.toLocaleString("en") + " €";
  };
  const SummationFooter = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
        borderTop: "1px solid #ccc", // Add a top border
        paddingTop: 5, // Add some padding above the line
      }}
    >
      <span style={{ paddingBottom: 8, fontWeight: "bold" }}>
        Summe: {getSumOfAmmount()}
      </span>
    </div>
  );
  return (
    <>
      {error.length > 0 && !error.includes("No Results") && (
        <div className="alert alert-danger" role="alert">
          <CheckError />
          {slice(error, 1000, "...")}
        </div>
      )}

      <div>{getGrid()}</div>
    </>
  );
  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <Button
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadClick}
            disabled={selectedRows.length === 0}
          >
            Download
          </Button>

          <Button
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick}
            disabled={selectedRows.length === 0}
          >
            Delete
          </Button>

          <GridToolbarExport
            disabled={pdfFilesData.length === 0}
            csvOptions={{
              fileName: "Pdf-Dateien",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
        </GridToolbarContainer>
      </Box>
    );
  }
  function getGrid() {
    return (
      <>
        <Box
          /* sx={{
            width: "100%",
            height: "300px",
          }}*/
          sx={{
            width: "100%",
            height: "fit-content",
            maxHeight: 300,
            minHeight: 200, // Minimum height of 200px
            overflow: "auto",
          }}
        >
          <DataGrid
            rowsPerPageOptions={[]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            onCellClick={handleOnCellClick}
            slots={{ toolbar: DefinedToolbar, footer: SummationFooter }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            density="compact"
            //rowSelection={false}
            rows={pdfFilesData}
            columns={columnsBuilder()}
            getRowId={(row) => row.rechnungsnummer}
            disableColumnMenu
            disableColumnSelector
            hideFooterPagination={true}
            getRowClassName={getRowClassName}
            // pageSize={100}
            // rowsPerPageOptions={[100]}
          />
        </Box>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={handleAfterDeleteClick}
          hideModal={hideConfirmationModal}
          message={deleteMessage}
        />
      </>
    );
  }
}

export default StudentPdfFilesGrid;
