import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
const DialogBoxWithYesNo = ({ open, onClose, onYes, title, text }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" startIcon={<UndoIcon />}>
          Abbrechen
        </Button>
        <Button
          onClick={onYes}
          variant="contained"
          endIcon={<DoneIcon />}
          autoFocus
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxWithYesNo;
