import "./DropDownStudent.css";

import { useEffect } from "react";
import { useState } from "react";

import { Get } from "../../../Common/WebRequest";
import { useRef } from "react";

function DropDownStudent(props) {
  const [error, setError] = useState("");
  const [studentsData, setStudentsData] = useState([]);

  let studentId = 0;
  //let selectRef = useRef(props.selectRef);

  if (props.studentID >= 0) {
    studentId = props.studentID;
  }

  useEffect(() => {
    (async () => {
      if (props.branchID === 0 || props.regionID === 0) {
        console.log("branch 0");
        setStudentsData([]);
        return;
      }

      let res;
      if (props.stateToFetch === "orders")
        res = await Get(
          "student/FromOrdersOnly/" + props.regionID + "/" + props.branchID
        );
      else res = await Get("student/" + props.regionID + "/" + props.branchID);

      if (res.isSuccess) {
        setStudentsData(res.result);
        props.onPopulateStudents([-1]);
        //props.onPopulateStudents(res.result.map((student) => student.id));
      } else {
        console.log("No students found");
        setStudentsData([]);
        setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      }
    })();
  }, [props.regionID, props.branchID]);

  return (
    <>
      <select
        className="form-select"
        value={studentId}
        id="student"
        onChange={props.onChange}
      >
        <option id={0} key={0} value={0} label="@ALL"></option>
        {studentsData.map((student) => {
          return (
            <option
              id={student.id}
              key={student.id}
              value={student.Id}
              label={student.studentName}
            >
              {student.id}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default DropDownStudent;
