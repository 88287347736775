import "./Dialog.css";
import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import { useState } from "react";
import { Get } from "../../../Common/WebRequest";
import "./Dialog.css";

function CourseDialog({
  open,
  currStudentId,
  handleClose,
  handelCancel,
  selectedCoursesDefaultIds,
}) {
  const [coursesData, setCoursesData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    (async () => {
      await loadCourses();
    })();
  }, [open]);

  async function loadCourses() {
    setCoursesData([]);
    if (currStudentId === 0) return;
    let res = await Get(
      "TrackerStudent/CoursesNotInTrackerStudents/" + currStudentId
    );
    if (res.isSuccess) {
      setCoursesData(res.result);
      setSelectionModel(selectedCoursesDefaultIds);
    } /*else {
      setError(" " + res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    }*/
  }

  function handleAdd() {
    var selectedRowsData = selectionModel.map((id) =>
      coursesData.find((row) => row.id === id)
    );

    selectedRowsData = selectedRowsData.map((course) => {
      return {
        ...course,
        studentId: currStudentId,
      };
    });
    const sortedRowsData = [...selectedRowsData].sort((a, b) =>
      b.courseName.localeCompare(a.courseName)
    );

    console.log("Course.Dialog.handleAdd::: ", selectedRowsData);
    handleClose(sortedRowsData);
  }

  function handleClosed() {
    handelCancel();
  }

  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <GridToolbarQuickFilter />
        </GridToolbarContainer>
      </Box>
    );
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "courseName", headerName: "Fach", flex: 1 },
  ];
  return (
    <Dialog open={open} onClose={handleClosed}>
      <DialogTitle className="dialog-title-box">Kurs hinzufügen</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "350px",
            height: "500px",
          }}
        >
          <DataGrid
            checkboxSelection={true}
            //disableRowSelectionOnClick
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
            rows={coursesData}
            columns={columns}
            getRowId={(row) => row.id}
            density="compact"
            // slots={{ toolbar: DefinedToolbar }}
            slotProps={{
              toolbar: {
                // showQuickFilter: true,
              },
            }}
            initialState={{
              ...coursesData.initialState,
              columns: {
                ...coursesData.initialState?.columns,
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            disableColumnMenu
            disableColumnSelector
            hideFooterPagination={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClosed}
          startIcon={<UndoIcon />}
        >
          Abbrechen
        </Button>
        <Button
          disabled={
            selectedCoursesDefaultIds &&
            selectionModel.length === 0 &&
            selectedCoursesDefaultIds.length === 0
          }
          variant="contained"
          onClick={handleAdd}
          endIcon={<DoneIcon />}
        >
          Akzeptieren
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CourseDialog;
