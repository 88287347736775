import "./DropDownBranch.css";
import { useEffect, useState } from "react";
import { Get } from "../../../Common/WebRequest";

function DropDownBranch(props) {
  const [error, setError] = useState("");
  const [branchsData, setBranchsData] = useState([]);

  let branchId = 0;
  let withAll = props.withAll;
  if (!withAll && props.branchID > 0 && props.regionID > 0) {
    branchId = props.branchID;
  } else branchId = props.branchID;

  useEffect(() => {
    (async () => {
      if (props.regionID === 0) return;
      let res = await Get("branch/region/" + props.regionID);

      if (res.isSuccess) {
        setBranchsData(res.result);
        if (withAll) {
          props.onPopulate([-1]);
        } else {
          props.onPopulate(res.result.map((branch) => branch.id));
        }
      } else {
        // set to @ALL
        setBranchsData([]);
        setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      }
    })();
  }, [props.regionID, withAll]);

  return (
    <>
      <select
        className="form-select"
        value={branchId}
        //defaultValue="2"
        id="branch"
        onChange={props.onChange}
      >
        {withAll ? (
          <option id={-1} key={-1} value={-1} label="@ALL"></option>
        ) : null}
        {branchsData.map((branch) => (
          <option key={branch.id} value={branch.Id} label={branch.branchName}>
            {branch.id}
          </option>
        ))}
      </select>
    </>
  );
}

export default DropDownBranch;
