import React from "react";
import "./DialogBoxYesNo.css";
import { useEffect } from "react";

function DialogBoxYesNo({ title, text, onClose }) {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      onClose(false);
    }
  };
  const handleYesClick = () => {
    onClose(true);
  };

  const handleNoClick = () => {
    onClose(false);
  };

  return (
    <div className="dialog-container">
      <div className="dialog-box">
        <h2>{title}</h2>
        <p>{text}</p>
        <div className="button-container">
          <button className="yes-button" onClick={handleYesClick}>
            ja
          </button>
          <button className="no-button" onClick={handleNoClick}>
            Nein
          </button>
        </div>
      </div>
    </div>
  );
}

export default DialogBoxYesNo;
