import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { green, blue, red } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";

import { Delete, Get, Put } from "../../Common/WebRequest";

import columnsBuilderDetails from "./columnsBuilderDetails";
import columnsBuilder from "./columnsBuilder";
import { DownloadPdf } from "../../Common/WebRequestPdf";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import DropDownRegion from "../../components/DropDown/Region/DropDownRegion";
import DropDownBranch from "../../components/DropDown/Branch/DropDownBranch";

import { getValue } from "../../Common/Utility";
import { WebRequestPdfViewer } from "../../Common/WebRequestPdfViewer";

export default function StudentCalculationTable() {
  const [calcData, setCalcData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const [detailsData, setDetailsData] = useState([]);
  const [regionId, setRegionId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [studentName, setStudentName] = useState("");

  const [rechnungsnummer, setRechnungsnummer] = useState("-");
  const [reload, setReload] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [sumOfAmounts, setSumOfAmounts] = useState(0);
  const [sumOfPayedAmounts, setSumOfPayedAmounts] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);

  let withAll = true; // @ALL   --> Region:@All , Branch:@All
  const gridContainerRef = useRef(null);
  // Delete Confirmation

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  useEffect(() => {
    (async () => {
      if (studentId === 0) {
        let regions = await Get("region");
        setRegionData(regions.result);
        if (regionId === 0) {
          setRegionId(-1);
          setBranchId(-1);
          /* setRegionId(
            regions.result[await getDefaultRegionIndex(regions.result)].id
          );*/
        }
      }
      if (regionId === 0 || branchId === 0) return;
      if (studentId === 0) {
        let res = await Get("Calculation/Summary/" + regionId + "/" + branchId);

        if (res.isSuccess) {
          setCalcData(res.result.calculationsSummaries);

          setSumOfAmounts(getSumOfAmount(res.result.calculationsSummaries));
          setSumOfPayedAmounts(
            getSumOfPayedAmount(res.result.calculationsSummaries)
          );
        } else {
          setSumOfAmounts(0);
          setSumOfPayedAmounts(0);
          if (!res.Error.includes("No Results found"))
            setError("Error: " + res.Status + "/" + res.Error);
        }
      }
      if (studentId === 0) return;
      let details = await Get(
        "Calculation/Details/" + branchId + "/" + rechnungsnummer
      );
      if (details.isSuccess) {
        setDetailsData(details.result);
      }
      if (gridContainerRef.current) {
        gridContainerRef.current.focus();
      }
    })();
  }, [regionId, branchId, rechnungsnummer, reload]);

  function getNextId(rechnungsnummer) {
    const srows = [...calcData].sort(
      (a, b) => a.rechnungsnummer - b.rechnungsnummer
    );
    const index = srows.findIndex(
      (row) => row.rechnungsnummer === rechnungsnummer
    );
    if (index < calcData.length - 1) return index + 1;
    return index;
  }

  function getPrevId(rechnungsnummer) {
    const srows = [...calcData].sort(
      (a, b) => a.rechnungsnummer - b.rechnungsnummer
    );
    const index = srows.findIndex(
      (row) => row.rechnungsnummer === rechnungsnummer
    );
    if (index > 0) return index - 1;
    return index;
  }
  const handleCellKeyDown = (params, event) => {
    if (event.key === "ArrowDown") {
      let nextId = getNextId(params.row.rechnungsnummer);
      setRechnungsnummer(calcData[nextId].rechnungsnummer);
      setStudentName(calcData[nextId].studentName);
      console.log(calcData[nextId].rechnungsnummer);
    } else if (event.key === "ArrowUp") {
      let prevId = getPrevId(params.row.rechnungsnummer);
      console.log(calcData[prevId].rechnungsnummer);
      setRechnungsnummer(calcData[prevId].rechnungsnummer);
      setStudentName(calcData[prevId].studentName);
    }
  };

  async function handleRowClick(params, event) {
    //event.defaultMuiPrevented = true;
    if (params.row) {
      //setBranchId(params.row.branchId);
      setStudentId(params.row.studentId);
      setRechnungsnummer(params.row.rechnungsnummer);
      setStudentName(params.row.studentName);
    }
  }
  function handleRefresh() {
    setCalcData([]);
    setDetailsData([]);
    //setBranchId(0);
    setStudentId(0);
    setRechnungsnummer("");
    setStudentName("");
    setReload(!reload);
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
    setDetailsData([]);
    setStudentName("");
  };
  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <Button
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadClick}
            disabled={selectedRows.length === 0}
          >
            Download
          </Button>
          <Button
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick}
            disabled={selectedRows.length === 0}
          >
            Delete
          </Button>

          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "Abrechnungsübersicht",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
          <Button
            color="primary"
            startIcon={<SyncIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  }
  function onRowsSelectionHandler(ids) {
    const selectedRowsData = ids.map((id) =>
      calcData.find((row) => row.rechnungsnummer === id)
    );
    setSelectedRows(selectedRowsData);
    if (selectedRowsData.length > 0) {
      setSumOfAmounts(getSumOfAmount(selectedRowsData));
      setSumOfPayedAmounts(getSumOfPayedAmount(selectedRowsData));
    } else {
      setSumOfAmounts(getSumOfAmount(calcData));
      setSumOfPayedAmounts(getSumOfPayedAmount(calcData));
    }
  }
  async function handleDeleteClick() {
    if (selectedRows.length > 0) {
      setDeleteMessage(`Möchten Sie wirklich löschen?`);
      setDisplayConfirmationModal(true);
    }
  }
  async function doDeleteApi() {
    if (selectedRows.length > 0) {
      let i = 0;
      let AllRechnungsnummer = [];
      for (i = 0; i < selectedRows.length; i++) {
        AllRechnungsnummer.push(selectedRows[i].rechnungsnummer);
      }

      /* AllRechnungsnummer = AllRechnungsnummer.toString().replace(
        new RegExp(",", "g"),
        ";"
      );*/
      const requestBody = {
        rechnungsnummern: AllRechnungsnummer,
      };
      if (AllRechnungsnummer) {
        let res = await Delete("Calculation/DeletePdf", "", requestBody);
        if (res.isSuccess) {
          let filteredData = calcData.filter((v) => {
            return !AllRechnungsnummer.includes(v.rechnungsnummer);
          });
          setCalcData(filteredData);
        } else {
          if (!res.Error.includes("No Results found")) {
            setError("Error: " + res.Error);
          }
        }
      }
    }
  }

  async function handleDownloadClick() {
    let selectedRowsFilepath = selectedRows.map((row) => row.pdfFileName);

    try {
      for (let filePath of selectedRowsFilepath) {
        await DownloadPdf("Calculation/download/" + filePath);
      }
    } catch (error) {
      console.error("Error fetching PDFs:", error);
      throw error;
    }
  }
  async function handleOnCellClick(params) {
    //apiRef.current.selectRow(params.rechnungsnummer);
    if (params.field === "pdfFileName") {
      let fileName = params.value;
      if (fileName.length > 0) {
        let props = { path: "Calculation/pdf/" + fileName };
        await WebRequestPdfViewer(props);
      }
    } else if (params.field === "isPayed") {
      //params.row.isPayed = !params.row.isPayed;

      const updatedRows = calcData.map((row) =>
        row.rechnungsnummer === params.id
          ? { ...row, isPayed: !row.isPayed }
          : row
      );
      setCalcData(updatedRows);

      const foundUpdatedRow = updatedRows.find(
        (row) => row.rechnungsnummer === params.id
      );

      setSumOfPayedAmounts(getSumOfPayedAmount(selectedRows));
      // Update DB Calculations.IsPayed

      await handleUpdateIsPayed(params.id, foundUpdatedRow.isPayed);

      const indexToUpdate = selectedRows.findIndex(
        (row) => row.rechnungsnummer === params.id
      );
      if (selectedRows.length !== 0) {
        if (indexToUpdate !== -1) selectedRows[indexToUpdate] = foundUpdatedRow;

        setSumOfPayedAmounts(getSumOfPayedAmount(selectedRows));
      } else {
        setSumOfPayedAmounts(getSumOfPayedAmount(updatedRows));
      }
    }
  }

  async function handleUpdateIsPayed(rechnungsNummer, isPayed) {
    let payedRechnung = {
      rechnungsNummer: rechnungsNummer,
      isPayed: isPayed,
    };
    let res = await Put("Calculation/UpdatePayedRechnung", "", payedRechnung);

    if (!res.isSuccess) {
      setError(res.Error);
    }
  }
  function handleRegionValue(e) {
    setRegionId((currentRegionId) => {
      return getValue(e.target.value);
    });
    //setStudentName("");
    if (withAll) {
      //-1  is : @All
      setBranchId(-1);
    }
    setStudentId(0);
    setRechnungsnummer("");
    setStudentName("");
    setCalcData([]);
    setDetailsData([]);
    setError("");
  }
  function handleBranchValue(e) {
    setBranchId((currentBranchId) => {
      return getValue(e.target.value);
    });
    //setStudentName("");
    console.log("Selected Branch = ", branchId);
    setStudentId(0);
    setRechnungsnummer("");
    setStudentName("");
    setCalcData([]);
    setDetailsData([]);
    setError("");
  }
  function handleOnBranchPopulate(branchIds) {
    if (branchIds.length > 0) {
      setBranchId(branchIds[0]);
      console.log("handleOnPopulate: ", branchIds);
    }
    //setBranchesIds(branches);
  }
  function getSumOfDetails() {
    const sum = detailsData.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
    return sum;
  }
  const getSumOfAmount = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.sumOfAmount;
    }, 0);
    return sum.toLocaleString("en") + " €";
  };

  const getSumOfPayedAmount = (data) => {
    const sum = data
      .filter((object) => object.isPayed)
      .reduce((accumulator, object) => {
        return accumulator + object.sumOfAmount;
      }, 0);

    return sum.toLocaleString("en") + " €";
  };

  return (
    <>
      <br />
      {/* <Button variant="contained" onClick={handleExportToCsv}>
        Export to CSV
      </Button> */}
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            paddingLeft={"10px"}
            color={blue[500]}
          >
            Abrechnungsübersicht
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2 }}
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Region
          </Typography>
          <FormControl>
            <DropDownRegion
              regionID={regionId}
              onChange={handleRegionValue}
              withAll={withAll}
              // onPopulate={handleOnPopulate}
            />
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginRight: 2 }}
          >
            Abrechnungstelle
          </Typography>
          <FormControl>
            <DropDownBranch
              regionID={regionId}
              branchID={branchId}
              onChange={handleBranchValue}
              onPopulate={handleOnBranchPopulate}
              withAll={withAll}
            />
          </FormControl>
        </Box>
      </Box>
      <br />
      <Typography
        variant="caption"
        component="h6"
        align="left"
        color={red.A400}
      >
        {error}
      </Typography>

      <div
        style={{ display: "flex", justifyContent: "left", paddingLeft: "20px" }}
      >
        <Typography
          variant="headline"
          component="h4"
          align="center"
          color={green[800]}
          style={{ marginRight: "20px" }}
        >
          Gesamt Bezahlt = {sumOfPayedAmounts}
        </Typography>
        <Typography
          variant="headline"
          component="h4"
          align="center"
          color={green.A700}
        >
          Gesamt = {sumOfAmounts}
        </Typography>
      </div>
      <Box
        sx={{
          width: "100%",
          height: "500px",
          "& .Mui-even": {
            backgroundColor: "#f3f7ff",
          },
          "& .Mui-odd": {
            backgroundColor: "#EEEEEE",
          },
          "& .MuiDataGrid-cell:hover": {
            backgroundColor: "#80b3ff",
          },
          "&& .MuiDataGrid-row:hover": {
            backgroundColor: "#cce0ff",
            /*  "@media (hover: none)": {
              backgroundColor: "transparent",
            },*/
            // color: "red"
          },
          "& .color--red--header": {
            backgroundColor: "rgba(255, 7, 0, 0.55)",
          },
          "& .color--green--header": {
            backgroundColor: "rgba(142, 200, 144, 0.91)",
          },
          "& .super-app.negative": {
            backgroundColor: "rgba(157, 255, 118, 0.49)",
            color: "#ff7773",
            fontWeight: "600",
          },
          "& .super-app.positive": {
            backgroundColor: "rgba(207, 255, 188, 0.48)",
            // backgroundColor: "#d47483",
            color: "#00c853",
            fontWeight: "600",
          },
          "& .super-app.notEmpty": {
            backgroundColor: "rgba(255, 119, 115, 0.19)",
            // backgroundColor: "#d47483",
            color: "#4A148C",
            fontWeight: "600",
          },
        }}
      >
        <DataGrid
          // apiRef={apiRef}
          checkboxSelection
          disableRowSelectionOnClick
          editable={true}
          editMode="cell"
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          onRowClick={handleRowClick}
          onCellClick={handleOnCellClick}
          onCellKeyDown={handleCellKeyDown}
          initialState={{
            ...calcData.initialState,
            columns: {
              ...calcData.initialState?.columns,
              columnVisibilityModel: {
                studentId: false,
                branchId: false,
              },
            },
            filter: {
              filterModel: {
                items: [
                  { field: "studentName", operator: "contains", value: "" },
                ],
              },
            },
          }}
          slots={{ toolbar: DefinedToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          density="compact"
          rows={calcData}
          columns={columnsBuilder({ regionData })}
          getRowId={(row) => row.rechnungsnummer}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
        />

        <Box
          sx={{
            width: "100%",
            height: "250px",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h5"
              bgcolor={blue[500]}
              color={"white"}
              fontWeight="bold"
            >
              {studentName}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                align="center"
                variant="h5"
                color={green.A700}
                fontWeight="bold"
              >
                Summe = {getSumOfDetails().toLocaleString("en")} €
              </Typography>
            </Box>
          </Box>
          <DataGrid
            hideFooterPagination={true}
            density="compact"
            rowSelection={false}
            rows={detailsData}
            columns={columnsBuilderDetails()}
            getRowId={(row) => row.id}
            rowsPerPageOptions={100}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 100, page: 1 },
              },
            }}
          />
        </Box>
      </Box>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        message={deleteMessage}
      />
    </>
  );
}
