import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import React, { useEffect, useState } from "react";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Get, Post, Put } from "../../Common/WebRequest";
import { getValue, slice } from "../../Common/Utility";

const initialFormState = {
  id: 0,
  teacherName: "",
  street: "",
  zip: "",
  regionId: 0,
  branchId: 0,
  tel: "",
};

const TeacherDialog = ({ open, onClose, onSave, TeacherId, relaod }) => {
  const [teacherData, setTeacherData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState(0);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    (async () => {
      try {
        setError("");
        if (getValue(TeacherId) === 0) {
          setTeacherData(initialFormState);
          let regions = await Get("region");
          if (!regions.isSuccess) {
            setError(regions.Error);
            return;
          }
          setRegionData(regions.result);
          setBranchData([]);
          setTitle("Lehrkraft hinzufügen");
          return;
        } else {
          let teacher = await Get("teacher/" + TeacherId);
          let regions = await Get("region");

          setTeacherData(teacher.result);
          setRegionData(regions.result);
          if (regions.isSuccess && teacher.isSuccess) {
            let branches = await Get(
              "branch/region/" + teacher.result.regionId
            );
            setBranchData(branches.result);
            setBranchId(teacher.result.branchId);
          }
          setTitle("Lehrkraft aktualisieren");
        }
      } catch (ex) {
        setError(ex);
      }
    })();
  }, [TeacherId, relaod]);

  async function handleChange(event) {
    const { name, value } = event.target;
    setTeacherData((prevteacherData) => ({
      ...prevteacherData,
      [name]: value,
    }));

    if (name === "branchId") {
      setBranchId(value);
    }

    // If the selected field is regionId, fetch branches based on the selected region's regionId
    if (name === "regionId") {
      const selectedRegion = regionData.find(
        (region) => region.id === parseInt(value, 10)
      );
      if (selectedRegion) {
        const regionBranches = await Get("branch/region/" + selectedRegion.id);
        setBranchData(regionBranches.result);
        if (regionBranches.result.length > 0)
          setBranchId(regionBranches.result[0].id);
        setTeacherData((prevteacherData) => ({
          ...prevteacherData,
          branchId: regionBranches.result[0].id,
        }));
      } else {
        setBranchData([]);
        setBranchId(0);
      }
    }
  }

  const handleCancel = () => {
    onClose();
    return;
    /*if (isModified) {
      if (
        window.confirm("You have unsaved changes. Do you want to discard them?")
      ) {
        onClose();
      }
    } else {
      onClose();
    }*/
  };

  async function handleSave() {
    var result;

    if (TeacherId > 0) {
      result = await Put("teacher", TeacherId, teacherData);
    } else {
      result = await Post("teacher", teacherData);
    }
    if (result.isSuccess) {
      onSave(true);
      onClose();
      setError("");
    } else {
      setError(result.Status + ": " + result.Error);
    }
    // onSave(teacherData);
    // DoSave API
  }

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          minHeight: "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        {error.length > 0 && !error.includes("No Results") && (
          <div className="alert alert-danger" role="alert">
            <CheckError />
            {slice(error, 1000, "...")}
          </div>
        )}
        <TextField
          autoFocus={true}
          name="teacherName"
          id="teacherName"
          label="Lehrkraft"
          required
          fullWidth
          value={teacherData && teacherData.teacherName}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            minLength: 3,

            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />
        <TextField
          name="street"
          id="street"
          label="Straße"
          fullWidth
          value={teacherData && teacherData.street}
          onChange={handleChange}
          inputProps={{
            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="zip"
          id="zip"
          label="PLZ"
          fullWidth
          value={teacherData && teacherData.zip}
          onChange={handleChange}
          autoComplete="off"
          inputProps={{
            maxLength: 5,
            minLength: 5,
            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel id="regionId">ORT (Region) *</InputLabel>
          <Select
            name="regionId"
            id="regionId"
            value={teacherData.regionId}
            onChange={handleChange}
            required
            label="ORT (Region)"
            autoComplete="off"
          >
            {regionData.length > 0 &&
              regionData.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.regionName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel id="branchId">Abrechnungstelle *</InputLabel>
          <Select
            name="branchId"
            id="branchId"
            value={branchId}
            onChange={handleChange}
            required
            label="Abrechnungstelle"
            autoComplete="no"
          >
            {branchData &&
              branchData.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.branchName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          name="tel"
          id="tel"
          label="Telefon"
          fullWidth
          value={teacherData && teacherData.tel}
          onChange={handleChange}
          autoComplete="no"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          startIcon={<UndoIcon />}
        >
          Abbrechen
        </Button>
        <Button onClick={handleSave} variant="contained" endIcon={<DoneIcon />}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherDialog;
