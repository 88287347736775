import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import "./UserPermissions.css";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import { slice } from "../../../../Common/Utility";
import { Get, Post } from "../../../../Common/WebRequest";

const Region = ({ region, onRegionChange, onBranchChange, GroupId }) => {
  const handleRegionChange = (isChecked) => {
    onRegionChange(region.id, isChecked);
  };

  const handleBranchChange = (branchId, isChecked) => {
    onBranchChange(region.id, branchId, isChecked);
  };

  const isIndeterminate =
    region.branches.some((branch) => branch.isSelected) && !region.isSelected;
  const allBranchesSelected = region.branches.every(
    (branch) => branch.isSelected
  );
  const someBranchesSelected =
    region.branches.some((branch) => branch.isSelected) && !allBranchesSelected;

  let regionClassName = "RegionCard";
  if (region.isSelected) {
    regionClassName += " selected";
  }
  if (someBranchesSelected) {
    regionClassName += " mixed";
  }
  if (isIndeterminate) {
    regionClassName += " indeterminate";
  }

  return (
    <Box className={regionClassName}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={GroupId === 99}
            checked={region.isSelected}
            onChange={(e) => handleRegionChange(e.target.checked)}
            indeterminate={isIndeterminate}
            className="RegionCheckbox"
          />
        }
        label={<strong>{region.regionName}</strong>}
        className="RegionLabel"
      />
      {region.branches.map((branch) => (
        <Box
          key={branch.id}
          className={`Branch ${branch.isSelected ? "selected" : ""}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={GroupId === 99}
                checked={branch.isSelected}
                onChange={(e) =>
                  handleBranchChange(branch.id, e.target.checked)
                }
                className={`BranchCheckbox ${
                  branch.isSelected ? "selected" : ""
                }`}
              />
            }
            label={branch.branchName}
            className="BranchLabel"
          />
        </Box>
      ))}
    </Box>
  );
};

export default function UserPermissions(props) {
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    (async () => {
      setError("");
      setSuccessMsg("");
      let res = await Get("UserManagmentPermission/" + props.UserId);

      if (res.isSuccess) {
        setPermissions(res.result.regions);
      } else {
        setPermissions([]);
        setError(res.Status + "/" + res.Error);
      }
    })();
  }, [props.UserId]);
  const handleRegionChange = (regionId, isChecked) => {
    const updatedPermissions = permissions.map((region) => {
      if (region.id === regionId) {
        const updatedBranches = region.branches.map((branch) => ({
          ...branch,
          isSelected: isChecked,
        }));

        return {
          ...region,
          isSelected: isChecked,
          branches: updatedBranches,
        };
      }
      return region;
    });

    setPermissions(updatedPermissions);
  };

  const handleBranchChange = (regionId, branchId, isChecked) => {
    const updatedPermissions = permissions.map((region) => {
      if (region.id === regionId) {
        const updatedBranches = region.branches.map((branch) => {
          if (branch.id === branchId) {
            return {
              ...branch,
              isSelected: isChecked,
            };
          }
          return branch;
        });

        const allBranchesSelected = updatedBranches.every(
          (branch) => branch.isSelected
        );
        /*const someBranchesSelected =
          updatedBranches.some((branch) => branch.isSelected) &&
          !allBranchesSelected;*/

        return {
          ...region,
          isSelected: allBranchesSelected,
          branches: updatedBranches,
        };
      }
      return region;
    });

    setPermissions(updatedPermissions);
  };

  async function handleFormSubmit() {
    var permissionsData = {
      userId: props.UserId,
      userName: props.UserName,
      groupId: props.GroupId,
      regions: permissions,
    };

    let res = await Post(
      "UserManagmentPermission/" + props.UserId,
      permissionsData
    );
    if (res.isSuccess) {
      setError("");
      setSuccessMsg("Die Berechtigungen wurden erfolgreich gespeichert.");
    } else {
      setSuccessMsg("");
      setError(res.Error);
    }
  }

  return (
    <div
      className="PermissionManager"
      hidden={error.toString().includes("Berechtigungen")}
    >
      <h5 className="Title">
        User Permissions: <span style={{ color: "red" }}>{props.UserName}</span>
      </h5>

      <div className="PermissionsContainer">
        {permissions.length > 0 ? (
          permissions.map((region) => (
            <Region
              GroupId={props.GroupId}
              key={region.id}
              region={region}
              onRegionChange={handleRegionChange}
              onBranchChange={handleBranchChange}
            />
          ))
        ) : (
          <div className="DeactivatedUserLabel">
            <p>Current user is deactivated</p>
          </div>
        )}
      </div>

      <div className="ButtonContainer">
        <Button
          disabled={props.GroupId === 99 || permissions.length === 0}
          className="SaveButton"
          variant="contained"
          onClick={handleFormSubmit}
          endIcon={<ArrowForward />}
        >
          Save Permissions
        </Button>
      </div>
      <div>
        <br />
        {error.length > 0 &&
          !error.toString().toLowerCase().includes("no results") && (
            <div className="alert alert-danger" role="alert">
              <HighlightOffIcon></HighlightOffIcon> {slice(error, 180, "...")}
            </div>
          )}
        {error.length === 0 && successMsg.length > 0 && (
          <div className="alert alert-success" role="alert">
            <SuccessIcon></SuccessIcon> {slice(successMsg, 100, "...")}
          </div>
        )}
      </div>
    </div>
  );
}
