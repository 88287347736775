import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts";
import Typography from "@mui/material/Typography";
import { Get } from "../../Common/WebRequest";
import { Paper } from "@mui/material";
import "./TopStats.css";

const TopStudents = () => {
  const [loading, setLoading] = useState(true);
  const [topStudents, setTopStudents] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Get("Statistics/TopStudents");

        if (res.isSuccess) {
          const data = res.result;

          setTopStudents(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!topStudents || topStudents.length === 0) {
    return <div> </div>;
  }

  return (
    <Paper elevation={3} className="top-container">
      <Typography align="left" variant="h6" gutterBottom>
        Top-Schüler
      </Typography>
      <div className="chart-container">
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: topStudents.map((item) => item.studentName),
            },
          ]}
          yAxis={[{ id: "linearAxis", scaleType: "linear" }]}
          series={[
            {
              yAxisKey: "linearAxis",
              data: topStudents.map((item) =>
                !isNaN(item.numberOfCourses) ? item.numberOfCourses : 0
              ),
              label: "Fächer",
              color: "#ffb400",
            },
          ]}
          leftAxis="linearAxis"
          height={250}
        />
      </div>
    </Paper>
  );
};

export default TopStudents;
