import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  initialState: "",
  name: "tokenSlice",
  reducers: {
    selectToken: (state, action) => {
      return action.payload;
    },
  },
});

export const { selectToken } = tokenSlice.actions;
export default tokenSlice.reducer;
