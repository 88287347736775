import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { HomeOutlined, PublicOutlined } from "@mui/icons-material";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { capitalizeFirstLetter, getStorageItem } from "../../Common/Utility";
import { useEffect } from "react";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import DialogBoxYesNo from "../Dialog/DialogBoxYesNo";
import { Post } from "../../Common/WebRequest";

const pages = [
  "Aufträge",
  "Abrechnung",
  "Abrechnungsübersicht",
  "Group Name",
  "System",
  "Logout",
];

function TitleBar({ onNavToggle }) {
  const [userName, setUserName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [systemType, setSystemType] = useState("");
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    (async () => {
      let userName = await getStorageItem("login", "userName");
      let groupName = await getStorageItem("login", "systemGroupName");
      let systemType = await getStorageItem("login", "systemType");
      if (userName) {
        setUserName(capitalizeFirstLetter(userName));
        setGroupName(groupName);
        setSystemType(systemType);

        pages[3] = "Group Name (" + groupName + ")";
        pages[4] = "  (" + systemType + ")";
        pages[5] = "Logout (" + userName + ")";
      }
    })();
  }, [userName]);
  const loginData = JSON.parse(localStorage.getItem("login"));
  const navigate = useNavigate();

  const handleCloseNavMenu = (page) => {
    console.log(page);
    if (page.includes("Logout")) {
      handleShowDialog();
      //window.location.href = "/login";
    } else if (page === "Aufträge") {
      navigate("/trackerstudents");
      //window.location.href = "/trackerstudents";
    } else if (page === "Abrechnung") {
      // window.location.href = "/calculations";
      navigate("/calculations");
    } else if (page === "Abrechnungsübersicht") {
      // window.location.href = "/calculationsview";
      navigate("/CalculationsView");
    }
  };
  if (!loginData || loginData.length === 0) {
    return null;
  }
  function handleOnNavToggle(e) {
    setIsNavOpen(!isNavOpen);

    onNavToggle();
  }

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = (result) => {
    setShowDialog(false);
    console.log("Dialog result:", result);
    if (result === true) {
      handleOnLogout();
    }
  };

  async function handleOnLogout() {
    try {
      var loginDataIdentifier = await getStorageItem(
        "login",
        "loginDataIdentifier"
      );
      let res = await Post("Login/Logout", loginDataIdentifier);

      localStorage.removeItem("login");
      onNavToggle(true); //  called from App to set margin-left=0px
      navigate("/login");
    } catch (error) {
      console.log("Error by logout session: ", error.toString());
    }
  }
  return (
    <AppBar position="static">
      {showDialog && (
        <DialogBoxYesNo
          title="Logout"
          text="Sind Sie sicher, dass Sie sich abmelden möchten?"
          onClose={handleCloseDialog}
        />
      )}

      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <IconButton onClick={handleOnNavToggle} sx={{ color: "white" }}>
            {isNavOpen ? <ReorderIcon /> : <ReorderIcon />}
          </IconButton>
        </Box>
        <Box sx={{ marginLeft: 2, marginRight: 2 }}></Box>

        <HomeOutlined />
        <Typography
          variant="h6"
          component="a"
          href="/"
          sx={{
            mr: 4,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Home
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page}
              onClick={(e) => handleCloseNavMenu(page)}
              sx={{
                my: 2,
                display: "block",
                color:
                  page.toLocaleLowerCase().includes("test") &&
                  systemType.toLocaleLowerCase().includes("test")
                    ? "red"
                    : page.toLocaleLowerCase().includes("live") &&
                      systemType.toLocaleLowerCase().includes("live")
                    ? "lightgreen"
                    : "white",
              }}
            >
              {page.toLowerCase() === "aufträge" && <ReceiptOutlinedIcon />}
              {page.toLowerCase() === "abrechnung" && <TableViewOutlinedIcon />}
              {page.toLowerCase() === "abrechnungsübersicht" && (
                <PlagiarismOutlinedIcon />
              )}
              {page.toLowerCase() === "logout" && <LoginIcon />}

              {page}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default TitleBar;
