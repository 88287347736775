import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts";
import Typography from "@mui/material/Typography";
import { Get } from "../../Common/WebRequest";
import { Paper } from "@mui/material";
import "./TopStats.css";

const TopCourses = () => {
  const [loading, setLoading] = useState(true);
  const [topCourses, setTopCourses] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Get("Statistics/TopCourses");

        if (res.isSuccess) {
          const data = res.result;
          setTopCourses(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!topCourses || topCourses.length === 0) {
    return <div></div>;
  }

  return (
    <Paper elevation={3} className="top-container">
      <Typography align="left" variant="h6" gutterBottom>
        Top-Fächer
      </Typography>
      <div className="chart-container">
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: topCourses.map((item) => item.courseName),
            },
          ]}
          yAxis={[{ id: "linearAxis", scaleType: "sqrt" }]}
          series={[
            {
              yAxisKey: "linearAxis",
              data: topCourses.map((item) =>
                !isNaN(item.totalHours) ? item.totalHours : 0
              ),
              label: "Ges.-Std",
              color: "#87bc45",
            },
            {
              yAxisKey: "linearAxis",
              data: topCourses.map((item) =>
                !isNaN(item.numberOfStudents) ? item.numberOfStudents : 0
              ),
              label: "Anz. Schüler",
              color: "#ef9b20",
            },
          ]}
          leftAxis="linearAxis"
          height={250}
        />
      </div>
    </Paper>
  );
};

export default TopCourses;
