//// LOCAL
export const apiUrl = process.env.REACT_APP_API_URL;
console.log(process.env);
//////****Test Server API = site4
//export const apiUrl = "https://hmberatung-001-site4.ctempurl.com:443/api/v1.0";

//////****Live Server API= site2
//export const apiUrl = "https://hmberatung-001-site2.ctempurl.com:443/api/v1.0";

/////export old old old const apiUrl = "https://mohabujayyab-001-site3.htempurl.com/api/v1.0";
