import "./DropDownRegion.css";
import { useEffect, useState } from "react";
import { Get } from "../../../Common/WebRequest";

function DropDownRegion(props) {
  const [error, setError] = useState("");
  const [regionsData, setRegionsData] = useState([]);

  let regionId = 0;
  let withAll = props.withAll;
  if (!withAll && props.regionID > 0) {
    regionId = props.regionID;
  } else regionId = props.regionID;

  useEffect(() => {
    (async () => {
      let res = await Get("region");

      if (res.isSuccess) setRegionsData(res.result);
      else setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    })();
  }, []);

  return (
    <>
      <select
        className="form-select"
        //defaultValue={regionId}
        value={regionId}
        id="region"
        onChange={props.onChange}
      >
        {withAll && <option id={-1} key={-1} value={-1} label="@ALL"></option>}
        {regionsData.map((region) => {
          return (
            <option
              id={region.id}
              key={region.id}
              value={region.Id}
              label={region.regionName}
            >
              {region.id}
            </option>
          );
        })}
      </select>
      {/* {regionId > 0 && props.onPopulate(regionsData.map((region) => region.id))} */}
    </>
  );
}

export default DropDownRegion;
