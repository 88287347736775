import React from "react";
import UserLoginsGrid from "./UserLogins/UserLoginsGrid";
import UserPermissions from "./UserPermissions/UserPermissions";
import { useState } from "react";

export default function UserManagement() {
  const [selectedUserId, setSelectedUserId] = useState(1);
  const [selectedGroupId, setSelectedGroupId] = useState(99);
  const [selectedUserName, setSelectedUserName] = useState("Admin");

  const handleUserSelected = (userId, groupId, userName) => {
    setSelectedUserId(userId);
    setSelectedGroupId(groupId);
    setSelectedUserName(userName);
  };
  return (
    <>
      <UserLoginsGrid onSelectUser={handleUserSelected} />

      <UserPermissions
        UserId={selectedUserId}
        GroupId={selectedGroupId}
        UserName={selectedUserName}
      />
    </>
  );
}
