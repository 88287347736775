import "./Dialog.css";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import React, { useEffect, useState } from "react";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Get, Post, Put } from "../../Common/WebRequest";
import {
  getString,
  getValue,
  isDate,
  showDateOnly,
  slice,
} from "../../Common/Utility";

const initialFormState = {
  id: 0,
  studentName: "",
  customerNumber: "",
  address: "",
  regionCenter: false,
  zip: "",
  ort: "",
  regionId: 0,
  branchId: 0,
  tel: "",
  birthDate: "",
  butNumber: "",
  expiryDate: null,
};

const StudentDialog = ({ open, onClose, onSave, StudentId, reload }) => {
  const [studentData, setStudentData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState(0);
  const [birthDate, setBirthDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    (async () => {
      setError("");
      if (getValue(StudentId) === 0) {
        let regions = await Get("region");
        if (!regions.isSuccess) {
          setError(regions.Error);
          return;
        }
        setStudentData(initialFormState);

        setRegionData(regions.result);

        setBranchData([]);
        setBirthDate("");
        setExpiryDate("");
        setTitle("Schüler/in hinzufügen");
        return;
      } else {
        let student = await Get("student/" + StudentId);
        let regions = await Get("region");

        setStudentData(student.result);
        setRegionData(regions.result);
        if (regions.isSuccess && student.isSuccess) {
          let branches = await Get("branch/region/" + student.result.regionId);
          setBranchData(branches.result);
          setBranchId(student.result.branchId);
          if (
            isDate(getString(student.result.birthDate)) &&
            showDateOnly(student.result.birthDate)
          ) {
            setBirthDate(showDateOnly(student.result.birthDate));
          } else setBirthDate("");
          if (
            isDate(getString(student.result.expiryDate)) &&
            showDateOnly(student.result.expiryDate)
          ) {
            setExpiryDate(showDateOnly(student.result.expiryDate));
          } else setExpiryDate("");
        }
        setTitle("Schüler/in aktualisieren");
      }
    })();
  }, [StudentId, reload]);

  async function handleChange(event) {
    const { name, value } = event.target;
    setStudentData((prevstudentData) => ({
      ...prevstudentData,
      [name]: value,
    }));

    if (name === "branchId") {
      setBranchId(value);
    } else if (name === "birthDate") {
      setBirthDate(value);
    } else if (name === "expiryDate") {
      if (isDate(value)) setExpiryDate(value);
      else setExpiryDate(null);
    } else if (name === "regionId") {
      const selectedRegion = regionData.find(
        (region) => region.id === parseInt(value, 10)
      );
      if (selectedRegion) {
        const regionBranches = await Get("branch/region/" + selectedRegion.id);
        setBranchData(regionBranches.result);
        if (regionBranches.result.length > 0)
          setBranchId(regionBranches.result[0].id);
        setStudentData((prevstudentData) => ({
          ...prevstudentData,
          branchId: regionBranches.result[0].id,
        }));
      } else {
        setBranchData([]);
        setBranchId(0);
        setBirthDate("");
        setExpiryDate("");
      }
    }
  }

  const handleCancel = () => {
    onClose();
    return;
  };

  async function handleSave() {
    var result;
    if (!isDate(studentData.expiryDate)) {
      setStudentData((prevstudentData) => ({
        ...prevstudentData,
        expiryDate: null,
      }));
    }
    if (StudentId > 0) {
      result = await Put("student", StudentId, studentData);
    } else {
      if (studentData.studentName.trim().length === 0) {
        setError("Schüler/in Name ist falsch.");
        return;
      }
      if (studentData.zip.trim().length === 0) {
        studentData.zip = "0";
      }
      if (
        studentData.zip.toString().trim().length > 1 &&
        studentData.zip.toString().trim().length < 5
      ) {
        setError("PLZ ist falsch. Muss '5-Ziffern' z.B. 20154");
        return;
      }
      if (studentData.birthDate === "") {
        setError("Geburtstagsdatum ist falsch.");
        return;
      }
      if (studentData.butNumber.trim() === "") {
        setError("But-Nummer ist falsch.");
        return;
      }
      result = await Post("student", studentData);
    }
    if (result.isSuccess) {
      onSave(true);
      onClose();
      setError("");
    } else {
      if (result.Error.includes("One or more validation")) {
        var err = result.ErrorDetail;
        err = err.replace("Details:", "");
        setError(err);
      } else setError(result.Status + ": " + result.Error);
    }
    // onSave(studentData);
    // DoSave API
  }

  return (
    <Dialog
      disableRestoreFocus
      aria-autocomplete="none"
      open={open}
      onClose={handleCancel}
      fullWidth
      PaperProps={{
        style: {
          minHeight: "350px",
          width: "550px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>

      <DialogContent>
        {error.length > 0 && !error.includes("No Results") && (
          <div className="alert alert-danger" role="alert">
            <CheckError />
            {slice(error, 1000, "...")}
          </div>
        )}
        <TextField
          autoFocus={true}
          name="studentName"
          id="studentName"
          label="Schüler/in"
          required
          fullWidth
          value={studentData && studentData.studentName}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            minLength: 3,

            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />

        <TextField
          name="address"
          id="address"
          label="Adresse"
          fullWidth
          value={studentData && studentData.address ? studentData.address : ""}
          onChange={handleChange}
          inputProps={{
            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        {/* <TextField
          name="customerNumber"
          id="customerNumber"
          label="Kunden-Nummer"
          fullWidth
          value={studentData && studentData.customerNumber}
          onChange={handleChange}
          inputProps={{
            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem" }}
        /> */}
        <TextField
          name="zip"
          id="zip"
          label="PLZ"
          fullWidth
          value={studentData && studentData.zip}
          onChange={handleChange}
          style={{ marginBottom: "1rem" }}
          inputProps={{
            maxLength: 5,
            minLength: 5,

            autoComplete: "off",
          }}
        />

        <TextField
          name="ort"
          id="ort"
          label="Ort"
          fullWidth
          value={studentData && studentData.ort ? studentData.ort : ""}
          onChange={handleChange}
          inputProps={{
            autoComplete: "off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel id="regionId">ORT (Region) *</InputLabel>
          <Select
            name="regionId"
            id="regionId"
            value={studentData.regionId}
            onChange={handleChange}
            required
            label="ORT (Region)"
            autoComplete="off"
          >
            {regionData.length > 0 &&
              regionData.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.regionName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel id="branchId">Abrechnungstelle *</InputLabel>
          <Select
            name="branchId"
            id="branchId"
            value={branchId}
            onChange={handleChange}
            required
            label="Abrechnungstelle"
            autoComplete="no"
          >
            {branchData &&
              branchData.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.branchName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          name="tel"
          id="tel"
          label="Telefon"
          fullWidth
          value={studentData && studentData.tel}
          onChange={handleChange}
          autoComplete="no"
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="butNumber"
          id="butNumber"
          label="BuT-Nummer"
          fullWidth
          required
          value={studentData && studentData.butNumber}
          onChange={handleChange}
          autoComplete="no"
          style={{ marginBottom: "1rem" }}
        />

        <FormControlLabel
          label="Region Hannover"
          sx={{
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
          control={
            <Checkbox
              name="regionCenter"
              id="regionCenter"
              checked={studentData.regionCenter}
              onChange={(e) =>
                handleChange({
                  target: { name: "regionCenter", value: e.target.checked },
                })
              }
            />
          }
        />
        <div>
          <div>
            <label style={{ fontWeight: "bold" }}>Geburtsdatum * &nbsp;</label>
            <input
              name="birthDate"
              type="date"
              required
              value={birthDate}
              onChange={handleChange}
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>
              Verfallsdatum &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <input
              name="expiryDate"
              type="date"
              value={expiryDate}
              onChange={handleChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          startIcon={<UndoIcon />}
        >
          Abbrechen
        </Button>
        <Button onClick={handleSave} variant="contained" endIcon={<DoneIcon />}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentDialog;
