import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { GridRowModes, DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { Delete, Get, Post, Put } from "../../Common/WebRequest";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { slice } from "../../Common/Utility";
import EditToolbar from "./EditToolbar";
import columnsBuilder from "./columnsBuilder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setPricesData: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.any,
  exportFileName: PropTypes.any,
};

export default function PricesGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [pricesData, setPricesData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  const [regionData, setRegionData] = useState([]);
  let fieldToFocus = "regionId";
  let exportFileName = "Preis pro Einheit";

  useEffect(() => {
    (async () => {
      let res = await Get("price");
      let regions = await Get("region");

      if (res.isSuccess) {
        setPricesData(res.result);
        setRegionData(regions.result);
      } else setError(res.Status + "/" + res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        pricesData.find((x) => x.id === id).priceName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "regionId" },
    });
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setPricesData(
      pricesData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      res = await Post("price", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      res = await Put("price", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Der neue 'Preis pro Einheit' wurde hinzugefügt.")
        : setSuccessMsg("Der 'Preis pro Einheit' wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      console.log("Error: ##### ", res.Error);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("price", id);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Der Preis pro Einheit wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = pricesData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setPricesData(pricesData.filter((row) => row.id !== id));
    }
  };
  const handleError = (err) => {
    setError(err.message);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();
    // await apiRef.current.stopRowEditMode({ id });
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new price:", newRow);
    newRow = {
      ...newRow,
    };
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    console.log("#####  # updatedRow:", updatedRow);
    DoSaveApi(newRow, updatedRow);

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns = columnsBuilder({
    regionData,
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
  });

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <h2>Preis pro Einheit</h2>
      <br></br>
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <HighlightOffIcon></HighlightOffIcon> {slice(error, 100, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          <SuccessIcon></SuccessIcon> {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      <DataGrid
        rowSelection={false}
        density="compact"
        rows={pricesData}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={5}
        hideFooterPagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        //onCellEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setPricesData,
            setRowModesModel,
            fieldToFocus,
            exportFileName,
          },
        }}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
