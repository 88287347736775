import { GridActionsCellItem } from "@mui/x-data-grid";
import { isDate, showDateOnly } from "../../Common/Utility";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PdfIcon from "@mui/icons-material/PictureAsPdf";

function columnBuilder(props) {
  const {
    GridRowModes,
    rowModesModel,
    handlePdfJobCenterClick,
    handleEditClick,
    handleCancelClick,
    handleDeleteClick,
    handleSaveClick,
    branchData,
    regionData,
  } = props;

  const columns = [
    { field: "id", headerName: "ID", width: 70, editable: false },
    {
      field: "studentName",
      headerName: "Schüler/in *",
      width: 250,
      type: "text",
      editable: true,
    },

    /* {
      field: "customerNumber",
      headerName: "Kunden-Nummer",
      width: 110,
      type: "text",
      editable: true,
      valueFormatter: function (params) {
        if (params.value === "0") return "";
        return params.value;
      },
    },*/
    {
      field: "address",
      headerName: "Adresse",
      width: 110,
      type: "text",
      editable: true,
      valueFormatter: function (params) {
        if (params.value === "0") return "";
        return params.value;
      },
    },
    {
      field: "zip",
      headerName: "PLZ",
      width: 70,
      type: "number",
      editable: true,

      valueFormatter: function (params) {
        if (params.value === 0) return "";
        return "" + params.value;
      },
    },
    {
      field: "ort",
      headerName: "Ort",
      width: 110,
      type: "text",
      editable: true,
    },
    {
      field: "regionId",
      headerName: " (Region) *",
      type: "singleSelect",
      editable: true,
      flex: 1,

      getOptionValue: (region) => region?.id,
      getOptionLabel: (region) => region?.regionName,
      valueOptions: regionData /* loaded regions from GET API*/,

      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find((region) => region.id === params.value);
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
        // return "";
      },
      /*renderCell: (params) => {
        return <div className="rowitem">{params.row.region.regionName}</div>;
      },*/
    },

    {
      field: "branchId",
      headerName: "Abrechnungstelle *",
      type: "singleSelect",
      editable: true,
      flex: 1,

      getOptionValue: (branch) => branch?.id,
      getOptionLabel: (branch) => branch?.branchName,
      valueOptions: branchData,

      valueFormatter: function (params) {
        if (branchData) {
          let branch = branchData.find((branch) => branch.id === params.value);
          if (typeof branch?.branchName !== "undefined")
            return branch.branchName;
        }
      },
    },
    {
      field: "tel",
      headerName: "Telefon",
      width: 120,
      type: "text",
      flex: 1,
      editable: true,
    },
    {
      field: "birthDate",
      headerName: "Geburtsdatum *",
      type: "date",
      width: 130,
      editable: true,
      valueFormatter: function (params) {
        let birthdate = params.value;
        if (isDate(birthdate)) {
          return showDateOnly(birthdate);
        }
      },
    },

    {
      field: "butNumber",
      headerName: "BuT-Nummer *",
      type: "text",
      editable: true,
      width: 170,
    },
    {
      field: "regionCenter",
      headerName: "RegionHannover",
      type: "boolean",
      editable: true,
      width: 150,
    },
    {
      field: "expiryDate",
      headerName: "Verfallsdatum",
      type: "date",
      editable: true,
      width: 130,
      flex: 1,

      valueFormatter: function (params) {
        let expiryDate = params.value;
        if (isDate(expiryDate)) {
          return showDateOnly(expiryDate);
        }
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("studentsData ", studentsData);

        return [
          <GridActionsCellItem
            icon={
              <span style={{ color: "#d32f2f" }}>
                <PdfIcon />
              </span>
            }
            label="View"
            className="textPrimary"
            onClick={handlePdfJobCenterClick(id)}
            color="red"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return columns;
}
export default columnBuilder;
