import { showDateOnly } from "../../Common/Utility";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
const columnsBuilder = () => {
  const columns = [
    {
      field: "fileName",
      headerName: "Pdf-Datei",
      width: 330,
      type: "text",

      editable: false,
      //renderCell: (params) => <a href="##">{params.value}</a>,
      renderCell: (cellValues) => {
        return (
          <div style={{ fontSize: 12 }}>
            <PdfIcon variant="contained" color="error" title="Download" />
            &nbsp; &nbsp;
            <a href="##" title={`View: ${cellValues.value}`}>
              {cellValues.value}
            </a>
          </div>
        );
      },
    },
    {
      field: "rechnungsnummer",
      headerName: "Rechnungsnummer.",
      width: 120,
      type: "text",
      editable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: 12 }}>{cellValues.value}</div>;
      },
    },

    {
      field: "sumAmount",
      headerName: "Betrag €",
      width: 100,
      type: "number",
      editable: false,
      renderCell: (params) => {
        const formattedValue = params.value.toLocaleString("en");
        return <div style={{ fontSize: 12 }}>{formattedValue}</div>;
      },
    },
    {
      field: "fileDateTime",
      headerName: "Datum",
      width: 100,
      type: "date",
      editable: false,
      valueFormatter: function (params) {
        return showDateOnly(params.value);
      },
      renderCell: (cellValues) => {
        return (
          <div style={{ fontSize: 12 }}>{showDateOnly(cellValues.value)}</div>
        );
      },
    },

    /* {
      field: "fileSize",
      headerName: "File Size",
      width: 80,
      type: "text",
      editable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: 12 }}>{cellValues.value}</div>;
      },
    },*/

    /*{
      field: "actions",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DownloadIcon />}
            variant="contained"
            color="success"
            label="Save"
            title="Download"
          />,
        ];
      },
    },*/
  ];

  return columns;
};

export default columnsBuilder;
