import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getStorageItem } from "../Common/Utility";
import { useState, useEffect } from "react";
import TopCourses from "./TopStatistics/TopCourses";
import TopCoursesWithOthers from "./TopStatistics/TopCoursesWithOthers";
import TopTeachers from "./TopStatistics/TopTeachers";
import TopStudents from "./TopStatistics/TopStudents";
import TopStudentsWithAmount from "./TopStatistics/TopStudentsWithAmount";
import TopStudentsAmountByPDFDate from "./TopStatistics/TopStudentsAmountByPDFDate";
import HeaderButtons from "./HeaderButtons/TopHeaderPage";

import Ticker from "./HeaderTitle/Ticker";

const Home = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    (async () => {
      let userName = await getStorageItem("login", "userName");
      if (userName) setUserName(capitalizeFirstLetter(userName));
      if (
        !loginData ||
        loginData.token === "" ||
        !userName ||
        userName === ""
      ) {
        console.log("invalid token");

        window.location.href = "/login";

        navigate("/login");
        return null;
      }
    })();
  }, []);

  return (
    <div style={{ background: "white" }}>
      <br />
      <Ticker />
      <br />
      <HeaderButtons />
      <br />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "20px",
          padding: "20px",
        }}
      >
        <div>
          <TopCourses />
          <div style={{ marginTop: "20px" }}>
            <TopCoursesWithOthers />
          </div>
        </div>

        <div>
          <TopTeachers />
          <div style={{ marginTop: "20px" }}>
            <TopStudents />
          </div>
        </div>

        <div>
          <TopStudentsWithAmount />
          <div style={{ marginTop: "20px" }}>
            <TopStudentsAmountByPDFDate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
