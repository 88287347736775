import React, { useEffect, useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Get } from "../../Common/WebRequest";
import { Paper, Typography } from "@mui/material";
import "./TopStats.css";
const TopCoursesWithOthers = () => {
  const [loading, setLoading] = useState(true);
  const [topCourses, setTopCourses] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Get("Statistics/TopCoursesWithOthers");

        if (res.isSuccess) {
          setTopCourses(res.result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!topCourses || topCourses.length === 0) {
    return <div></div>;
  }

  const TOTAL = topCourses
    .map((item) => item.studentPercentage)
    .reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const colorPalette = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#9C27B0",
    "#3F51B5",
  ];

  const pieChartData = topCourses.map((item, index) => ({
    label: item.courseName,
    value: item.studentPercentage,
    color: colorPalette[index % colorPalette.length],
  }));

  const sizing = {
    // margin: { right: 20 },

    height: 250,
  };

  return (
    <div>
      <Paper elevation={3} className="top-container">
        <Typography align="left" variant="h6" gutterBottom>
          Top-Fächer/Schüler
        </Typography>
        <div className="chart-container">
          <PieChart
            series={[
              {
                outerRadius: 100,
                data: pieChartData,
                arcLabel: getArcLabel,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 15,
              },
            }}
            {...sizing}
          />
        </div>
      </Paper>
    </div>
  );
};

export default TopCoursesWithOthers;
