import * as XLSX from "xlsx";

export function ToExcel(document, tableId, fileName) {
  const table = document.getElementById(tableId);
  if (!table) {
    console.error(`Table with ID "${tableId}" not found.`);
    return;
  }

  const rows = table.querySelectorAll("tr");
  if (rows.length === 0) {
    console.error("The table has no rows.");
    return;
  }

  const data = [];
  rows.forEach((row) => {
    const rowData = [];
    const columns = row.querySelectorAll("td, th");
    columns.forEach((column) => {
      if (column.querySelector("input")) {
        rowData.push(column.querySelector("input").value.trim());
      } else {
        rowData.push(column.textContent.trim());
      }
    });
    data.push(rowData);
  });

  //const fileName = calcData.length > 0 ? calcData[0].studentName : null;
  const sheetName = "Sheet1";
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const excelBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const excelUrl = URL.createObjectURL(excelBlob);

  const downloadLink = document.createElement("a");
  downloadLink.href = excelUrl;
  downloadLink.download = `${fileName}.xlsx`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
export function ToExcelByRows(headers, rows, fileName) {
  try {
    const worksheetData = [headers, ...rows.map((row) => Object.values(row))];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const excelUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = excelUrl;
    downloadLink.download = `${fileName}.xlsx`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (ex) {
    console.log("Error By exporting  Rows/XLS, ", ex.toString());
  }
}

export const copyTableById = (document, tableId) => {
  try {
    const table = document.getElementById(tableId); // Use the table id here
    const range = document.createRange();
    range.selectNode(table);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");

    // Remove the selection range
    selection.removeAllRanges();
  } catch (ex) {
    console.log("Error By Copy Table:", tableId, " : ", ex.toString());
  }
};

export const copyToClipboard = (text) => {
  try {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  } catch (ex) {
    console.log("Error By Coppying Rows, ", ex.toString());
  }
};
//Not Working with dropDown
export const copyTableWithDropDownByTableId = (document, tableId) => {
  const table = document.getElementById(tableId);
  if (table) {
    const range = document.createRange();
    range.selectNodeContents(table);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    const textToCopy = selection.toString();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Table content copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy table content: ", err);
      });
    selection.removeAllRanges();
  }
};
