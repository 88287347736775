import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { GridRowModes, DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";

import EditToolbar from "./EditToolbar";
import columnsBuilder from "./columnsBuilder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

import DeleteConfirmation from "../../../../components/DeleteConfirmation";
import { slice } from "../../../../Common/Utility";
import { Delete, Get, Post, Put } from "../../../../Common/WebRequest";

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setLoginData: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.any,
  exportFileName: PropTypes.any,
};

export default function UserLoginsGrid({ onSelectUser }) {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loginData, setLoginData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  let fieldToFocus = "userName";
  let exportFileName = "Logins";

  useEffect(() => {
    (async () => {
      let res = await Get("Login");

      if (res.isSuccess) {
        setLoginData(res.result);
      } else setError(res.Status + "/" + res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        loginData.find((x) => x.id === id).userName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "userName" },
    });
  };

  async function handleOnRowClick(params, event) {
    setError("");
    setSuccessMsg("");
    //event.defaultMuiPrevented = true;
    if (params.row && params.row.id > 0) {
      onSelectUser(params.row.id, params.row.groupId, params.row.userName);
    }
  }

  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setLoginData(
      loginData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      res = await Post("login", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      res = await Put("login", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Der neue Benutzer wurde hinzugefügt.")
        : setSuccessMsg("Der Benutzer wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      console.log("Error: ##### ", res.Error + "/" + res.ErrorDetail);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("login", id);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Der Benutzer wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = loginData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setLoginData(loginData.filter((row) => row.id !== id));
    }
  };
  const handleError = (err) => {
    setError(err.message);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();
    // await apiRef.current.stopRowEditMode({ id });
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new user:", newRow);
    newRow = {
      ...newRow,
    };
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    console.log("#####  # updatedRow:", updatedRow);
    DoSaveApi(newRow, updatedRow);

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = columnsBuilder({
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,

    //onCellKeyDown={handleCellKeyDown}
  });

  return (
    <Box
      sx={{
        height: 500,

        display: "contents",
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {/* <h4>Users Management</h4> */}

      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <HighlightOffIcon></HighlightOffIcon> {slice(error, 180, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          <SuccessIcon></SuccessIcon> {slice(successMsg, 100, "...")}
        </div>
      )}

      <DataGrid
        rowSelection={false}
        density="compact"
        rows={loginData}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={5}
        initialState={{
          columns: {
            ...loginData.initialState?.columns,
            columnVisibilityModel: {
              groupId: false,
            },
          },
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onRowClick={handleOnRowClick}
        //onCellEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setLoginData,
            setRowModesModel,
            fieldToFocus,
            exportFileName,
          },
        }}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
