import "./Calculation.css";
import React, { useEffect } from "react";
import { useState } from "react";
import DropDownBranch from "../../components/DropDown/Branch/DropDownBranch";
import DropDownStudent from "../../components/DropDown/Student/DropDownStudent";
import Export from "@mui/icons-material/SystemUpdateAlt";
import Copy from "@mui/icons-material/ContentCopy";
import {
  getBaseUrl,
  getFirstDayOfThisMonth,
  getLastDayOfThisMonth,
  getStorageItem,
  getValue,
  setStorageItem,
  showDateOnly,
  slice,
} from "../../Common/Utility";
import { Get, Post } from "../../Common/WebRequest";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { useRef } from "react";
import CheckOk from "@mui/icons-material/CheckCircleOutline";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import StudentPdfFilesGrid from "../../components/StudentPdfFiles/StudentPdfFilesGrid";
import DropDownRegion from "../../components/DropDown/Region/DropDownRegion";
import { Label } from "reactstrap";
import DialogPdfConfirmDate from "../../components/Dialog/DialogPdfConfirmDate";
import {
  ToExcel,
  copyTableById,
  copyTableByIdAsText,
  copyTableWithDropDownByTableId,
  copyToClipboard,
} from "../../Common/ExportTableToXls";
import { WebRequestPdfViewer } from "../../Common/WebRequestPdfViewer";
import { apiUrl } from "../../config";

const Calculation = () => {
  const [regionId, setRegionId] = useState(0);
  const [error, setError] = useState("");
  const [wait, setWait] = useState("");
  const [success, setSuccess] = useState("");
  const [branchId, setBranchId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [reload, setReload] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);
  //const [studentName, setStudentName] = useState("");
  let dateFrom = useRef();
  let dateTo = useRef();

  const [calcData, setCalcData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const [isDone, setIsDone] = useState(false);

  const [guid, setGuid] = useState("");
  const [totalAmountGrand, setTotalAmountGrand] = useState(0);
  const [totalHoursGrand, setTotalHoursGrand] = useState(0);

  const [isPdfCreated, setIsPdfCreated] = useState(false);
  const inputRefs = useRef([]);

  const [isFirst, setIsFirst] = useState(true);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isBranchLoaded, setIsBranchLoaded] = useState(false);
  const [isStudentLoaded, setIsStudentLoaded] = useState(false);

  const [readFromAuftrag, setReadFromAuftrag] = useState(false);

  useEffect(() => {
    (async () => {
      let regions = await Get("region");
      setRegionData(regions.result);
      if (!regions.isSuccess) setError(regions.Error);
      if (regionId === 0) setRegionId(regions.result[0].id);

      if (studentId === 0 || regionId === 0 || branchId === 0 || isFirst)
        return;
      await loadCalcData();
    })();
  }, [regionId, branchId, studentId, isPdfCreated, reload]);

  useEffect(() => {
    (async () => {
      let regionIdValue = 0;
      let branchIdValue = 0;
      setReadFromAuftrag(
        await getStorageItem("Calculation", "readFromAuftrag")
      );
      // setStorageItem("Calculation", "readFromStorage", false);
      if (!readFromAuftrag) return;
      if (isBranchLoaded) {
        regionIdValue = await getStorageItem("Calculation", "regionId");
        branchIdValue = await getStorageItem("Calculation", "branchId");

        if (getValue(regionIdValue) > 0) setRegionId(regionIdValue);
        if (getValue(branchIdValue) > 0) {
          setBranchId(branchIdValue);

          console.log("Load from storage:", branchId);
        }
      }
      if (!isStudentLoaded) return;

      let studentIdValue = await getStorageItem("Calculation", "studentId");
      let dateFromValue = await getStorageItem("Calculation", "dateFrom");
      let dateToValue = await getStorageItem("Calculation", "dateTo");

      setReadFromAuftrag(false);
      setStorageItem("Calculation", "readFromAuftrag", false);

      if (getValue(studentIdValue) >= 0) {
        setStudentId(studentIdValue);
      }
      //}
      if (dateFromValue) dateFrom.current.value = dateFromValue;
      if (dateToValue) dateTo.current.value = dateToValue;
    })();
  }, [isBranchLoaded, isStudentLoaded]);

  async function loadCalcData() {
    setTotalAmountGrand(0);
    setWait("Bitte warten.....");
    setStorageItem("Calculation", "regionId", getValue(regionId));
    setStorageItem("Calculation", "branchId", getValue(branchId));
    setStorageItem("Calculation", "studentId", getValue(studentId));

    if (getValue(studentId) === 0 || getValue(branchId) === 0) return;
    let res = {};
    let url = "Calculation/Calculation/" + branchId;

    setIsDone(() => {
      return false;
    });

    url = url + "/" + studentId;
    url = url + "/" + dateFrom.current.value + "/" + dateTo.current.value;
    res = await Get(url);
    if (res.isSuccess) {
      setWait("");
      setCalcData(res.result.studentTracks);
      setGrandTotalAmountValue(res.result.studentTracks);
      setGrandTotalHoursValue(res.result.studentTracks);
      if (res.result.isDone && !isPdfCreated) {
        setIsDone(() => {
          return true;
        });
      }
      if (res.result.isError && res.result.isDone && isPdfCreated) {
      } else if (res.result.isError) {
        if (res.result.error.includes("Already done"))
          setSuccess(" " + res.result.error);
        else setError(" " + res.result.error);
      }
    } else {
      setWait("");
      if (res.Error === "No Results found." || res.Error.includes("wrong"))
        return;
      setError(" " + res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    }
    console.log("isDone: ", isDone);
  }

  function setGrandTotalAmountValue(data) {
    setTotalAmountGrand(
      data.reduce((total, item) => total + getValue(item.amount), 0)
    );
  }
  function setGrandTotalHoursValue(data) {
    setTotalHoursGrand(
      data.reduce((total, item) => total + getValue(item.totalHours), 0)
    );
  }
  function handleOnDateFromChanged(e) {
    if (studentId > 0) {
      setIsDateChanged(true);
    } else setIsDateChanged(false);
  }

  function handleRegionValue(e) {
    setRegionId((currentRegionId) => {
      return getValue(e.target.value);
    });

    setGrandTotalAmountValue([]);
    setGrandTotalHoursValue([]);
    setBranchId(0);
    setStudentId(0);

    setCalcData([]);
    setError("");
    setSuccess("");
    setIsDone(false);
    setGuid("");
  }
  function handleBranchValue(e) {
    setBranchId((currentBranchId) => {
      return getValue(e.target.value);
    });

    setGrandTotalAmountValue([]);
    setGrandTotalHoursValue([]);
    setStudentId(0);

    setCalcData([]);
    setError("");
    setSuccess("");
    setIsDone(false);
    setGuid("");
  }
  function handleStudentValue(e) {
    setStudentId((currentStudentId) => {
      return getValue(e.target.value);
    });
    setGrandTotalAmountValue([]);
    setGrandTotalHoursValue([]);
    setCalcData([]);
    setError("");
    setSuccess("");
    setIsDone(false);
    setGuid("");
    console.log("Student id:", studentId);
    //setReadFromStorage(false);
  }

  async function RequestCreatePdf(selectedDate) {
    setWait("Bitte warten.....");
    setError("");
    setGuid("");
    setSuccess("");
    setCalcData([]);
    setIsDone(false);
    setGuid("");
    setGrandTotalAmountValue([]);
    setGrandTotalHoursValue([]);

    await setStorageItem("Calculation", "regionId", getValue(regionId));
    await setStorageItem("Calculation", "branchId", getValue(branchId));

    // if (studentId === -1) setStudentId(-1); // all student @ALL
    await setStorageItem("Calculation", "studentId", getValue(studentId));

    let url = "Calculation/pdf/" + branchId;
    // let studentIdTmp = studentId;
    url = url + "/" + studentId;
    url = url + "/" + dateFrom.current.value + "/" + dateTo.current.value;
    url = url + "/" + selectedDate;

    const studentTracksData = {
      studentTracks: calcData,
    };

    console.log(studentTracksData);
    const res = await Post(url, studentTracksData);
    setIsPdfCreated(false);

    if (studentId > 0) {
      if (res.isSuccess) {
        if (res.result[0].isError) {
          setGuid(() => {
            return res.result[0].guid;
          });
          setError("---> " + res.result[0].error);
        } else {
          setSuccess(
            " *" +
              res.displayMessage +
              " Rechnungsnummer: '" +
              res.result[0].rechnungsNummer +
              "', Pdf: '" +
              res.result[0].pdfFileName +
              "'"
          );
          setIsPdfCreated(true);
        }
      } else {
        //when exception by server-side
        if (typeof res.result === "undefined") {
          setError(" " + slice(res.Error + "/" + res.ErrorDetail, 200, "..."));
        }
      }
    } else {
      if (typeof res.result === "undefined") {
        setError(slice(res.Error + "/" + res.ErrorDetail, 200, "..."));
      } else {
        if (res.result[0].isError) {
          setGuid(() => {
            return res.result[0].guid;
          });
          setError(" --> " + res.displayMessage + " Please check log files!:");
        } else {
          setSuccess(" " + res.displayMessage);
        }
      }
    }
    setWait("");
  }

  async function handleDialogPdfDateChoice(choiceObject) {
    if (choiceObject.choice === "OK") {
      setError("");
      setSuccess("");

      if (IsValid()) {
        if (choiceObject.date === null) {
          setError("PDF-Datum ist falsch: ", choiceObject.date);
          return false;
        }
        console.log("PDF-Will be Created");
        await RequestCreatePdf(choiceObject.date);
      }
    }
  }

  async function handleOpenDialogDate() {
    //setStartCreatePdf(false);

    setIsDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  async function handleShow(e) {
    if (e) e.preventDefault();
    setGrandTotalAmountValue([]);
    setGrandTotalHoursValue([]);
    setIsPdfCreated(false);
    setGuid("");
    setError("");
    setSuccess("");
    setWait("");
    setCalcData([]);
    setIsDone(false);

    if (IsValid()) {
      await setStorageItem("Calculation", "regionId", getValue(regionId));
      await setStorageItem("Calculation", "branchId", getValue(branchId));
      await setStorageItem("Calculation", "studentId", getValue(studentId));

      if (studentId > 0) {
        setIsFirst(false);
        setWait("Bitte warten.....");

        setReload(!reload);

        await setStorageItem("Calculation", "dateFrom", dateFrom.current.value);
        await setStorageItem("Calculation", "dateTo", dateTo.current.value);
      } else {
        setError("Bitte 'Schüler/in' auswählen!");
      }
    }
    setIsDateChanged(false);
    setWait("");
  }

  function IsValid() {
    dateFrom.current.style.backgroundColor = "transparent";
    if (getValue(dateFrom.current.value) === 0) {
      setError("Zeitraum : Von ist falsch");
      dateFrom.current.style.backgroundColor = "yellow";
      return false;
    }
    if (getValue(dateTo.current.value) === 0) {
      setError("Zeitraum : Zum ist falsch");
      dateFrom.current.style.backgroundColor = "yellow";
      return false;
    }
    if (Date.parse(dateTo.current.value) < Date.parse(dateFrom.current.value)) {
      setError("Zeitraum: Von/Zum ist falsch");
      dateFrom.current.style.backgroundColor = "yellow";
      return false;
    }
    if (branchId === 0) {
      setError("Abrechnungstelle ist falsch");
      return false;
    }

    return true;
  }
  function GetDateFromString(strDate) {
    const datefrom = showDateOnly(strDate.split("-"));

    const [year1, month1, day1] = datefrom.split(",");

    const isoDate = `${year1}-${month1.padStart(2, "0")}-${day1.padStart(
      2,
      "0"
    )}`;

    return isoDate;
  }
  async function handleRowClick(
    event,
    filteredDateFrom,
    filteredDateTo,
    totalHours,
    period,
    pdfFileName
  ) {
    if ((period === "" || period.includes("*")) && totalHours > 0) {
      dateFrom.current.value = GetDateFromString(filteredDateFrom);

      dateTo.current.value = GetDateFromString(filteredDateTo);
      handleShow(event);
    }
    if (pdfFileName.length > 0) {
      let props = { path: "Calculation/pdf/" + pdfFileName };
      await WebRequestPdfViewer(props);
    }
  }
  const handleTotalHoursChange = (event, index) => {
    const numberValue = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    const newData = [...calcData];
    newData[index].totalHours = numberValue;
    newData[index].amount = numberValue * newData[index].priceValue;
    setCalcData(newData);
    setGrandTotalAmountValue(newData);
    setGrandTotalHoursValue(newData);
  };
  function handleOnBranchPopulate(branchIds) {
    if (branchIds.length > 0) {
      if (!readFromAuftrag) {
        setBranchId(getValue(branchIds[0]));
      }
      setIsBranchLoaded(true);
    }
  }

  function handleOnStudentPopulate(studentsIds) {
    if (studentsIds.length > 0) {
      setIsStudentLoaded(true);
    }
    if (!readFromAuftrag) {
      if (studentsIds[0] === -1 || studentId === -1) {
        setStudentId(0);
      } else setStudentId(getValue(studentsIds[0]));
    }
    // case not loaded all students, because some permissions or errors, then delete
    if (studentsIds.length <= 1) {
      //removeFromStorage("Calculation");
    }
  }

  const handleKeyDown = (event, rowIndex, columnIndex) => {
    const rowCount = inputRefs.current.length;
    const columnCount = inputRefs.current[rowIndex]?.length;

    if (event.key === "ArrowDown" && rowIndex < rowCount - 1 && columnCount) {
      event.preventDefault();
      inputRefs.current[rowIndex + 1]?.[columnIndex]?.focus();
      inputRefs.current[rowIndex + 1]?.[columnIndex]?.select();
    } else if (event.key === "ArrowUp" && rowIndex > 0 && columnCount) {
      event.preventDefault();
      inputRefs.current[rowIndex - 1]?.[columnIndex]?.focus();
      inputRefs.current[rowIndex - 1]?.[columnIndex]?.select();
    }
  };
  const generateFormattedText = (data) => {
    let status = getStatus(
      data.totalHours,
      data.period,
      data.rechnungsnummer
    ).Text;
    const formattedText =
      `${data.courseName}\t${data.filteredDateFrom}\t${data.filteredDateTo}\t` +
      `${data.priceName}\t${data.priceValue}\t${data.totalHours}\t${data.amount}\t` +
      `${data.period}\t${data.rechnungsnummer}\t${status}`;

    return formattedText;
  };
  function copyFromTableToClipboard() {
    try {
      var header = `Fach\tVon\tBis\t(E/G)\tPreis (€)\tStunde\tBetrag (€)\tZeitraum\tRechnungsnummer\tStatus`;
      let allFormattedText = header + "\n";

      calcData.forEach((item) => {
        const formattedText = generateFormattedText(item);
        allFormattedText += formattedText + "\n";
      });

      const sumOfHours = calcData.reduce(
        (sum, item) => sum + item.totalHours,
        0
      );
      const sumOfAmounts = calcData.reduce((sum, item) => sum + item.amount, 0);

      allFormattedText += `\t\t\t\tGesamt\t${sumOfHours}\t${sumOfAmounts}\n`;

      const el = document.createElement("textarea");
      el.value = allFormattedText;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      console.log("Formatted text copied to clipboard:\n", allFormattedText);
    } catch (ex) {
      console.log("Error By Copying Cells");
    }
  }

  return (
    <>
      {error.length > 0 &&
        (error.toLowerCase().includes("error") ||
          error.toLowerCase().includes("please")) && (
          <div className="alert alert-danger" role="alert">
            <CheckError />
            {slice(error, 200, "...")}
          </div>
        )}

      {error.length > 0 && guid && (
        <div>
          <a
            href={`/SystemLogs?state=${guid}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "10px" }}
          >
            Please see System Logs
          </a>
        </div>
      )}

      {success.length > 0 && (
        <div className="alert alert-success" role="alert">
          <CheckOk />
          {slice(success, 350, "...")}
          {"\u00A0".repeat(3)}
        </div>
      )}

      {wait.length > 0 && (
        <div>
          <h4>Bitte warten ....</h4>
          <ProgressBar
            isLoading={true}
            percent={20}
            size={"large"}
            showInfo={false}
          />
        </div>
      )}
      <br></br>
      <div
        className="custom-container"
        style={{
          width: "1000px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <table className="table" style={{ width: "auto" }}>
            <tbody>
              <tr>
                <td
                  className="label-column"
                  style={{ verticalAlign: "middle", fontWeight: "Bold" }}
                >
                  Region
                </td>
                <td className="input-column">
                  <DropDownRegion
                    regionID={regionId}
                    onChange={handleRegionValue}
                  />
                </td>
                <td
                  className="label-column"
                  style={{ verticalAlign: "middle", fontWeight: "Bold" }}
                >
                  Abrechnungstelle
                </td>
                <td className="input-column">
                  <DropDownBranch
                    regionID={regionId}
                    branchID={branchId}
                    onChange={handleBranchValue}
                    onPopulate={handleOnBranchPopulate}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className="label-column"
                  style={{ verticalAlign: "middle", fontWeight: "Bold" }}
                >
                  Schüler/in
                </td>
                <td className="input-column">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DropDownStudent
                      regionID={regionId}
                      branchID={branchId}
                      studentID={studentId}
                      stateToFetch="orders"
                      onChange={handleStudentValue}
                      onPopulateStudents={handleOnStudentPopulate}
                    />
                    {"\u00A0".repeat(3)}
                  </div>
                </td>
                <td
                  className="label-column"
                  style={{ verticalAlign: "middle", fontWeight: "Bold" }}
                >
                  Zeitraum
                </td>
                <td className="input-column" colSpan="2">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="dateFrom"
                      name="from"
                      defaultValue={getFirstDayOfThisMonth()}
                      type="date"
                      className="form-control"
                      ref={dateFrom}
                      onChange={handleOnDateFromChanged}
                    />
                    {"\u00A0".repeat(2)}
                    <input
                      id="dateTo"
                      name="to"
                      defaultValue={getLastDayOfThisMonth()}
                      type="date"
                      className="form-control"
                      ref={dateTo}
                      onChange={handleOnDateFromChanged}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="btn btn-primary" onClick={handleShow}>
            Vorschau
          </button>
          {"\u00A0".repeat(2)}
          <button
            className="btn btn-primary"
            onClick={handleOpenDialogDate}
            disabled={
              isDone ||
              isPdfCreated ||
              (error.length && studentId > 0) > 0 ||
              (isDateChanged && studentId > 0) ||
              (calcData.length === 0 && studentId > 0)
            }
          >
            PDF &gt;&gt;
          </button>
          {"\u00A0".repeat(1)}

          <div>
            <DialogPdfConfirmDate
              open={isDialogOpen}
              handleClose={handleCloseDialog}
              onChoice={handleDialogPdfDateChoice}
            />
          </div>
          {"\u00A0".repeat(1)}
          <button
            onClick={(e) => copyFromTableToClipboard(document, "calculations")}
            className="custom-button"
            disabled={!(calcData && calcData.length)}
          >
            <Copy className="icon" /> Copy
          </button>
          {"\u00A0".repeat(2)}
          <button
            onClick={(e) => handleCopyTableToExcel(document, "calculations")}
            className="custom-button"
            disabled={!(calcData && calcData.length)}
          >
            <Export className="icon" /> XLSX
          </button>
          {"\u00A0".repeat(5)}
          {isDone && (
            <Label
              style={{
                backgroundColor: "rgba(0, 255, 0, 0.2)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Berechnet!!
            </Label>
          )}
        </div>
        <br />
      </div>
      <div>{getGrid()}</div>
    </>
  );
  function handleCopyTableToCSV(tableId) {
    if (studentId === 0) return;

    const delima = ";";
    const table = document.getElementById(tableId);
    if (!table) {
      console.error(`Table with ID "${tableId}" not found.`);
      return;
    }

    const rows = table.querySelectorAll("tr");
    if (rows.length === 0) {
      console.error("The table has no rows.");
      return;
    }

    const csvData = [];
    // csvData.push(headersData.join(delima));

    rows.forEach((row) => {
      const rowData = [];
      const columns = row.querySelectorAll("td, th");
      columns.forEach((column) => {
        if (column.querySelector("input")) {
          rowData.push(column.querySelector("input").value.trim());
        } else {
          rowData.push(column.textContent.trim());
        }
      });
      csvData.push(rowData.join(delima));
    });

    var fileName = calcData.length > 0 ? calcData[0].studentName : null;
    fileName = fileName + "_Rechnung";

    const csvString = csvData.join("\n");
    const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const csvUrl = URL.createObjectURL(csvBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = `${fileName}.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function handleCopyTableToExcel(document, tableId) {
    if (studentId === 0) return;
    const fileName = calcData.length > 0 ? calcData[0].studentName : null;
    ToExcel(document, tableId, fileName + "_Rechnung");
  }
  function getStatus(totalHours, period, rechnungsnummer) {
    if (period === "" || period.includes("*")) {
      return {
        Text: "Nicht berechnet",
        BackgroundColor: "rgb(235, 177, 52)",
        cursor: "pointer",
      };
    }

    if (totalHours === 0 || period.includes("X")) {
      return {
        Text: "Nicht erlaubt",
        BackgroundColor: "rgb(236, 63, 11)",
        cursor: "auto",
      };
    }
    if (rechnungsnummer?.length > 0) {
      return {
        Text: "Berechnet",
        BackgroundColor: "rgb(49, 150, 21)",
        cursor: "pointer",
      };
    }
    if (totalHours > 0 && rechnungsnummer?.length === 0 && period !== "") {
      return {
        Text: "Bereit",
        BackgroundColor: "rgb(14, 143, 224)",
        cursor: "auto",
      };
    } else {
      return {
        Text: "Fehler",
        BackgroundColor: "rgb(236, 63, 11)",
        cursor: "auto",
      };
    }
  }

  function getGrid() {
    return (
      <>
        {/* <Legend /> */}

        <div
          className="custom-container"
          style={{
            width: "1000px",
            maxHeight: "500px",
            overflowY: "auto",
            justifyContent: "center",
          }}
        >
          <table id="calculations" className="custom-table">
            <thead>
              <tr>
                <th>Nachhilfefach</th>
                <th> Von</th>
                <th> Bis</th>
                <th> (E/G)</th>
                <th>Preis pro Einheit (€)</th>
                <th>Stunde</th>
                <th>Betrag (€)</th>
                <th>Zeitraum</th>
                <th>Rech.</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {calcData.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    style={{
                      textAlign: "left",
                      width: "150px",
                      paddingLeft: "20px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {item.courseName}
                  </td>

                  <td style={{ width: "100px", textAlign: "center" }}>
                    {showDateOnly(item.filteredDateFrom)}
                  </td>
                  <td style={{ width: "100px", textAlign: "center" }}>
                    {showDateOnly(item.filteredDateTo)}
                  </td>
                  <td style={{ width: "60px", textAlign: "center" }}>
                    {item.priceName}
                  </td>
                  <td style={{ width: "80px", textAlign: "center" }}>
                    {item.priceValue}
                  </td>
                  <td className="input-wrapper">
                    <input
                      autoFocus
                      className="custom-input"
                      disabled={isDone || isPdfCreated || error.length > 0}
                      ref={(ref) => {
                        if (!inputRefs.current[rowIndex]) {
                          inputRefs.current[rowIndex] = [];
                        }
                        inputRefs.current[rowIndex][5] = ref;
                      }}
                      type="text"
                      maxLength={3}
                      style={{ width: "99%", textAlign: "right" }}
                      value={item.totalHours}
                      onChange={(event) =>
                        handleTotalHoursChange(event, rowIndex)
                      }
                      onKeyDown={(event) => handleKeyDown(event, rowIndex, 5)}
                    />
                  </td>
                  <td style={{ width: "80px", textAlign: "right" }}>
                    {item.amount.toLocaleString("en")}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {item.period}
                  </td>
                  <td style={{ width: "120px", textAlign: "center" }}>
                    {item.rechnungsnummer}
                  </td>
                  <td>
                    <label
                      className="custom-status"
                      onClick={(e) =>
                        handleRowClick(
                          e,
                          item.filteredDateFrom,
                          item.filteredDateTo,
                          item.totalHours,
                          item.period,
                          item.pdfFileName
                        )
                      }
                      style={{
                        backgroundColor: getStatus(
                          item.totalHours,
                          item.period,
                          item.rechnungsnummer
                        ).BackgroundColor,
                        cursor: getStatus(
                          item.totalHours,
                          item.period,
                          item.rechnungsnummer
                        ).cursor,
                      }}
                    >
                      {
                        getStatus(
                          item.totalHours,
                          item.period,
                          item.rechnungsnummer
                        ).Text
                      }
                    </label>
                  </td>
                  <td style={{ display: "none" }}>{item.pdfFileName}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontWeight: "bold", textAlign: "right" }}>
                  Gesamt
                </td>
                <td style={{ fontWeight: "bold", textAlign: "right" }}>
                  {totalHoursGrand.toLocaleString()}
                </td>
                <td style={{ fontWeight: "bold", textAlign: "right" }}>
                  {totalAmountGrand.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="custom-container"
          style={{
            width: "1000px",
            overflowY: "auto",
            maxHeight: "300px",
            justifyContent: "center",
          }}
        >
          <StudentPdfFilesGrid
            StudentId={isFirst ? 0 : studentId}
            Reload={!reload}
            PdfCreated={!isPdfCreated}
            handleShow={(e) => handleShow(e)}
          ></StudentPdfFilesGrid>
        </div>
      </>
    );
  }
};

export default Calculation;
