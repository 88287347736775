import "./TreeViewTracker.css";
import { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { Get } from "../../../Common/WebRequest";
import {
  getStorageItem,
  getString,
  getValue,
  removeFromStorage,
  slice,
} from "../../../Common/Utility";
import { Place } from "@mui/icons-material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import BranchIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlineSharp";
import CourseIcon from "@mui/icons-material/AutoStoriesOutlined";

function TreeViewTracker(props) {
  const [searchText, setSearchText] = useState("");
  const [foundElements, setFoundElements] = useState([]);

  const [reload, setReload] = useState(false);

  const [error, setError] = useState("");
  const [wait, setWait] = useState("");
  const [ordersData, setOrdersData] = useState([]);
  const [selectedIds, setSelectedIds] = useState(["2"]);
  const [expandedIds, setExpandedIds] = useState(["01", "1-R", "2"]);
  const [firstTime, setFirstTime] = useState(true);
  const [defaultRegionId, setDefaultRegionId] = useState(1);
  const [defaultBranchId, setDefaultBranchId] = useState(2);
  const [newStudentAddedId, setNewStudentAddedId] = useState(0);

  //const [selectedNodeId, setSelectedNodeId] = useState("");

  useEffect(() => {
    (async () => {
      await LoadData();
    })();
  }, []); //, props.isSelectedCourse  //isSelectedStudent

  useEffect(() => {
    (async () => {
      if (ordersData && ordersData.length > 0) {
        if (props.selectedStudentsToBeAddedToTree) {
          await handleAddStudent(ordersData);
        }
      }
    })();
  }, [props.isSelectedStudent, props.selectedStudentsToBeAddedToTree.length]);

  useEffect(() => {
    (async () => {
      const nodeElement = document.getElementById(
        props.BranchId.toString() + "/" + props.StudentId.toString()
      );
      MoveScrollBarToNode(nodeElement);
    })();
  }, [newStudentAddedId]);

  useEffect(() => {
    (async () => {
      if (ordersData && ordersData.length > 0) {
        if (props.selectedCoursesToBeAddedToTree) {
          await handleAddCourse(ordersData);
        }
      }
    })();
  }, [props.isSelectedCourse]);

  useEffect(() => {
    (async () => {
      if (props.CourseSaved) {
        await handleCourseSaved();

        //setFirstTime(false);

        setReload(!reload);
      }
    })();
  }, [props.CourseSaved]);

  useEffect(() => {
    (async () => {
      if (props.CourseSaved) {
        const isFinish = await processStudents(ordersData); // if has saved course --> replace'*' -> ''
        if (isFinish) {
          let isRemoved = await removeEmptyStudent(ordersData);
          if (isRemoved) {
            await RemoveEmptyStudentFromStorage();
            handleOnStudentClick(props.RegionId, props.BranchId, 0);
            handleOnBranchClick(props.RegionId, props.BranchId);
          } else {
            handleOnStudentClick(
              props.RegionId,
              props.BranchId,
              props.StudentId
            );
            var queryId =
              props.BranchId + "/" + props.StudentId + "/" + props.CourseId;
            handleOnCourseClick(
              props.RegionId,
              props.BranchId,
              props.StudentId,
              props.CourseId,
              queryId
            );
            selectNextCourseNode(props.CourseId);
          }
        }
      }
    })();
  }, [props.CourseSaved]);

  useEffect(() => {
    (async () => {
      if (!firstTime) return;

      var selectedNodes = await getSelectedNodeToExpand();
      if (selectedNodes.length > 0) {
        let regionIdValue = await getStorageItem("Tree", "regionId");
        let branchIdValue = await getStorageItem("Tree", "branchId");
        let studentIdValue = await getStorageItem("Tree", "studentId");
        let courseIdValue = await getStorageItem("Tree", "courseId");

        if (getValue(branchIdValue) > 0) {
          setSelectedIds([branchIdValue.toString()]);
          setExpandedIds(selectedNodes);
          setDefaultRegionId(regionIdValue);
          setDefaultBranchId(branchIdValue);

          //setSelectedIds([branchIdValue.toString()]);
        }
        if (ordersData.length > 0 && getValue(props.courseId) === 0) {
          //handleOnBranchClick(regionIdValue, branchIdValue);

          if (getValue(courseIdValue) > 0) {
            var queryId =
              branchIdValue + "/" + studentIdValue + "/" + courseIdValue;
            handleOnStudentClick(regionIdValue, branchIdValue, studentIdValue);
            handleNodeSelect(
              null,
              branchIdValue.toString() + "/" + studentIdValue.toString()
            );
            expandNode(
              branchIdValue.toString() + "/" + studentIdValue.toString()
            );
            handleNodeSelect(
              null,
              branchIdValue.toString() +
                "/" +
                studentIdValue.toString() +
                "/" +
                courseIdValue
            );
            expandNode(
              branchIdValue.toString() + "/" + studentIdValue.toString()
            );
            handleNodeSelect(
              null,
              branchIdValue.toString() +
                "/" +
                studentIdValue.toString() +
                "/" +
                courseIdValue
            );
            handleOnCourseClick(
              regionIdValue,
              branchIdValue,
              studentIdValue,
              courseIdValue,
              queryId
            );
          } else if (getValue(studentIdValue) > 0) {
            handleOnStudentClick(regionIdValue, branchIdValue, studentIdValue);
            handleNodeSelect(
              null,
              branchIdValue.toString() + "/" + studentIdValue.toString()
            );
            expandNode(
              branchIdValue.toString() + "/" + studentIdValue.toString()
            );
          } else if (getValue(branchIdValue) > 0) {
            handleOnBranchClick(regionIdValue, branchIdValue);
          } else if (getValue(branchIdValue) === 0) {
            handleOnBranchClick(defaultRegionId, defaultBranchId); //default
          }

          const nodeElement = document.getElementById(
            branchIdValue.toString() + "/" + getValue(studentIdValue)
          );
          if (nodeElement) {
            MoveScrollBarToNode(nodeElement);
          }
          if (nodeElement === null) {
            //getValue(studentIdValue) === 0 || getValue(courseIdValue) === 0) {
            await RemoveEmptyStudentFromStorage();
            handleOnBranchClick(regionIdValue, branchIdValue);
          }
        }
      }
    })();
  }, [ordersData]); //, props.isSelectedCourse  //isSelectedStudent

  async function LoadData() {
    setWait("Bitte warten..");
    console.log("Bitte warten..............");
    let res = await Get("TrackerStudent/Order");
    if (res.isSuccess) {
      setOrdersData(res.result.regions);

      /* if (props.CourseSaved) {
        handleCourseSaved();
        setFirstTime(false);
      }*/
      setWait("");
    } else {
      setError(res.Status + "/" + res.Error);
    }
  }

  async function RemoveEmptyStudentFromStorage() {
    let regionIdValue = await getStorageItem("Tree", "regionId");
    let branchIdValue = await getStorageItem("Tree", "branchId");

    //removeFromStorage("Tree");
    localStorage.setItem(
      "Tree",
      JSON.stringify({
        regionId: regionIdValue,
        branchId: branchIdValue,
        strudentId: 0,
      })
    );
  }

  const handleSearchTextChange = (event) => {
    setFoundElements([]);
    setSearchText(event.target.value);
  };
  async function getSelectedNodeToExpand() {
    let node = ["01", "1-R", "0"];
    let regionIdValue = await getStorageItem("Tree", "regionId");
    let branchIdValue = await getStorageItem("Tree", "branchId");
    let studentIdValue = await getStorageItem("Tree", "studentId");
    let courseIdValue = await getStorageItem("Tree", "courseId");
    if (getValue(regionIdValue) > 0 && getValue(branchIdValue) > 0) {
      node = ["01", regionIdValue.toString() + "-R", branchIdValue.toString()];
    }

    if (
      getValue(regionIdValue) > 0 &&
      getValue(branchIdValue) > 0 &&
      getValue(studentIdValue) > 0
    ) {
      node = [
        "01",
        regionIdValue.toString() + "-R",
        branchIdValue.toString(),
        studentIdValue.toString(),
      ];
    }
    if (
      getValue(regionIdValue) > 0 &&
      getValue(branchIdValue) > 0 &&
      getValue(studentIdValue) > 0 &&
      getValue(courseIdValue) > 0
    ) {
      node = [
        "01",
        regionIdValue.toString() + "-R",
        branchIdValue.toString(),
        studentIdValue.toString(),
        courseIdValue.toString(),
      ];
    }
    //default
    return node;
  }

  function handleOnRegionClick(regionId) {
    localStorage.setItem(
      "Tree",
      JSON.stringify({
        regionId: regionId,
      })
    );
    props.onRegionClick(regionId);
  }
  function handleOnBranchClick(regionId, branchId) {
    localStorage.setItem(
      "Tree",
      JSON.stringify({
        regionId: regionId,
        branchId: branchId,
      })
    );
    getNewStudentsByNodeBranch(regionId, branchId);
  }

  function handleOnStudentClick(regionId, branchId, studentId) {
    getNewStudentsByNodeBranch(regionId, branchId);
    getNewCoursesByNodeStudent(regionId, branchId, studentId);
    /*if (getStudentIndexByIdRegionIndexAndBranchIndex(regionIndex,) < 0) {
      return;
    }*/
    localStorage.setItem(
      "Tree",
      JSON.stringify({
        regionId: regionId,
        branchId: branchId,
        studentId: studentId,
      })
    );
    // props.onStudentClick(regionId, branchId, studentId);
  }

  function handleOnCourseClick(
    regionId,
    branchId,
    studentId,
    courseId,
    queryId,
    courseName = ""
  ) {
    getNewStudentsByNodeBranch(regionId, branchId);
    getNewCoursesByNodeStudent(regionId, branchId, studentId);
    props.onCourseClick(regionId, branchId, studentId, courseId, queryId);
    console.log("courseName: : " + courseName);
    if (courseName.includes("+")) {
      //|| courseName.trim().length === 0) {
      // course added +, and not saved --> do not store it
      courseId = 0;
      queryId = "";
    }
    localStorage.setItem(
      "Tree",
      JSON.stringify({
        regionId: regionId,
        branchId: branchId,
        studentId: studentId,
        courseId: courseId,
      })
    );
  }

  function getNewStudentsByNodeBranch(regionId, branchId) {
    var regionIndex = getRegionIndexById(regionId);

    if (regionIndex < 0) return;

    const nodeBranch = ordersData[regionIndex].branches.find(
      (branch) => branch.id === branchId
    );
    var studentIds = [];
    if (nodeBranch) {
      studentIds = nodeBranch.students?.filter((student) =>
        student.studentName.includes("*")
      );
      studentIds = studentIds.map((student) => student.id);
      console.log("Old added students :", studentIds);
    }
    props.onBranchClick(regionId, branchId, studentIds);
    if (studentIds.length === 0) {
      // setReload(!reload);
    }
    return studentIds;
  }

  function getNewCoursesByNodeStudent(regionId, branchId, studentId) {
    var regionIndex = getRegionIndexById(regionId);

    if (regionIndex < 0) return;
    var branchIndex = getBranchIndexByRegionIndexAndBranchId(
      regionIndex,
      branchId
    );
    if (branchIndex < 0) return;

    const nodeStudent = ordersData[regionIndex].branches[
      branchIndex
    ].students.find((student) => student.id === studentId);
    var courseIds = [];
    if (nodeStudent) {
      courseIds = nodeStudent.courses?.filter((course) =>
        course.courseName.startsWith("+")
      );
      courseIds = courseIds.map((course) => course.id);
      console.log("Old added courses :", courseIds);
    }
    props.onStudentClick(regionId, branchId, studentId, courseIds);
  }

  function getStudentNameWithCoursesCount(studentName, courses) {
    let result = "";
    if (getValue(courses?.length) > 0) {
      result = studentName + " (" + courses.length + ")";
    } else result = studentName;
    return result;
  }

  async function handleAddStudent(ordersDataOrg) {
    console.log("handleAddStudent");
    console.log(
      "Selected Students --> into Tree ::: ",
      props.selectedStudentsToBeAddedToTree
    );

    if (
      !Array.isArray(props.selectedStudentsToBeAddedToTree) ||
      typeof props.selectedStudentsToBeAddedToTree === "undefined" ||
      props.BranchId === 0 ||
      !ordersData ||
      ordersData.length === 0
    ) {
      //handleNodeSelect(null, selectedIds);

      return;
    }

    var regionIndex = getRegionIndexById(props.RegionId);
    var branchIndex = getBranchIndexByRegionIndexAndBranchId(
      regionIndex,
      props.BranchId
    );

    let studentsToBeRemoved = [];
    console.log("RegionIndex ===>    ===> ", regionIndex);
    if (regionIndex === -1) return;
    studentsToBeRemoved = ordersData[regionIndex].branches[
      branchIndex
    ].students.filter((item) => item.studentName.includes("*"));

    studentsToBeRemoved = studentsToBeRemoved.filter(
      (student) =>
        !props.selectedStudentsToBeAddedToTree.find((s) => s.id === student.id)
    );

    let newData = [...ordersDataOrg];
    var sortedStudentToBeAdded = props.selectedStudentsToBeAddedToTree;
    /*var sortedStudentToBeAdded = sortByName(
      props.selectedStudentsToBeAddedToTree
    );*/
    sortedStudentToBeAdded?.map((newStudent) => {
      const hasStudent = newData[regionIndex].branches[
        branchIndex
      ].students.some((student) => student.id === newStudent.id);
      if (!hasStudent && newStudent.branchId === props.BranchId) {
        ///////////////////////////
        newStudent.studentName = newStudent.studentName
          .replace(/\*/g, "")
          .trim();
        newStudent.studentName = newStudent.studentName + " *";
        newStudent.courses = [];

        newData[regionIndex].branches[branchIndex].students.push(newStudent);
        // setNewStudentAdded(newStudent.id);

        props.onStudentClick(props.RegionId, props.BranchId, newStudent.id);
        // Sort the students array alphabetically based on studentName
        newData[regionIndex].branches[branchIndex].students.sort((a, b) =>
          a.studentName.localeCompare(b.studentName)
        );

        setOrdersData(newData);
      }
    });

    console.log("ordersData --> Now::", ordersData);
    // remove unSelected students from tree->>  unselected students comes from StudentDialog

    newData[regionIndex].branches[branchIndex].students = newData[
      regionIndex
    ].branches[branchIndex].students.filter(
      (student) => !studentsToBeRemoved.find((s) => s.id === student.id)
    );
    // if (JSON.stringify(newData) === JSON.stringify(ordersData)) {
    //   console.log("equal");
    // }
    setOrdersData(newData);
    //handleOnStudentClick(defaultRegionId, defaultBranchId, 0);

    if (
      props.selectedStudentsToBeAddedToTree.length === 0 &&
      props.StudentId === 0
    ) {
      selectNextStudentNode(0);
    } else {
      if (props.selectedStudentsToBeAddedToTree.length === 0) {
        handleOnStudentClick(props.RegionId, props.BranchId, 0);
        return;
      }
      let newStudentId =
        props.selectedStudentsToBeAddedToTree[
          props.selectedStudentsToBeAddedToTree.length - 1
        ].id; // first
      handleOnStudentClick(props.RegionId, props.BranchId, newStudentId);
      setNewStudentAddedId(newStudentId);
    }
  }

  async function handleCourseSaved() {
    console.log("handleCourseSaved: true");
    var nextCourse = 0;
    // if removed from db, then remove it from tree
    //processStudents(ordersData);
    if (props.ResultCount === 0) {
      const region = ordersData.find((region) => region.id === props.RegionId);
      if (region) {
        const branch = region.branches.find(
          (branch) => branch.id === props.BranchId
        );
        if (branch) {
          const student = branch.students.find(
            (student) => student.id === props.StudentId
          );
          if (student?.courses) {
            if (student.courses.length > 1) {
              if (
                props.CourseId &&
                student.courses[student.courses.length - 1].id !==
                  props.CourseId
              ) {
                nextCourse = student.courses[student.courses.length - 1].id;
              } else {
                nextCourse = student.courses[0].id;
              }
            } else if (student.courses.length <= 1) {
              nextCourse = 0;
            } else {
              nextCourse = 0;
            }
            student.courses = student.courses.filter(
              (course) => course.id !== props.CourseId
            );
            // props.selectedCoursesToBeAddedToTree.unshift(course)

            let courseFound = null;
            if (props.selectedCoursesToBeAddedToTree)
              courseFound = props.selectedCoursesToBeAddedToTree.find(
                (course) => course.id === props.CourseId
              );
            if (
              typeof courseFound !== "undefined" &&
              props.selectedCoursesToBeAddedToTree
            ) {
              console.log("Course Found:", courseFound);
              var removedCourse = props.selectedCoursesToBeAddedToTree.filter(
                (course) => course.id !== courseFound.id
              );
              if (typeof removedCourse !== "undefined") {
                //props.selectedCoursesToBeAddedToTree = removedCourse;
                props.onCoursesAdded(removedCourse);
                //console.log("removedCourse :::: ", removedCourse);
                props.onCourseSaved(false);

                setOrdersData(ordersData);

                //setNewStudentAdded(0);
              }
            }
          }
        }
        selectNextCourseNode(nextCourse);

        return;
      }
    }
    nextCourse = 0;
    const student = ordersData
      .find((region) => region.id === props.RegionId)
      ?.branches.find((branch) => branch.id === props.BranchId)
      ?.students.find((student) => student.id === props.StudentId);

    if (student?.courses) {
      nextCourse = student.courses[0];
      const course = student.courses.find(
        (course) => course.id === props.CourseId
      );
      if (course && course.courseName.includes("+")) {
        course.courseName = course.courseName.replace(/\+/g, " ");

        setOrdersData(ordersData);

        handleOnCourseClick(
          props.RegionId,
          props.BranchId,
          props.StudentId,
          props.CourseId,
          course.courseName
        );

        //await handleRefresh();
      } else await handleRefresh();
    }
  }
  async function handleAddCourse(ordersDataOrg) {
    //if (ordersData.length === 0) setOrdersData(ordersDataOrg);
    let isAdded = false;
    if (
      !Array.isArray(props.selectedCoursesToBeAddedToTree) ||
      typeof props.selectedCoursesToBeAddedToTree === "undefined" ||
      props.BranchId === 0 ||
      props.studentId === 0 ||
      !ordersData ||
      ordersData.length === 0
    ) {
      return;
    }

    var regionIndex = getRegionIndexById(props.RegionId);
    var branchIndex = getBranchIndexByRegionIndexAndBranchId(
      regionIndex,
      props.BranchId
    );
    var studentIndex = getStudentIndexByIdRegionIndexAndBranchIndex(
      regionIndex,
      branchIndex
    );
    if (
      studentIndex === -1 ||
      regionIndex === -1
      // || (newStudentAdded > 0 && newStudentAdded !== props.StudentId)
    ) {
      //await removeFromStorage("Tree");
      //setReload(!reload);
      //handleNodeSelect(null, "");
      //handleOnCourseClick(0, 0, 0, 0, "");
      //setSelectedIds([]);
      return;
    }

    let coursesToBeRemoved = [];

    coursesToBeRemoved = ordersData[regionIndex].branches[branchIndex].students[
      studentIndex
    ].courses.filter((item) => item.courseName.startsWith("+"));

    coursesToBeRemoved = coursesToBeRemoved.filter(
      (course) =>
        !props.selectedCoursesToBeAddedToTree.find(
          (c) => c.id === course.id && c.studentId === props.StudentId
        )
      // && !props.CourseSaved
    );

    const newData = [...ordersDataOrg];
    props.selectedCoursesToBeAddedToTree?.map((newCourse) => {
      const hasCourse = newData[regionIndex].branches[branchIndex].students[
        studentIndex
      ].courses.some((course) => course.id === newCourse.id);

      if (!hasCourse && newCourse.studentId === props.StudentId) {
        newCourse.courseName = newCourse.courseName.replace(/\+/g, "").trim();
        newCourse.courseName = "+ " + newCourse.courseName;
        newData[regionIndex].branches[branchIndex].students[
          studentIndex
        ].courses.unshift(newCourse);
        isAdded = true;
        setOrdersData(newData);
        let studentId =
          ordersData[regionIndex].branches[branchIndex].students[studentIndex]
            .id;

        // var selectedNode = selectNextCourseNode(newCourse.id);
        //setCurrentCourse(newCourse.id);
        expandNode(props.BranchId + "/" + studentId);
      }
    });

    // remove unSelected courses from tree->>  unselected courses comes from CourseDialog

    newData[regionIndex].branches[branchIndex].students[studentIndex].courses =
      newData[regionIndex].branches[branchIndex].students[
        studentIndex
      ].courses.filter(
        (course) => !coursesToBeRemoved.find((c) => c.id === course.id)
      );

    var cid =
      newData[regionIndex].branches[branchIndex].students[studentIndex]
        .courses[0]?.id;
    if (typeof cid === "undefined" || coursesToBeRemoved.length === 0) cid = 0;

    setFirstTime(false);

    setOrdersData(newData);
    expandNode(props.BranchId + "/" + props.StudentId);
    selectNextCourseNode(props.selectedCoursesToBeAddedToTree[0]);

    var choosenCourses = props.selectedCoursesToBeAddedToTree;
    if (choosenCourses.length === 0) {
      //selectNextCourseNode(cid);

      selectNextStudentNode(props.StudentId);
      selectNextCourseNode(cid);
      props.onCoursesAdded(null);
    } else {
      selectNextStudentNode(props.StudentId);

      selectNextCourseNode(
        choosenCourses[choosenCourses.length - 1].id,
        choosenCourses[choosenCourses.length - 1].courseName
      );

      const nodeElement = document.getElementById(
        props.BranchId.toString() + "/" + props.StudentId.toString()
      );
      MoveScrollBarToNode(nodeElement);
    }
  }

  function getStudentIndexByIdRegionIndexAndBranchIndex(
    regionIndex,
    branchIndex
  ) {
    if (regionIndex === -1) {
      return -1;
    }
    const studentIndex = ordersData[regionIndex].branches[
      branchIndex
    ].students.findIndex((student) => student.id === getValue(props.StudentId));

    return studentIndex;
  }
  async function processStudents(data) {
    const regionId = props.RegionId;
    const branchId = props.BranchId;
    const studentIdToProcess = props.StudentId;
    if (regionId === 0 || branchId === 0 || studentIdToProcess === 0) return;

    const region = data.find((r) => r.id === regionId);

    if (region) {
      const branch = region.branches.find((b) => b.id === branchId);

      if (branch) {
        const studentToProcess = branch.students.find(
          (student) => student.id === studentIdToProcess
        );

        if (studentToProcess) {
          let hasValidCourses =
            studentToProcess.courses &&
            studentToProcess.courses.some(
              (course) => course.courseName && !course.courseName.includes("+")
            );

          if (hasValidCourses) {
            studentToProcess.studentName = studentToProcess.studentName.replace(
              /\*/g,
              ""
            );
            setOrdersData(data);
          }
        }
      }
    }
    return true;
  }

  async function removeEmptyStudent(data) {
    let isFound = false;
    const regionId = props.RegionId;
    const branchId = props.BranchId;
    const studentIdToRemove = props.StudentId;
    if (regionId === 0 || branchId === 0 || studentIdToRemove === 0) return;

    const region = data.find((r) => r.id === regionId);

    if (region) {
      const branch = region.branches.find((b) => b.id === branchId);

      if (branch) {
        branch.students = branch.students.filter((student) => {
          if (student.id === studentIdToRemove) {
            if (!student.courses || student.courses.length === 0) {
              let allStudentsRemains = props.selectedStudentsToBeAddedToTree;
              const studentIdToRemove = props.StudentId;

              // Remove the student with the specified ID from the allStudents array
              allStudentsRemains = allStudentsRemains.filter(
                (student) => student.id !== studentIdToRemove
              );
              props.onStudentsAdded(allStudentsRemains);
              isFound = true;
            }
            return student.courses && student.courses.length > 0;
          }
          return true;
        });
      }
    }

    return isFound;
  }

  function getBranchIndexByRegionIndexAndBranchId(regionIndex, branchId) {
    if (regionIndex === -1) return -1;
    const branchIndex = ordersData[regionIndex].branches?.findIndex(
      (branch) => branch.id === getValue(branchId)
    );

    console.log("branchId : ", branchId);
    console.log("branchIndex: ", branchIndex);
    return branchIndex;
  }

  function getRegionIndexById(regionId) {
    if (!Array.isArray(ordersData)) return -1;
    const regionIndex = ordersData?.findIndex(
      (region) => region.id === getValue(regionId)
    );
    console.log("regionId : ", regionId);
    console.log("regionIndex: ", regionIndex);
    return regionIndex;
  }
  const handleNodeSelect = (event, ids) => {
    console.log("selected id: ", ids);
    setSelectedIds(ids);
  };

  function selectNextCourseNode(nextCourseId, courseName = "") {
    if (typeof nextCourseId === "undefined") return;
    if (nextCourseId > 0) {
      handleNodeSelect(
        null,
        props.BranchId + "/" + props.StudentId + "/" + nextCourseId
      );

      var queryId = props.BranchId + "/" + props.StudentId + "/" + nextCourseId;
      handleOnCourseClick(
        props.RegionId,
        props.BranchId,
        props.StudentId,
        nextCourseId,
        queryId,
        courseName
      );
    } else {
      handleNodeSelect(null, props.BranchId + "/" + props.StudentId);

      handleOnStudentClick(props.RegionId, props.BranchId, props.StudentId);
    }

    return queryId;
  }

  function selectNextStudentNode(nextStudentId) {
    handleNodeSelect(null, props.BranchId + "/" + nextStudentId);

    handleOnStudentClick(props.RegionId, props.BranchId, nextStudentId);
    return props.BranchId + "/" + nextStudentId;
  }

  function expandNode(nodeId) {
    if (!expandedIds.includes(nodeId)) {
      setExpandedIds([...expandedIds, nodeId]);
      console.log("*** expandNode: ", nodeId);
    }
  }

  const handleNodeToggle = (event, nodeIds) => {
    setExpandedIds(nodeIds);
    if (nodeIds === "") {
      handleOnBranchClick(defaultRegionId, defaultBranchId);
    }
  };

  async function handleRefresh() {
    props.onCoursesAdded([]);
    props.onStudentsAdded([]);
    props.onCourseSaved();

    if (props.CourseId > 0) {
      handleOnCourseClick(
        props.RegionId,
        props.BranchId,
        props.StudentId,
        props.CourseId
      );
    } else if (props.StudentId > 0) {
      handleOnStudentClick(props.RegionId, props.BranchId, props.StudentId);
    } else {
      handleOnBranchClick(defaultRegionId, defaultBranchId);
    }
    setReload(!reload);
  }

  function hasExpiredCourse(regionId, branchId, studentId) {
    const region = ordersData.find((region) => region.id === regionId);
    if (region) {
      const branch = region.branches.find((branch) => branch.id === branchId);
      if (branch) {
        const student = branch.students.find(
          (student) => student.id === studentId
        );
        if (student?.courses) {
          const foundExpiredCourse = student.courses.some(
            (course) => course.isExpired === true
          );
          if (foundExpiredCourse) return true;
        }
      }
    }
    return false;
  }
  function hasFoundText(regionId, branchId, studentId, nodeId) {
    // see also handleSearchTextChange

    if (searchText === "" || searchText.length <= 1) return false;
    const region = ordersData.find((region) => region.id === regionId);
    if (region) {
      const branch = region.branches.find((branch) => branch.id === branchId);
      if (branch) {
        const student = branch.students.find(
          (student) =>
            student.id === studentId &&
            searchText.length >= 1 &&
            student.studentName.toLowerCase().includes(searchText.toLowerCase())
        );

        if (getString(student?.studentName) !== "") {
          const nodeElement = document.getElementById(nodeId);

          if (nodeElement) {
            foundElements.unshift(nodeElement);
            if (foundElements.length >= 2) {
            } else {
              // });
              MoveScrollBarToNode(nodeElement);
            }
          }

          return true;
        }
      }
    }
    return false;
  }

  async function MoveScrollBarToNode(nodeElement) {
    if (nodeElement === null) {
      await removeFromStorage("Tree");
      //setReload(!reload);
      handleNodeSelect(null, "");
      handleOnCourseClick(0, 0, 0, 0, "");
      setSelectedIds([]);
      return;
    }
    if (nodeElement) {
      nodeElement.scrollIntoView({
        behavior: "instant",
        block: "start",
      });

      // Find the container that wraps the TreeView and set its scrollLeft to 0
      const containerElement = nodeElement.closest(".MuiTreeView-root");
      if (containerElement) {
        containerElement.scrollLeft = 0;
      }
    }
  }
  //--------------------- HTML -------------------------------------------------------------------
  return error.length > 0 ? (
    <>
      <div className="alert alert-danger" role="alert">
        {slice(error, 100, "...")}
      </div>
    </>
  ) : ordersData !== null ? (
    <div>
      <div className="TreeViewTrackerDiv">
        <div
          className="head"
          style={{
            width: "fit-content",
            alignContent: "baseline",
            color: "red",
            margin: "auto",
          }}
        >
          <strong> {wait}</strong>
          <div className="containerSearch">
            <button className="refreshButton" onClick={handleRefresh}>
              Refresh
            </button>
            <input
              autoFocus
              placeholder="Suche"
              className="searchInput"
              type="text"
              onChange={handleSearchTextChange}
            />
          </div>
        </div>

        <TreeView
          id="TreeView"
          onNodeSelect={handleNodeSelect}
          selected={selectedIds}
          expanded={expandedIds}
          onNodeToggle={handleNodeToggle}
          className="TreeView"
          aria-label="Studenten"
          sx={{
            margin: "auto",
            flexGrow: 2,
            width: "100%",
          }}
        >
          <TreeItem
            className="TreeItemStyle"
            nodeId="01"
            label="Regionen"
            icon={<Place />}
          >
            {ordersData !== null &&
              ordersData.length > 0 &&
              ordersData.map((region, gIndex) => (
                <TreeItem
                  className="TreeItemStyle"
                  nodeId={region.id + "-R"}
                  id={region.id + ""}
                  key={region.id + ""}
                  label={region.name + " (" + region.branches.length + ")"}
                  icon={<PlaceOutlinedIcon />}
                  onClick={(e) => handleOnRegionClick(region.id)}
                >
                  {region.branches.map((branch, index) => (
                    <TreeItem
                      className="TreeItemStyle"
                      nodeId={branch.id + ""}
                      id={branch.id + ""}
                      key={branch.id + ""}
                      label={branch.name + " (" + branch.students.length + ")"}
                      icon={<BranchIcon />}
                      onClick={(e) => handleOnBranchClick(region.id, branch.id)}
                    >
                      {branch.students.map((student) => (
                        <TreeItem
                          className="TreeItemStyle"
                          id={branch.id + "/" + student.id}
                          key={branch.id + "/" + student.id}
                          nodeId={branch.id + "/" + student.id}
                          label={
                            <span
                              style={{
                                color: hasExpiredCourse(
                                  region.id,
                                  branch.id,
                                  student.id
                                )
                                  ? "red"
                                  : "inherit",
                                backgroundColor: hasFoundText(
                                  region.id,
                                  branch.id,
                                  student.id,
                                  branch.id + "/" + student.id
                                )
                                  ? "yellow"
                                  : student.studentName.includes("*")
                                  ? "#adf802"
                                  : "transparent",
                              }}
                            >
                              {getStudentNameWithCoursesCount(
                                student.studentName,
                                student.courses
                              )}
                            </span>
                          }
                          icon={<PersonIcon />}
                          onClick={(e) =>
                            handleOnStudentClick(
                              region.id,
                              branch.id,
                              student.id
                            )
                          }
                        >
                          {student.courses?.map((course, index) => (
                            <TreeItem
                              className="TreeItemStyle"
                              icon={<CourseIcon />}
                              id={
                                branch.id + "/" + student.id + "/" + course.id
                              }
                              key={
                                branch.id + "/" + student.id + "/" + course.id
                              }
                              nodeId={
                                branch.id + "/" + student.id + "/" + course.id
                              }
                              // label={course.courseName}

                              label={
                                <span
                                  style={{
                                    color: course.isExpired ? "red" : "inherit",
                                    backgroundColor: course.courseName.includes(
                                      "+"
                                    )
                                      ? "#e2fea1"
                                      : "transparent",
                                  }}
                                >
                                  {course.courseName}
                                </span>
                              }
                              onClick={(e) => {
                                handleOnCourseClick(
                                  region.id,
                                  branch.id,
                                  student.id,
                                  course.id,
                                  branch.id +
                                    "/" +
                                    student.id +
                                    "/" +
                                    course.id,
                                  course.courseName
                                );
                              }}
                            ></TreeItem>
                          ))}
                        </TreeItem>
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
          </TreeItem>
        </TreeView>
      </div>
    </div>
  ) : null;
}

export default TreeViewTracker;
