import React, { useState, useEffect } from "react";
import "./CalculationDetailsDialog.css";
import { Get } from "../../../Common/WebRequest";
import { showDateOnly } from "../../../Common/Utility";
import { copyTableById } from "../../../Common/ExportTableToXls";
import { WebRequestPdfViewer } from "../../../Common/WebRequestPdfViewer";
import { toJsonDateTimeWithoutMilsec } from "./ValidationAutoComplete";

const CalculationDetailsDialog = ({
  BranchId,
  StudentId,
  CourseId,
  DateFrom,
  DateTo,
  UserDate,
  onClose,
}) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [currentCourse, setCurrentCourse] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchData();
  }, [BranchId, StudentId, DateFrom, DateTo, UserDate]);

  const fetchData = async () => {
    try {
      const response = await Get(
        `Calculation/Calculation/${BranchId}/${StudentId}/${DateFrom}/${DateTo}`
      );
      if (response.isSuccess) {
        const allStudentTracks = response.result.studentTracks;

        setData(allStudentTracks);
        setAllData(allStudentTracks);
        const courseResponse = await Get(`Course/${CourseId}`);
        if (courseResponse.isSuccess) {
          setCurrentCourse(courseResponse.result.courseName);
        }
        setIsLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const doFilter = async () => {
    if (!isLoaded) return;
    if (isChecked) {
      const filteredStudentTracks = allData.filter(
        (track) => track.courseId === CourseId
      );
      setData(filteredStudentTracks);
    } else {
      setData(allData);
    }
  };
  useEffect(() => {
    doFilter();
  }, [isChecked, isLoaded]);

  async function handleRowClick(
    event,

    pdfFileName
  ) {
    if (pdfFileName.length > 0) {
      let props = { path: "Calculation/pdf/" + pdfFileName };
      await WebRequestPdfViewer(props, true);
    }
  }
  const handleMouseDown = (e) => {
    setDragging(true);
    setPosition({
      x: e.clientX - e.currentTarget.getBoundingClientRect().left,
      y: e.clientY - e.currentTarget.getBoundingClientRect().top,
    });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    const left = e.clientX - position.x;
    const top = e.clientY - position.y;
    const dialog = document.querySelector(".custom-dialog");
    dialog.style.left = left + "px";
    dialog.style.top = top + "px";
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dragging]);

  function getStatus(totalHours, period, rechnungsnummer) {
    if (period === "" || period.includes("*")) {
      return {
        Text: "Nicht berechnet",
        BackgroundColor: "rgb(235, 177, 52)",
        cursor: "auto",
      };
    }

    if (totalHours === 0 || period.includes("X")) {
      return {
        Text: "Nicht erlaubt",
        BackgroundColor: "rgb(236, 63, 11)",
        cursor: "auto",
      };
    }
    if (rechnungsnummer?.length > 0) {
      return {
        Text: "Berechnet",
        BackgroundColor: "rgb(49, 150, 21)",
        cursor: "pointer",
      };
    }
    if (totalHours > 0 && rechnungsnummer?.length === 0 && period !== "") {
      return {
        Text: "Bereit",

        BackgroundColor: "rgb(14, 143, 224)", //"#F5F5F5", //smoke,
        cursor: "auto",
      };
    } else {
      return {
        Text: "Fehler",
        BackgroundColor: "rgb(236, 63, 11)",
        cursor: "auto",
      };
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="custom-dialog-overlay">
      <div
        className="custom-dialog"
        onMouseDown={handleMouseDown}
        style={{
          cursor: dragging ? "grabbing" : "grab",
          position: "absolute",
          zIndex: dragging ? 1000 : 1,
        }}
      >
        <div className="close-icon" onClick={onClose}>
          X
        </div>
        <h3 className="dialog-title">
          Nachhilfefach:&nbsp;{currentCourse}&nbsp;-&nbsp;&nbsp;&nbsp;{DateFrom}{" "}
          - {DateTo}
        </h3>
        <div className="table-container">
          {isLoading ? (
            <div className="center-container">
              <p>Loading........</p>
            </div>
          ) : (
            <table id="data-table" className="data-table">
              <thead>
                <tr className="table-header">
                  <th className="header-cell">Nachhilfefach</th>
                  <th className="header-cell">Von</th>
                  <th className="header-cell">Zum</th>
                  <th className="header-cell">(E/G)</th>
                  <th className="header-cell">Preis pro Einheit(€)</th>
                  <th className="header-cell">Stunde</th>
                  <th className="header-cell">Betrag(€)</th>
                  <th className="header-cell">Zeitraum</th>
                  <th className="header-cell">Rech.</th>
                  {/* <th className="header-cell">File</th> */}
                  <th className="header-cell">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      key={index}
                      className={`data-row ${
                        item.courseId === CourseId ? "bold-row" : "course-cell"
                      }`}
                    >
                      <td className="course-cell">{item.courseName}</td>
                      <td>{showDateOnly(item.filteredDateFrom)}</td>
                      <td>{showDateOnly(item.filteredDateTo)}</td>
                      <td>{item.priceName}</td>
                      <td>{item.priceValue}</td>
                      <td className="number-cell"> {item.totalHours}</td>
                      <td className="number-cell"> {item.amount}</td>
                      <td>{item.period}</td>
                      <td>{item.rechnungsnummer}</td>
                      {/* <td>{item.pdfFileName}</td> */}
                      <td>
                        <label
                          onClick={(e) => handleRowClick(e, item.pdfFileName)}
                          className="custom-status-text"
                          style={{
                            backgroundColor: getStatus(
                              item.totalHours,
                              item.period,
                              item.rechnungsnummer
                            ).BackgroundColor,

                            cursor: getStatus(
                              item.totalHours,
                              item.period,
                              item.rechnungsnummer
                            ).cursor,
                          }}
                        >
                          {
                            getStatus(
                              item.totalHours,
                              item.period,
                              item.rechnungsnummer
                            ).Text
                          }
                        </label>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10">No data available</td>
                  </tr>
                )}
                <tr>
                  <td colSpan="5" className="total-cell">
                    Gesamt{" "}
                  </td>
                  <td className="total-cell">
                    {Array.isArray(data) &&
                      data.reduce((acc, item) => acc + item.totalHours, 0)}
                  </td>
                  <td className="total-cell">
                    {Array.isArray(data) &&
                      data.reduce((acc, item) => acc + item.amount, 0)}
                  </td>
                  <td colSpan="4"></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="bottom-container">
          <input
            className="check-box"
            hidden={isLoading}
            type="checkbox"
            id="checkbox"
            name="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="checkbox" className="check-box-label">
            {currentCourse}
          </label>

          <label className="label-date">
            Last-Update: {toJsonDateTimeWithoutMilsec(UserDate)}
          </label>

          <button
            hidden={isLoading}
            className="action-button"
            onClick={(e) => copyTableById(document, "data-table")}
          >
            Copy
          </button>
          <button
            hidden={isLoading}
            className="action-button"
            onClick={onClose}
          >
            OK
          </button>
          <label hidden={!isLoading} className="empty-label"></label>
        </div>
      </div>
    </div>
  );
};

export default CalculationDetailsDialog;
