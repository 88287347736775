import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";
import "./NotFoundPage.css";

const NotFoundPage = () => {
  const handleGoHome = () => {
    window.location.href = "/Home";
  };

  return (
    <Container className="not-found-container">
      <Typography variant="h1" className="not-found-heading">
        Page Not Found
      </Typography>
      <br />
      <br />
      <Typography variant="body1" className="not-found-subheading">
        The page you are looking for does not exist.
      </Typography>
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        className="not-found-button"
      >
        Go Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
