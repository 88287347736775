import React from "react";
import UserChangePasswordForm from "./UserChangePasswordForm";

const UserProfile = () => {
  return (
    <div>
      <UserChangePasswordForm />
    </div>
  );
};

export default UserProfile;
