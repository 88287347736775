import React from "react";
import { Container, Paper, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const PasswordResetInvalid = ({ email }) => {
  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={3}
        style={{
          padding: "2em",
          marginTop: "8vh",
          textAlign: "left",
          borderRadius: "12px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Typography variant="h2" color="error" paragraph>
            Ungültige URL
          </Typography>
        </div>
        <br />
        <br />
        <Typography variant="h7" color="darkgray" paragraph>
          - Entschuldigung! Der angeforderte Link ist nicht mehr gültig. Sorry!
        </Typography>

        <Typography variant="h7" color="darkgray" paragraph>
          - The requested link is no longer valid.
        </Typography>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WarningAmberIcon
            fontSize="large" // or set a specific size like "2em" or "3em"
            style={{
              marginRight: "8px",
              color: "red",
            }}
          />
        </div>

        <br />
        <br />
        <div style={{ textAlign: "center", alignItems: "center" }}>
          <Button
            component={Link}
            to="/passwordrecovery"
            variant="contained"
            color="info"
            size="medium"
            sx={{
              "&:hover": {
                color: "#fff",
                backgroundColor: "#1976D2",
              },
              margin: "auto",
              display: "block",
              width: "fit-content",
            }}
          >
            Passwort-Wiederherstellung
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default PasswordResetInvalid;
