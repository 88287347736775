import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  DataGrid,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import { Delete, Get, Post, Put } from "../../Common/WebRequest";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { useNavigate } from "react-router-dom";
import { slice } from "../../Common/Utility";
import TeacherDialog from "./TeacherDialog";

function EditToolbar(props) {
  const { handleAddUpdateTeacher } = props;

  const [reload, setReload] = useState(false);
  let navigate = useNavigate();
  /*const handleAddClick = () => {
    const id = 0;
    setTeachersData((oldRows) => [
      ...oldRows,
      { id, teacherName: "", regionId: 0, branchId: 0, isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "teacherName" },
    }));
  };*/

  //---- Add Lehrkraft

  function handleRefresh() {
    navigate(0);
  }

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={(e) => handleAddUpdateTeacher(0)}
      >
        Neu
      </Button>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: "Lehrkräfte",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />

      <GridToolbarQuickFilter />
      <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setTeachersData: PropTypes.func.isRequired,
  handleAddUpdateTeacher: PropTypes.func.isRequired,
};

export default function TeachersGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [teachersData, setTeachersData] = useState([]);
  const [TeacherId, setTeacherId] = useState(0);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    (async () => {
      let res = await Get("teacher");
      let regions = await Get("region");
      let branches = await Get("branch");

      if (res.isSuccess) {
        setTeachersData(res.result);
        setRegionData(regions.result);
        setBranchData(branches.result);
      } else setError(res.Status + "/" + res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        teachersData.find((x) => x.id === id).teacherName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  //---------
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    //console.log("Cell lost focus / exit  edit ==> ", params, event);
    //console.log("event.target.value :: ******** ", params, event.target.value);
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "teacherName" },
    });
    // console.log("## Edit clicked -- id=", id);
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setTeachersData(
      teachersData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      res = await Post("teacher", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      res = await Put("teacher", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Die neue Lehrkräfte wurde hinzugefügt.")
        : setSuccessMsg("Die Lehrkräfte wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error);
      console.log("Error: ##### ", res.Error);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
    //setTeachersData(teachersData.filter((row) => row.id !== id));
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("teacher", id);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Die Lehrkräfte wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = teachersData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setTeachersData(teachersData.filter((row) => row.id !== id));
    }
  };
  const handleError = (err) => {
    console.log("handleError");

    console.log(err);
    setError(err.message);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();
    // await apiRef.current.stopRowEditMode({ id });
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new teacher:", newRow);
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);
    /* setTeachersData(
      teachersData.map((teacher) => {
        if (teacher.id === newRow.id) return newRow;
        return teacher;
      })
    );*/

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  function handleRegionValue(e) {
    setTeachersData({
      ...teachersData,
      region: {
        id: e.target.value,
        regionName: e.target.options[e.target.selectedIndex].label,
      },
    });
  }
  async function handleOnRowDoubleClick(params, event) {
    if (params.row) {
      setTeacherId(params.row.id);
      handleAddUpdateTeacher(params.row.id);
    }
  }

  /// Add Update Open Teacher Dialog
  const handleOpenDialog = () => {
    setReload(!reload);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveTeacherData = (formData) => {
    // Handle saving the teacher data here, e.g., send it to an API or update the state in your main application
    console.log("Teacher data to save:", formData);
    setReload(!reload);
  };
  async function handleAddUpdateTeacher(teacherId) {
    if (teacherId === 0) {
      setTeacherId(0);
      handleOpenDialog();
    } else if (teacherId > 0) {
      handleOpenDialog();
    }
  }
  const columns = [
    { field: "id", headerName: "ID", width: 100, editable: false },
    {
      field: "teacherName",
      headerName: "Lehrkraft *",
      type: "text",
      editable: true,
      flex: 1,
    },

    {
      field: "street",
      headerName: "Straße",
      type: "text",
      editable: true,
      flex: 1,
    },

    {
      field: "zip",
      headerName: "PLZ",
      width: 70,
      type: "number",
      editable: true,
      valueFormatter: function (params) {
        if (params.value === 0) return "";
        return "" + params.value;
      },
    },

    {
      field: "regionId",
      headerName: "Ort (Region) *",
      type: "singleSelect",
      editable: true,
      flex: 1,

      getOptionValue: (region) => region?.id,
      getOptionLabel: (region) => region?.regionName,
      valueOptions: regionData /* loaded regions from GET API*/,

      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find((region) => region.id === params.value);
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
        // return "";
      },
    },

    {
      field: "branchId",
      headerName: "Abrechnungstelle *",
      type: "singleSelect",
      editable: true,
      flex: 1,

      getOptionValue: (branch) => branch?.id,
      getOptionLabel: (branch) => branch?.branchName,
      valueOptions: branchData /* loaded branches from GET API*/,

      valueFormatter: function (params) {
        if (branchData) {
          let branch = branchData.find((branch) => branch.id === params.value);
          if (typeof branch?.branchName !== "undefined")
            return branch.branchName;
        }
        //return "";
      },
    },

    {
      field: "tel",
      headerName: "Telefon",
      type: "text",
      editable: true,
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("teachersData ", teachersData);

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <h2>Lehrkräfte</h2>
      <br></br>
      {error && error.length > 0 && !error.includes("No Results") && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 200, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      <Box
        sx={{
          width: "100%",
          height: "700px",
          "& .Mui-even": {
            backgroundColor: "#f3f7ff",
          },
          "& .Mui-odd": {
            backgroundColor: "#EEEEEE",
          },
          "& .MuiDataGrid-cell:hover": {
            backgroundColor: "#80b3ff",
          },
          "&& .MuiDataGrid-row:hover": {
            backgroundColor: "#cce0ff",
            /*  "@media (hover: none)": {
              backgroundColor: "transparent",
            },*/
            // color: "red"
          },
        }}
      >
        <TeacherDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleSaveTeacherData}
          relaod={reload}
          TeacherId={TeacherId}
        />
        <DataGrid
          rowSelection={false}
          density="compact"
          rows={teachersData}
          columns={columns}
          editMode="row"
          rowsPerPageOptions={5}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100, page: 0 },
            },
            filter: {
              filterModel: {
                items: [
                  { field: "teacherName", operator: "contains", value: "" },
                ],
              },
            },
          }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onRowDoubleClick={handleOnRowDoubleClick}
          //onCellEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleError}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              setTeachersData,
              setRowModesModel,
              handleAddUpdateTeacher,
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
        />
      </Box>

      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
