import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

const columnsBuilder = (props) => {
  const {
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
  } = props;
  const columns = [
    { field: "id", headerName: "ID", width: 50, editable: false },

    { field: "groupId", headerName: "Group Id", width: 50, editable: false },
    {
      field: "userName",
      headerName: "UserName",
      type: "test",
      editable: true,
      width: 150,
    },

    {
      field: "password",
      headerName: "Passwort",
      width: 150,
      type: "text",
      editable: true,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      width: 100,
      type: "boolean",
      editable: true,
    },
    {
      field: "isActive",
      headerName: "aktiv",
      width: 100,
      type: "boolean",
      editable: true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      width: 250,
      type: "text",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return columns;
};

export default columnsBuilder;
