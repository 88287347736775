import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./GroupSetup.css";
import { getStorageItem, removeFromStorage } from "../../../Common/Utility";
import { Put } from "../../../Common/WebRequest";
import DialogBoxWithYesNo from "../../../components/Dialog/DialogBoxWithYesNo"; // Adjust the path

import DialogBoxWithResult from "../../../components/Dialog/DialogBoxWithResult";
import { useNavigate } from "react-router-dom";

function GroupSetup() {
  const [textGroupName, setTextGroupName] = useState("");
  const [withResetRechNummer, setWithResetRechNummer] = useState(true);
  const [withPdfZip, setWithPdfZip] = useState(true);
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogResultOpen, setDialogResultOpen] = useState(false);
  const [resultText, setResultText] = useState("");
  const [resultStatus, setResultStatus] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setCurrentGroupName(await getStorageItem("login", "systemGroupName"));
    })();
  }, []);

  const handleReset = () => {
    setTextGroupName("");
    setWithPdfZip(true);
    setWithResetRechNummer(true);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  async function handleYesClick() {
    setResultText("");
    setResultStatus("");
    handleDialogClose();
    await handleSave();
  }
  async function handleSave() {
    const isValidGroupName =
      /^[A-Za-z0-9-_]+$/.test(textGroupName) && textGroupName.length >= 4;

    if (isValidGroupName) {
      // Add your logic here for saving the form
      var groupData = {
        currentGroupName: currentGroupName.trim(),
        newGroupName: textGroupName.trim(),
        withResetRechnungsNummer: withResetRechNummer,
        WithPdfZip: withPdfZip,
      };

      var res = await Put("SystemGroupInfo", "", groupData);
      if (res.isSuccess) {
        setResultText(res.displayMessage);
        setResultStatus("success");
      } else {
        console.log(res.Error);
        setResultText(res.Error);
        setResultStatus("error");
      }
      handleDialogResultOpen();
      //await removeFromStorage("login")
    }
  }
  const handleGroupNameChange = (e) => {
    const newValue = e.target.value.replace(/[^A-Za-z0-9-_]/g, "").slice(0, 30);
    setTextGroupName(newValue);
  };

  const handleDialogResultOpen = () => {
    setDialogResultOpen(true);
  };

  const handleDialogResultClose = () => {
    setDialogResultOpen(false);
  };

  return (
    <Card className="group-setup">
      <Typography className="warning-text">
        Bitte stellen Sie sicher, dass Sie die Datenbank gesichert haben, bevor
        Sie eine neue Gruppe erstellen!
      </Typography>

      <Typography variant="body2" className="vertical-space">
        {/* Add vertical spacing here */}
      </Typography>

      <Typography variant="h6" className="group-name">
        {`Aktuelle Gruppenname: ${currentGroupName}`}
      </Typography>

      <Typography variant="body2" className="vertical-space">
        {/* Add vertical spacing here */}
      </Typography>
      <div className="section">
        <Typography variant="h6" className="section-title">
          Datensätze
        </Typography>

        <FormControlLabel
          control={<Checkbox disabled={true} checked={true} />}
          label="Alle Datensätze aus Abrechnungen löschen."
          className="checkbox-label"
        />

        <FormControlLabel
          control={<Checkbox disabled={true} checked={true} />}
          label="Alle Datensätze aus Aufträgen löschen."
          className="checkbox-label"
        />

        <FormControlLabel
          control={
            <Checkbox
              value={true}
              checked={withResetRechNummer}
              onChange={() => setWithResetRechNummer(!withResetRechNummer)}
            />
          }
          label="Setzen Sie alle Rechnungsnummern für alle Branchen auf „0“ zurück."
          className="checkbox-label"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={true}
              checked={withPdfZip}
              onChange={() => setWithPdfZip(!withPdfZip)}
            />
          }
          label="Erstellen Sie PDF.zip für die Rechnungen aller Schüler."
          className="checkbox-label"
        />
      </div>
      <div>
        <Typography variant="body2" className="vertical-space">
          {/* Add vertical spacing here */}
        </Typography>
        <Typography variant="body2" className="group-name">
          Neuer Gruppenname * (4-30 Zeichen):
        </Typography>

        <TextField
          autoFocus
          autoComplete="off"
          value={textGroupName}
          fullWidth
          className="text-field"
          placeholder="Abrechnung-2023-2024"
          onChange={handleGroupNameChange}
        />
      </div>
      <div className="buttons">
        <Button
          variant="outlined"
          className="reset-button"
          onClick={handleReset}
        >
          Zurücksetzen
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="save-button"
          onClick={handleDialogOpen}
          disabled={textGroupName.length < 4}
        >
          Löschen & speichern
        </Button>
      </div>
      <DialogBoxWithYesNo
        open={dialogOpen}
        onClose={handleDialogClose}
        onYes={handleYesClick}
        title="Bestätigung"
        text="Sind Sie sicher, dass Sie fortfahren möchten?"
      />
      <DialogBoxWithResult
        open={dialogResultOpen}
        onClose={handleDialogResultClose}
        title="Result"
        text={resultText}
        result={resultStatus} // or "error"
      />
    </Card>
  );
}

export default GroupSetup;
