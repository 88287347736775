import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

const columnsBuilder = (props) => {
  const {
    regionData,
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
  } = props;
  const columns = [
    { field: "id", headerName: "ID", width: 50, editable: false },

    {
      field: "regionId",
      headerName: "Ort (Region) *",
      type: "singleSelect",
      editable: true,
      width: 150,

      getOptionValue: (region) => region?.id,
      getOptionLabel: (region) => region?.regionName,

      valueOptions: regionData /* loaded regions from GET API*/,

      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find((region) => region.id === params.value);
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
      },
      // renderCell: (params) => (
      //   <div key={params.row.regionId}>
      //     {/* Your singleSelect component JSX */}
      //   </div>
      // ),
    },

    {
      field: "priceName",
      headerName: "Name *",
      width: 150,
      type: "text",
      editable: true,
    },

    {
      field: "priceValue",
      headerName: "Preis pro Einheit (€) *",
      width: 150,
      type: "number",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("pricesData ", pricesData);

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return columns;
};

export default columnsBuilder;
