import React, { useEffect, useState } from "react";
import {
  FaUserGraduate,
  FaCalendarAlt,
  FaChalkboardTeacher,
  FaSync,
  FaFileInvoiceDollar,
  FaFileAlt,
} from "react-icons/fa";

import "./TopHeaderPage.css";
import { Get } from "../../Common/WebRequest";
import { useNavigate } from "react-router-dom";
import { clearCacheData } from "../../Common/Utility";

const DashboardCard = ({ value, label, icon, onClick }) => {
  const cardWidth =
    label === "Kürzlich erstellt" ||
    label.includes("Mon") ||
    label.includes("berechnet")
      ? "200px"
      : "150px";

  const iconColor =
    label === "Studenten"
      ? "orange"
      : label === "Aktualisieren"
      ? "#00bfa0"
      : label === "Lehrkräfte"
      ? "red"
      : label.includes("Mon")
      ? "#006400"
      : label === "Kürzlich erstellt"
      ? "#0d88e6"
      : label.includes("berechnet")
      ? "#5ad45a"
      : "black";

  return (
    <div className="card" style={{ width: cardWidth }} onClick={onClick}>
      <div className="icon-container" style={{ color: iconColor }}>
        {icon}
      </div>
      <div className="value">{value}</div>
      <div className="label">{label}</div>
    </div>
  );
};

const TopHeaderPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [topSummary, setTopSummary] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    (async () => {
      try {
        setError("");
        const res = await Get("Statistics/Summary");

        if (res.isSuccess) {
          const data = res.result;

          setTopSummary(data);
        } else {
          setError(res.Error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!topSummary || topSummary.length === 0) {
    if (error && error.length > 0) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {error}
        </div>
      );
    }
    return <div>No data available</div>;
  }

  function handleRefresh(e) {
    clearCacheData();
    window.location.reload(true);
    //navigate(0);
  }

  return (
    <div className="dashboard-container">
      <DashboardCard
        value={topSummary.today}
        label="Aktualisieren"
        icon={<FaSync />}
        onClick={(e) => handleRefresh(e)}
      />
      <DashboardCard
        value={topSummary.studentCount}
        label="Schüler/in (en)"
        icon={<FaUserGraduate />}
        onClick={(e) => navigate("/students")}
      />
      <DashboardCard
        value={topSummary.teacherCount}
        label="Lehrkräfte"
        icon={<FaChalkboardTeacher />}
        onClick={(e) => navigate("/teachers")}
      />
      <DashboardCard
        value={topSummary.recentlyCreated}
        label="Kürzlich erstellt"
        icon={<FaCalendarAlt />}
      />
      <DashboardCard
        value={topSummary.amountForLastMonth}
        label={topSummary.lastMonth}
        icon={<FaFileInvoiceDollar />}
      />

      <DashboardCard
        value={
          topSummary.lastCalculated.includes("1970")
            ? "-"
            : topSummary.lastCalculated
        }
        label="Zuletzt berechnet"
        icon={<FaFileAlt />}
      />
    </div>
  );
};

export default TopHeaderPage;
