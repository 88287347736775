import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import userManual from "./pdfs/AbrechnungSystem-UserManual.pdf";
//import technicalManual from "./pdfs/AbrechnungSystem-TechnicalManual.pdf";
import userManualImage from "../img/usermanual.jpeg";
import technicalManualImage from "../img/technicalmanual.jpeg";
import "./HelpDocuments.css";
import { useEffect } from "react";
import { getStorageItem } from "../../Common/Utility";
import { useState } from "react";

const HelpDocuments = () => {
  const [loginData, setLoginData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoginData(JSON.parse(localStorage.getItem("login")));
      if (!loginData || loginData.token === "") {
        console.log("invalid token");
        navigate("/login");
        return null;
      }
    })();
  }, []);

  const pageTitle = "Dokumentation";

  const redIconStyle = { color: "red" };

  return (
    <div>
      <br />
      <h4>{pageTitle}</h4>
      <br />
      <br />

      <table style={{ borderCollapse: "separate", borderSpacing: "50px" }}>
        <tbody>
          <tr>
            <td
              style={{
                border: "none",
                textAlign: "center",
                background: "white",
              }}
            >
              <a href={userManual} target="_blank" rel="noopener noreferrer">
                <div className="image-container">
                  {" "}
                  {/* Apply the CSS class here */}
                  <img
                    src={userManualImage}
                    alt="User Manual"
                    width="200"
                    height="250"
                  />
                </div>
              </a>
            </td>
            <td
              style={{
                border: "none",
                textAlign: "center",
                background: "white",
              }}
            >
              <a href="not-found" target="_blank" rel="noopener noreferrer">
                <div className="image-container">
                  {" "}
                  {/* Apply the CSS class here */}
                  <img
                    src={technicalManualImage}
                    alt="Technical Manual"
                    width="200"
                    height="250"
                  />
                </div>
              </a>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "none",
                textAlign: "center",
                verticalAlign: "top",
                background: "white",
              }}
            >
              <a href={userManual} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFilePdf} style={redIconStyle} />
                Benutzerhandbuch (PDF)
              </a>
            </td>
            <td
              style={{
                border: "none",
                textAlign: "center",
                verticalAlign: "top",
              }}
            >
              <a href="not-found" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFilePdf} style={redIconStyle} />
                Technisches Handbuch (PDF)
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HelpDocuments;
