function getPCName() {
  return window.navigator && window.navigator.userAgent;
}
async function getIPAddress() {
  try {
    const response = await fetch("https://api.ipify.org?format=text");
    const ipAddress = await response.text();
    return ipAddress;
  } catch (error) {
    console.error("Error:", error);
    return "x.x.x.x";
  }
}
async function getCountryName() {
  try {
    const response = await fetch("https://ipapi.co/json");
    const data = await response.json();
    return data.country_name + "/" + data.city;
  } catch (error) {
    console.error("Error:", error);
    return "Unknown";
  }
}
function getLocalDateTime() {
  const now = new Date();
  const localDateTime = now.toLocaleString(); // Adjust the formatting as needed
  return localDateTime;
}

function getOperatingSystem() {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf("Win") !== -1) {
    return "Windows";
  } else if (userAgent.indexOf("Mac") !== -1) {
    return "MacOS";
  } else if (userAgent.indexOf("Linux") !== -1) {
    return "Linux";
  } else if (userAgent.indexOf("Android") !== -1) {
    return "Android";
  } else if (userAgent.indexOf("iOS") !== -1) {
    return "iOS";
  } else {
    return "Unknown OS";
  }
}

function getBrowser() {
  const userAgent = window.navigator && window.navigator.userAgent;

  if (userAgent) {
    console.log(userAgent);
    if (userAgent.indexOf("Firefox") !== -1) {
      return "Mozilla Firefox";
    } else if (
      userAgent.indexOf("Edg") !== -1 ||
      userAgent.indexOf("Edge") !== -1
    ) {
      return "Microsoft Edge";
    } else if (userAgent.indexOf("Brave") !== -1) {
      return "Brave Browser";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      return "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      return "Apple Safari";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      return "Internet Explorer";
    }
  }

  return "Unknown Browser";
}

function getDeviceType() {
  const userAgent = navigator.userAgent;

  if (/Mobi/i.test(userAgent)) {
    return "Mobile";
  } else if (/Tablet/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
}
function getScreenResolution() {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  return `${screenWidth}x${screenHeight}`;
}

function getViewportDimensions() {
  const viewportWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const viewportHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return `${viewportWidth}x${viewportHeight}`;
}
function getUserLanguage() {
  return navigator.language || navigator.userLanguage;
}

function getUserDateTimeFormat() {
  const options = { dateStyle: "full", timeStyle: "full" };
  return new Intl.DateTimeFormat(navigator.language, options).resolvedOptions()
    .locale;
}
function getUserDateFormat() {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Intl.DateTimeFormat(navigator.language, options)
    .formatToParts(new Date())
    .map((part) =>
      part.type === "day"
        ? "dd"
        : part.type === "month"
        ? "mm"
        : part.type === "year"
        ? "yyyy"
        : part.value
    )
    .join("");
}
export {
  getPCName,
  getBrowser,
  getOperatingSystem,
  getIPAddress,
  getLocalDateTime,
  getCountryName,
  getDeviceType,
  getScreenResolution,
  getViewportDimensions,
  getUserLanguage,
  getUserDateTimeFormat,
  getUserDateFormat,
};
