import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";   
import Sidebar from "./SideBarMenu" 
import { getStorageItem } from "../../Common/Utility";

const NavSideBar = (props) => {  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
  const isNonMobile = useMediaQuery("(min-width: 620px)");
  const loginData = JSON.parse(localStorage.getItem("login"));
     
  useEffect(() => {
    (async () => {
      let userName = await getStorageItem("login", "userName");     
      if (
        !loginData ||
        loginData.token === "" ||
        !userName ||
        userName === ""
      ) {        
        console.log("invalid token");  
          
        return null;
      }
      
    })();
  }, []);

   
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%" color="red">
      <Sidebar  
   
        isNonMobile={isNonMobile}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        drawerWidth={props.width}  
             
      />
   
    </Box>
  );
};

export default NavSideBar;
