import { isDate, replaceIf, showDateOnly } from "../../Common/Utility";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
function columnBuilder() {
  const columns = [
    {
      field: "studentName",
      headerName: "Schüler/in",
      width: 290,
      type: "text",
      editable: false,
      // flex: 1,
    },
    {
      field: "regionName",
      headerName: "Region",
      width: 200,
      type: "text",
      editable: false,
      valueFormatter: function (params) {
        if (params.value === "0") return "";
        return params.value;
      },
    },
    {
      field: "branchName",
      headerName: "Abrechnungstelle",
      width: 230,
      type: "text",
      editable: false,
      valueFormatter: function (params) {
        if (params.value === "0") return "";
        return params.value;
      },
    },

    {
      field: "pdfDate",
      headerName: "PDF-Datum",
      width: 150,

      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return showDateOnly(params.value);
        }
      },
    },

    {
      field: "period",
      headerName: "Zeitraum",
      type: "text",
      editable: false,
      width: 200,

      valueFormatter: function (params) {
        let period = params.value;
        return replaceIf(period, "-", "--");
      },
    },
    {
      field: "pdfFileName",
      headerName: "PDF-Datei",
      type: "text",
      editable: false,
      width: 370,
      renderCell: (cellValues) => {
        if (cellValues.value === "") return null;
        return (
          <div>
            <PdfIcon variant="contained" color="error" />
            &nbsp; &nbsp;
            <a href="##">{cellValues.value}</a>
          </div>
        );
      },
    },
  ];
  return columns;
}
export default columnBuilder;
