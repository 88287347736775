import TabContainer from "./TabContainer";
import "./ControlPanel.css";
import UserManagement from "../../Pages/ControlPanel/UserManagement/UserManagement.js";
import Database from "./Database/Database";
import GroupSetup from "./Group/GroupSetup";
const tabs = [
  { label: "Users Management", content: <UserManagement /> },
  // { label: "Database", content: <Database /> },
  { label: "Group Setup*", content: <GroupSetup /> },
];

function ControlPanel() {
  return (
    <div className="control-panel">
      <div>
        <h1>Control Panel</h1>
        <TabContainer tabs={tabs} />
      </div>
    </div>
  );
}

export default ControlPanel;
