import { createSlice } from "@reduxjs/toolkit";

const regionSlice = createSlice({
  initialState: "",
  name: "regionSlice",
  reducers: {
    selectRegion: (state, action) => {
      return action.payload;
    },
  },
});

export const { selectRegion } = regionSlice.actions;
export default regionSlice.reducer;
