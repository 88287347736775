import { RemoveComma, showDateOnly } from "../../Common/Utility";
//test
const columnsBuilder = () => {
  // const {
  //   rowModesModel,
  //   handleEditClick,
  //   handleCancelClick,
  //   handleSaveClick,
  //   handleDeleteClick,
  // } = props;
  const columns = [
    /*{ field: "id", headerName: "ID", width: 50, editable: false },
     */
    {
      field: "id",
      headerName: "ID",
      width: 70,
      type: "number",
      editable: false,
      valueFormatter: function (params) {
        return RemoveComma(params.value);
      },
    },

    {
      field: "logType",
      headerName: "Type",
      width: 70,
      type: "string",
      editable: false,
    },

    {
      field: "status",
      headerName: "Status",
      width: 80,
      type: "text",
      editable: false,
      valueFormatter: function (params) {
        return RemoveComma(params.value);
      },
    },

    {
      field: "statusText",
      headerName: "S.Text",
      width: 110,
      type: "text",
      editable: false,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      type: "text",
      editable: false,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      type: "text",
      editable: false,
    },
    {
      field: "guid",
      headerName: "GUID",
      width: 150,
      type: "text",
      editable: false,
    },
    {
      field: "userDate",
      headerName: "Datum",
      width: 160,
      type: "datetime",
      editable: false,
      /*valueFormatter: function (params) {
        return showDateOnly(params.value);
      },*/
    },
  ];

  return columns;
};

export default columnsBuilder;
