import React from "react";
import { Button, Container, Typography } from "@mui/material";
import "./NotFoundPage.css";

const NotFoundPdf = () => {
  const handleCloseTab = () => {
    window.close(); // Close the tab
  };

  return (
    <Container className="not-found-container">
      <Typography variant="h1" className="not-found-heading">
        Pdf File Not Found
      </Typography>
      <br />
      <br />
      <Typography variant="body1" className="not-found-subheading">
        The PDF file you are looking for does not exist/ or may be located on
        (Next-Cloud).
      </Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={handleCloseTab}
        className="not-found-button"
      >
        Close Tab
      </Button>
    </Container>
  );
};

export default NotFoundPdf;
