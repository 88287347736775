import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./DialogStyle.css";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import { getToDay, isDate } from "../../Common/Utility";

const DialogPdfConfirmDate = ({ onChoice, open, handleClose }) => {
  const [selectedDate, setSelectedDate] = useState("");

  if (!isDate(selectedDate)) setSelectedDate(getToDay());

  const handleYesClick = () => {
    onChoice({ date: selectedDate, choice: "OK" });
    handleClose();
  };

  const handleNoClick = () => {
    onChoice({ date: null, choice: "Abbrechen" });
    setSelectedDate(null);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        className="dialog"
      >
        <DialogTitle>PDF-Datum bestätigen</DialogTitle>
        <DialogContent>
          <DialogContentText className="dialogContentText">
            <br />
            Bitte bestätigen Sie das Datum, um die PDF-Datei(en) zu erstellen.
            <br />
          </DialogContentText>
          <br />
          <div className="datePickerContainer">
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="datePicker"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleNoClick}
            startIcon={<UndoIcon />}
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            onClick={handleYesClick}
            className="confirmButton"
            disabled={!selectedDate}
            endIcon={<DoneIcon />}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogPdfConfirmDate;
