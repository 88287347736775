import {
  containsNumberAndLetter,
  isDate,
  showDateOnly,
} from "../../Common/Utility";
import clsx from "clsx";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import { blue, red } from "@mui/material/colors";
import { Checkbox } from "@mui/material";
const columnsBuilder = (props) => {
  const {
    //   rowModesModel,
    //   handleEditClick,
    //   handleCancelClick,
    //   handleSaveClick,
    regionData,
  } = props;

  const columns = [
    {
      field: "studentId",
      headerName: "ID",
      width: 20,
      editable: false,
    },

    {
      field: "branchId",
      headerName: "Abrechnungstelle ID",
      width: 20,
      editable: false,
    },

    {
      field: "isPayed",
      headerName: "Bezahlt €",
      width: 120,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.value}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },

      disabled: false,
    },
    { field: "studentName", headerName: "Auftrag", width: 300 },

    {
      field: "regionId",
      headerName: "Ort (Region)",
      type: "singleSelect",
      editable: false,
      width: 120,

      getOptionValue: (region) => region?.id,
      getOptionLabel: (region) => region?.regionName,
      valueOptions: regionData /* loaded regions from GET API*/,

      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find((region) => region.id === params.value);
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
        // return "";
      },
    },
    {
      field: "period",
      headerName: "Zeitraum",
      width: 170,

      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "DodgerBlue",
              fontSize: 12,
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "sumOfAmount",
      headerName: "Betrag (€)",
      type: "number",
      width: 100,
      headerClassName: "color--green--header",
      cellClassName: (params) =>
        clsx("super-app", {
          negative: params.value < 0,
          positive: params.value > 0,
        }),
    },
    {
      field: "pdfDate",
      headerName: "PDF-Datum",
      width: 120,

      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return showDateOnly(params.value);
        }
      },
    },
    {
      field: "birthDate",
      headerName: "Geburstdatum",
      width: 150,
      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return showDateOnly(params.value);
        }
      },
    },
    { field: "butNumber", headerName: "But-Number", width: 160 },
    {
      field: "rechnungsnummer",
      headerName: "Rechnungsnummer",
      width: 150,
      headerClassName: "color--red--header",
      cellClassName: (params) =>
        clsx("super-app", {
          notEmpty: params.value !== "",
        }),
      valueFormatter: function (params) {
        if (containsNumberAndLetter(params.value)) {
          return params.value;
        }
        return "";
      },
    },
    {
      field: "pdfFileName",
      headerName: "PDF-Datei",
      width: 350,
      //renderCell: (params) => <a href="##">{params.value}</a>,
      // see StudentCalculationTable::handleOnCellClick
      renderCell: (cellValues) => {
        return (
          <div>
            <PdfIcon variant="contained" color="error" />
            &nbsp; &nbsp;
            <a href="##">{cellValues.value}</a>
          </div>
        );
      },
    },
    {
      field: "entryDateFrom",
      headerName: "Entry-From",
      width: 100,
      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return showDateOnly(params.value);
        }
      },
    },
    {
      field: "entryDateTo",
      headerName: "Entry-To",

      width: 100,
      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return showDateOnly(params.value);
        }
      },
    },
  ];
  return columns;
};
export default columnsBuilder;
