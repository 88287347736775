// import csv--> student template
import { apiUrl } from "../config.js";
async function WebRequestCsv(props) {
  let fullPath = props.path;

  let url = apiUrl + "/" + fullPath;
  let fileName = props.fileName;
  const loginData = JSON.parse(localStorage.getItem("login"));
  const token = loginData?.token;

  fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;

      // Programmatically trigger the download
      downloadLink.click();
    })
    .catch((error) => {
      console.error("Error downloading the CSV-File.", error);
    });

  //------------
}
async function GetCSV(path, fileName) {
  let props = { path: path, fileName: fileName, method: "GET" };
  return await WebRequestCsv(props);
}

export { GetCSV };
