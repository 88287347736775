import React, { useEffect } from "react";
import { Container, Typography, Paper, Button } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { Link, useNavigate } from "react-router-dom";

const EmailSent = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");

  useEffect(() => {
    // Redirect to NotFoundPage if email is not provided or empty
    if (!email || email.trim() === "") {
      navigate("/notFound");
    }
  }, [email, navigate]);

  if (!email || email.trim() === "") {
    return null; // To avoid rendering anything in this case
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{
          padding: "2em",
          marginTop: "8vh",
          textAlign: "center",
          borderRadius: "12px",
        }}
      >
        <Typography variant="h5" gutterBottom color="success">
          Email wurde erfolgreich Versendet
        </Typography>
        <br />
        <CheckCircleOutline
          fontSize="large"
          style={{
            verticalAlign: "middle",
            marginRight: "8px",
            color: "green",
          }}
        />
        <br />
        <br />
        <Typography paragraph>
          an: <strong>{email}</strong>.
        </Typography>
        <Typography paragraph style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
          Bitte überprüfen Sie Ihre E-Mail.
        </Typography>
        <Typography paragraph style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
          Bitte beachten Sie, dass der in 5 Minuten deaktiviert wird
        </Typography>
        <br />
        <br />
        <Typography
          paragraph
          style={{ fontStyle: "italic", color: "darkgray" }}
        >
          * Wenn E-Mail nicht im Posteingang gefunden wird, überprüfen Sie den{" "}
          <strong style={{ fontWeight: "bold", color: "black" }}>
            Spam-Ordner.
          </strong>
        </Typography>
        <br />
        <br />
        {/* <Button
          component={Link}
          to="/"
          variant="contained"
          color="success"
          sx={{
            "&:hover": {
              color: "#fff",
              backgroundColor: "#4caf50",
            },
          }}
        >
          Zurück zur Anmeldung
        </Button> */}
      </Paper>
    </Container>
  );
};

export default EmailSent;
