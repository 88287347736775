const columnsBuilderDetails = () => {
  const columns = [
    {
      field: "courseName",
      headerName: "Fach",
      width: 150,
      type: "text",
      editable: false,
    },
    {
      field: "priceName",
      headerName: "(E/G)",
      width: 150,
      type: "text",
      editable: false,
    },

    {
      field: "priceValue",
      headerName: "Preis pro Einheit",
      width: 150,
      type: "number",
      editable: false,
    },
    {
      field: "hours",
      headerName: "Stunde",
      width: 150,
      type: "number",
      editable: false,
    },
    {
      field: "amount",
      headerName: "Betrag (€)",
      width: 150,
      type: "number",
      editable: false,
    },

    {
      field: "period",
      headerName: "Zeitraum",
      width: 170,
      type: "text",
      editable: false,
    },
  ];

  return columns;
};

export default columnsBuilderDetails;
