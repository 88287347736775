import { apiUrl } from "../config.js";
import { downloadFile, getBaseUrl } from "./Utility.js";

async function WebRequestPdfViewer(props, isPopUp, isDownload) {
  try {
    let fullPath = props.path;

    const url = apiUrl + "/" + fullPath;
    const fileName = fullPath.slice(1 + fullPath.lastIndexOf("/"));
    const loginData = JSON.parse(localStorage.getItem("login"));
    const token = loginData?.token;

    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      var responseData = await response.json(); // Assuming the response contains JSON data
      if (responseData.result === null) {
        // no pdf path return in case old data : next Cloud
        window.open("/NotFoundPdf", "_blank");
        return;
      }
      var fullPdfpath = getBaseUrl(apiUrl) + "/" + responseData.result;
      if (!isPopUp) {
        window.open(fullPdfpath, "_blank");
      } /*else if (isDownload) {
        let path = apiUrl + "/Calculation/test/test.pdf";
        console.log("path: ", path);
        await downloadFile(path);
      
      }*/ else {
        openInPopup(fullPdfpath, 600, 400);
      }
    } else if (response.status === 401) {
      window.location.href = "/LoginPage";
      console.log("Error: ", response.statusText);
    } else {
      console.log("Error: ", response.statusText);
    }
  } catch (error) {
    console.error("Error loading the PDF:", error);
  }
}

function openInPopup(url, width, height) {
  const spacingFromTop = 50; // Adjust this value as needed
  const spacingFromRight = -250; // Adjust this value as needed

  const top = spacingFromTop;
  const left = window.innerWidth - width - spacingFromRight;

  const features = `width=${width},height=${height},left=${left},top=${top},resizable,scrollbars=yes,toolbar=no,location=no`;

  window.open(url, "Popup", features);
}

export { WebRequestPdfViewer };
