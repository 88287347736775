import { apiUrl } from "../config.js";
import { removeFromStorage } from "./Utility.js";
async function WebRequestPdf(props) {
  let fullPath = props.path;
  var fullPdfpath = apiUrl + "/" + fullPath;
  let url = fullPdfpath;

  let fileName = fullPath.slice(1 + fullPath.lastIndexOf("/"));

  const loginData = JSON.parse(localStorage.getItem("login"));
  const token = loginData?.token;

  let headers = {
    Accept: "application/json, text/plain, */*",
  };

  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }

  if (props.contentType) {
    headers["Content-Type"] = props.contentType;
  }

  const fetchOptions = {
    method: props.method || "GET",
    headers: headers,
  };

  if (props.method && props.method.toUpperCase() !== "GET" && props.body) {
    fetchOptions.body = JSON.stringify(props.body);
  }

  try {
    const response = await fetch(url, fetchOptions);
    if (!response.ok) {
      if (response.status === 401) {
        removeFromStorage("login"); // Assuming removeFromStorage is a function you've defined elsewhere
        window.location.href = "/login";
        return null;
      }
      throw new Error(
        `Download Error==> File ${fileName}, Status: ${response.status}`
      );
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download error:", error);

    throw new Error(error);
  }
}
// downloadFile('path/to/file.ext', { method: 'GET', contentType: 'application/octet-stream' });

async function DownloadPdf(path) {
  try {
    let props = {
      path: path,
      method: "GET",
      contentType: "application/octet-stream",
    };
    let filePath = await WebRequestPdf(props);
    return filePath;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export { DownloadPdf };
