import { HomeOutlined } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonIcon from '@mui/icons-material/Person'; 
import GridViewIcon from '@mui/icons-material/GridView';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SystemUpdate from '@mui/icons-material/SystemUpdateAlt';


 export const navigationItems = [
    {
      text: "Home",
      icon: <HomeOutlined />,
      link: "Home",
    },
    {
      text: "Stammdaten",
      icon: null,
      link: "",
    },
    {
      text: "Regionen",
      icon: <PlaceIcon />,
      link: "Regions",
    },
    {
      text: "Abrechnungstelle",
      icon: <AssuredWorkloadOutlinedIcon />,
      link: "Branches",
    },
    {
      text: "Firmendaten",
      icon: <BusinessOutlinedIcon />,
      link: "Companies",
    },
    {
      text: "Nachhilfefächer",
      icon: <AutoStoriesOutlinedIcon />,
      link: "Courses",
    },
    {
      text: "Lehrkräfte",
      icon: <CastForEducationOutlinedIcon />,
      link: "Teachers",
    },
    {
      text: "Schüler/in",
      icon: <Diversity3OutlinedIcon />,
      link: "Students",
    },
    {
      text: "Preis pro Einheit",
      icon: <EuroOutlinedIcon />,
      link: "Prices",
    },
    {
      text: "Rechnungsnummer",
      icon: <PinOutlinedIcon />,
      link: "Invoicenumbers",
    },
  
    {
      text: "Aufträge & Abrechnung",
      icon: null,
      link: "",
    },
    {
      text: "Aufträge",
      icon: <ReceiptOutlinedIcon />,
      link: "Trackerstudents",
    },
  
    {
      text: "Abrechnung",
      icon: <TableViewOutlinedIcon />,
      link: "Calculations",
    },
    {
      text: "Abrechnungsübersicht",
      icon: <PlagiarismOutlinedIcon />,
      link: "CalculationsView",
    },
    {
      text: "PDF-Dateien",
      icon: <PictureAsPdfOutlinedIcon />,
      link: "PdfFilesHistory",
    },   
    {
      text: "Others",
      icon: null,
      link: "",
    }, 
    {
      text: "Control Pannel",
      icon: <GridViewIcon />,
      link: "ControlPannel",
    }, 
    // {
    //   text: "Users Management",
    //   icon: <ManageAccountsIcon />,
    //   link: "UserManagement",
    // },
    {
      text: "Audit Logs",
      icon: <ScatterPlotIcon/>,
      link: "AuditLogs",
    },    
    {
      text: "Users Profile",
      icon: <PersonIcon/>,
      link: "UserProfile",
    },
    {
      text: "System Updates",
      icon: <SystemUpdate/>,
      link: "SystemUpdates",
    },

    {
      text: "System Logs",
      icon: <ErrorOutlineIcon />,
      link: "SystemLogs",
    },
  /*  {
      text: "Help",
      icon: <HelpIcon />,
      link: "Help",
    },*/

    
  ];

  export function filterNavigationItems(isAdmin) {
    if(typeof (isAdmin) === 'undefined')
    return [];
    var filteredItems= navigationItems.filter(
      (item) =>
         !(item.link  === "UserManagement" && !isAdmin) &&
         !(item.link  === "ControlPannel" && !isAdmin) &&
         !(item.link === "UserProfile" && isAdmin)          
    );

    return filteredItems;
  }
  