import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <div className="footer">
      <p>
        Total Stunden= <strong>{props.TotalHours}</strong>, Total berechnet={" "}
        <strong>{props.TotalCalculatedHours}</strong> , Rest={" "}
        <strong> {props.TotalHours - props.TotalCalculatedHours}</strong>
      </p>
    </div>
  );
}

export default Footer;
