import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import RefreshIcon from "@mui/icons-material/CachedOutlined";
import CheckIcon from "@mui/icons-material/OfflinePinOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import Export from "@mui/icons-material/SystemUpdateAlt";
import TocIcon from "@mui/icons-material/Toc";
import CopyIcon from "@mui/icons-material/ContentCopy";
import {
  formatDate,
  getString,
  getValue,
  isDate,
  setStorageItem,
  showDateOnlyFormatted,
} from "../../../Common/Utility";
import Footer from "./Footer";
import "./EditableDataTable.css";
import "./OptionSelect.css";
import "./Button.css";

import React, { useState, useRef, useEffect } from "react";
import {
  getFormattedDate,
  getNextEndDate,
  getWeeks,
  increaseDateByOneDay,
  isDate2LessDate1,
  isValidDate,
  toJsonDate,
  toJsonDateTimeHandle,
} from "./ValidationAutoComplete";
//import { Key } from "@mui/icons-material";
import { Delete, Get, Post, Put } from "../../../Common/WebRequest";
import { Label } from "reactstrap";
import LegendDataTable from "./LegendDataTable";
import ExportTableCSV from "../../../Common/ExportTableCSV";
import {
  ToExcelByRows,
  copyToClipboard,
} from "../../../Common/ExportTableToXls";
import CalculationDetailsDialog from "./CalculationDetailsDialog";
import { Box } from "@mui/material";
import StudentDialog from "../../Students/StudentDialog";

function EditableDataTable(props) {
  let regionId = props.RegionId,
    branchId = props.BranchId /*2*/,
    studentId = props.StudentId /*26*/,
    courseId = props.CourseId; /*3 */
  let queryId =
    branchId.toString() +
    "/" +
    studentId.toString() +
    "/" +
    courseId.toString();
  let IsEscPressed = false;

  const [coursesData, setCoursesData] = useState([]);
  const [coursesDataOriginal, setCoursesDataOriginal] = useState([]);
  const [coursesDataToDelete, setCoursesDataToDelete] = useState([]);
  const [reload, setReload] = useState(false);

  const [teacherData, setTeacherData] = useState([]);
  const [priceData, setPriceData] = useState([]);

  const inputRefs = useRef([]);
  const tableRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [lastValue, setLastValue] = useState("");
  const [lastIdValue, setLastIdValue] = useState("");

  const [totalCalculatedHours, setTotalCalculatedHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [statusWait, setStatusWait] = useState("");
  const headers = ["Von", "Zum", "Anzahl(Std)", "W/S", "Lehrkraft", "E/G (€)"];
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [lastUpdatedUserDate, setLastUpdatedUserDate] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [isHidden, setIsHidden] = useState(true);

  // for teachers & prices

  useEffect(() => {
    (async () => {
      if (getValue(branchId) === 0) {
        return;
      }

      let teachers = await Get("teacher/Branch/" + branchId);
      let prices = await Get("price/Branch/" + branchId);
      if (teachers.isSuccess) {
        setTeacherData(teachers.result);
      }
      if (prices.isSuccess) {
        setPriceData(prices.result);
      }

      // console.log();
    })();
  }, [branchId]); // comment

  useEffect(() => {
    (async () => {
      setStatusWait("");
      setTotalCalculatedHours(0);
      setTotalHours(0);
      if (
        getValue(branchId) === 0 ||
        getValue(studentId) === 0 ||
        getValue(courseId) === 0
      ) {
        setCoursesData([]);
        return;
      }
      let res = await Get("TrackerStudent/Order/" + queryId);
      //let teachers = await Get("teacher/Branch/" + branchId);
      //let prices = await Get("price/Branch/" + branchId);

      if (res.isSuccess) {
        setCoursesData(() => {
          return res.result.studentCoursesTrackDtos;
        });

        // setIsHidden(res.result.studentCoursesTrackDtos.length === 0);
        props.handleResultCount(res.result.studentCoursesTrackDtos.length);
        setCoursesDataOriginal(res.result.studentCoursesTrackDtos);
        maintainDate(res.result.studentCoursesTrackDtos);
        //props.handleSaved(false);

        // if (!props.CourseSaved)
        if (props.IsFirstTime) {
          handleAddNewRecord(res.result.studentCoursesTrackDtos);
          props.handleIsFirstTime(false);
        }

        /*else {
          console.log("not first");
        }*/
        setTotalCalculatedHours(res.result.totalCalculatedHours);
        setTotalHours(res.result.totalHours);
      } else {
        setCoursesData([]);
        handleAddNewRecord();
        props.handleResultCount(coursesData.length + 1);
        setTotalCalculatedHours(0);
        setTotalHours(0);
      }
      //  setPriceData(prices.result);
      // setTeacherData(teachers.result);

      // console.log();
    })();
  }, [reload, branchId, studentId, courseId, queryId]); // comment

  /*useEffect(() => {
    (async () => {
      if (props.Disabled) RemoveAllEmptyItems();
    })();
  }, [props.Disabled]); // comment
*/
  function maintainDate(result) {
    if (result) {
      result.forEach((record) => {
        record.dateFrom = showDateOnlyFormatted(record.dateFrom);
        record.dateTo = showDateOnlyFormatted(record.dateTo);
      });
      //setCoursesData(result);
    }
  }

  const handleKeyDownSelect = (event, rowIndex, colIndex) => {
    if (
      (event.key === "Tab" ||
        event.key === "Enter" ||
        event.key === "ArrowRight") &&
      event.target.name === "priceId"
    ) {
      //event.preventDefault();
      if (!isEmptyRow(rowIndex)) {
        event.preventDefault();
        handleAddNewRecord(coursesData);
      } else {
        event.preventDefault();
        const rowsCount = tableRef.current.rows.length;
        if (rowsCount - 1 === rowIndex) {
          inputRefs.current[rowIndex][colIndex]?.focus();
          inputRefs.current[rowIndex][colIndex]?.select();
        }
        if (typeof inputRefs.current[rowIndex + 1][0] === "undefined") return;
        inputRefs.current[rowIndex + 1][0]?.focus();

        inputRefs.current[rowIndex + 1][0]?.select();
      }
    }
    //if (event.target.name === "priceId") return;
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      inputRefs.current[rowIndex][colIndex - 1]?.focus();
      let component = inputRefs.current[rowIndex][colIndex - 1];
      if (!component?.innerHTML.includes("option"))
        inputRefs.current[rowIndex][colIndex - 1]?.select();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      inputRefs.current[rowIndex][colIndex + 1]?.focus();
      let component = inputRefs.current[rowIndex][colIndex + 1];
      if (!component?.innerHTML.includes("option"))
        inputRefs.current[rowIndex][colIndex + 1]?.select();
    } else if (event.key === "Enter") {
      event.preventDefault();
      inputRefs.current[rowIndex][colIndex + 1]?.focus();
      let component = inputRefs.current[rowIndex][colIndex + 1];
      if (!component?.innerHTML.includes("option"))
        inputRefs.current[rowIndex][colIndex + 1]?.select();
    }
  };

  const handleKeyDown = (event, rowIndex, colIndex, id) => {
    const { key, target } = event;
    if (IsEscPressed) {
      target.select();
      IsEscPressed = false;
    }
    var chars = '!"§$%&/()=?``.,°^+-*=@#<>|{[]}';
    var charsDate = '!"§$%&()=?``,°^+-*=@#<>|{[]}';

    target.style.caretColor = "";
    if (
      event.target.name === "numberHours" ||
      event.target.name === "numberHoursWeekly" ||
      event.target.name === "dateFrom" ||
      event.target.name === "dateTo"
    ) {
      if (
        chars.includes(key) &&
        (event.target.name === "numberHours" ||
          event.target.name === "numberHoursWeekly")
      ) {
        //else
        event.preventDefault();
        return;
      } else if (
        charsDate.includes(key) &&
        (event.target.name === "dateFrom" || event.target.name === "dateTo")
      ) {
        //else
        event.preventDefault();
        return;
      } else if (
        event.keyCode === 46 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        (event.keyCode === 17) | (event.keyCode === 67) ||
        (event.keyCode === 17) | (event.keyCode === 86) ||
        (event.keyCode === 17) | (event.keyCode === 88) ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 13 ||
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode === 37 ||
        event.keyCode === 38 ||
        event.keyCode === 39 ||
        event.keyCode === 40 ||
        ((event.keyCode === 111 || event.keyCode === 190) &&
          (event.target.name === "dateFrom" || event.target.name === "dateTo"))
      ) {
      } else {
        event.preventDefault();
      }
    }
    switch (key) {
      case "ArrowUp":
        if (rowIndex > 1) {
          inputRefs.current[rowIndex - 1][colIndex]?.focus();
          inputRefs.current[rowIndex - 1][colIndex]?.select();
          IsEscPressed = false;
        }
        break;
      case "ArrowDown":
        if (!isEmptyRow(rowIndex)) {
          handleAddNewRecord(coursesData);
          event.preventDefault();
        } else if (rowIndex < coursesData.length) {
          inputRefs.current[rowIndex + 1][colIndex]?.focus();
          inputRefs.current[rowIndex + 1][colIndex]?.select();
          IsEscPressed = false;
        }
        break;
      case "ArrowLeft":
        if (target.selectionStart === 0 && target.selectionEnd === 0) {
          if (colIndex > 0) {
            inputRefs.current[rowIndex][colIndex - 1]?.focus();
            let component = inputRefs.current[rowIndex][colIndex - 1];
            if (!component?.innerHTML.includes("option"))
              inputRefs.current[rowIndex][colIndex - 1]?.select();
            IsEscPressed = false;
          } else {
            tableRef.current.rows[rowIndex].cells[0].focus();
            console.log("target.selectionEnd ==> ", target.selectionEnd);
            setSelectedRow(rowIndex);
          }
        }

        break;
      case "ArrowRight":
        if (
          IsEscPressed ||
          (target.selectionStart === target.value.length &&
            target.selectionEnd === target.value.length)
        ) {
          if (colIndex < 5) {
            inputRefs.current[rowIndex][colIndex + 1]?.focus();
            let component = inputRefs.current[rowIndex][colIndex + 1];
            if (!component?.innerHTML.includes("option"))
              inputRefs.current[rowIndex][colIndex + 1]?.select();
            IsEscPressed = false;
          }
        }

        break;
      case "F2":
        target.focus();
        target.setSelectionRange(0, 0); // Set cursor at the beginning
        IsEscPressed = false;
        break;

      case "Escape":
        // if (IsEscPressed) return;
        target.focus();
        target.style.caretColor = "transparent";
        target.setSelectionRange(0, 0);

        IsEscPressed = true;
        target.value = lastValue;
        let item = coursesData.find((item) => item.id === id);
        var propertyName = target.name;
        item[propertyName] = lastValue;

        break;
      case "Enter":
        if (IsEscPressed) {
          target.focus();
          //target.setSelectionRange(0, 0); // Set cursor at the beginning
          //target.select();
          IsEscPressed = false;
          break;
        }
        event.preventDefault();
        var nextRowIndex = rowIndex;
        var nextColIndex = colIndex;
        if (nextColIndex === 7) {
          nextColIndex = 0;
          nextRowIndex = rowIndex + 1;
        } else nextColIndex = colIndex + 1;
        if (nextRowIndex <= coursesData.length) {
          setTimeout(() => {
            inputRefs.current[nextRowIndex][nextColIndex]?.focus();
            var component = inputRefs.current[rowIndex][colIndex + 1];
            if (!component?.innerHTML.includes("option"))
              inputRefs.current[nextRowIndex][nextColIndex]?.select();
          }, 0);
        }
        break;

      default:
        target.style.caretColor = ""; // Show the cursor
        IsEscPressed = false;

        break;
    }
  };

  function handleCellChange(event, id, property, content) {
    var { value } = event.target;
    if (content) {
      value = content;
    }
    var propToSet = event.target.name;
    if (property) propToSet = property;
    setCoursesData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [propToSet]: value,
          };
        }
        return item;
      });
    });
  }

  const handleCellClick = (event, rowIndex) => {
    //event.target.select();
    writeCellValue(rowIndex);
  };

  function handleCellFocus(event, rowIndex, id) {
    var ID = tableRef.current?.rows[rowIndex]?.cells[1]?.innerText;

    if (lastIdValue === event.target.value + ID && lastValue.length > 0) {
      //console.log(lastValue + ID, "#", event.target.value + ID);
      if (event.target.tagName === "SELECT") {
        event.target.focus();
      } else {
        event.target.select();
      }
      //writeCellValue(rowIndex);
      return;
    }
    writeCellValue(rowIndex);
    setLastValue(event.target.value);
    setLastIdValue(event.target.value + ID);
    if (event.target.name === "dateTo") {
      var item = coursesData.find((item) => item.id === id);

      if (isValidDate(item.dateFrom) && getString(item.dateTo.trim()) === "") {
        var nextDate = getNextEndDate(item.dateFrom);
        handleCellChange(event, id, event.target.name, nextDate);
      } else {
        //  event.target.focus();
      }
    }

    requestAnimationFrame(() => {
      if (event.target.tagName === "SELECT") {
        event.target.focus();
      } else {
        event.target.select();
      }
    });
  }

  function handleCellLostFocusDate(event, id, rowIndex) {
    var value = event.target.value;
    if (value) {
      if (!isValidDate(value) && (value.includes("/") || value.includes("."))) {
        var result = getFormattedDate(value);
        if (typeof result === "undefined") return; // return false and set color red as error

        if (event.target.name === "dateFrom") {
          let item = coursesData.find((item) => item.id === id);
          item.dateFrom = result;
        } else if (event.target.name === "dateTo") {
          let item = coursesData.find((item) => item.id === id);
          item.dateTo = result;
        }

        //tableRef.current.rows[rowIndex].cells[2].innerText = result;
      }
      var resultDate = getFormattedDate(value);
      if (typeof resultDate === "undefined") {
        event.target.style.color = "red";
        event.target.focus();
        return;
      }
      //resultDate = formatDateWithZero(resultDate);

      if (event.target.name === "dateTo") {
        /*if (isDateLessThanToDay(resultDate)) {
          event.target.style.color = "red";
        } else {
          event.target.style.color = "black";
        }*/

        let lastDateFrom = inputRefs.current[rowIndex][0];

        if (lastDateFrom && isDate2LessDate1(lastDateFrom.value, resultDate)) {
          event.target.style.backgroundColor = "rgb(165, 42, 42)";

          lastDateFrom.style.backgroundColor = "rgb(246, 123, 123)";
        } else {
          event.target.style.backgroundColor = "transparent";
          lastDateFrom.style.backgroundColor = "transparent";
        }
        //
        var currItem = coursesData.find((item) => item.id === id);
        if (
          getValue(currItem.numberHours) === 0 &&
          getValue(currItem.numberHoursWeekly) === 0 &&
          getValue(currItem.teacherId) === 0 &&
          getValue(currItem.priceId) === 0
        ) {
          var lastItem = getBeforeLastItem();
          if (lastItem && id.toString().includes("*")) {
            setCoursesData((prevData) => {
              return prevData.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    numberHours: getValue(lastItem.numberHours),
                    numberHoursWeekly: getValue(lastItem.numberHoursWeekly),
                    teacherId: lastItem.teacherId,
                    priceId: lastItem.priceId,
                  };
                }
                return item;
              });
            });
            return;
          }
        }
      }
    }
  }
  function handleCellLostFocusHoursWeekly(event, rowIndex) {
    let numHoursDefault = inputRefs.current[rowIndex][2];
    if (!numHoursDefault) return;
    let numHoursDefaultValue = getValue(numHoursDefault.value);

    let numHoursWeekly = showNumberOfHoursAsToolTip(event, rowIndex);
    if (
      numHoursDefaultValue !== numHoursWeekly &&
      numHoursWeekly !== 0 &&
      numHoursDefaultValue !== 0
    ) {
      numHoursDefault.style.backgroundColor = "rgb(240, 230, 140)";
      numHoursDefault.title = numHoursWeekly.toString();
    } else {
      numHoursDefault.title = "";
      numHoursDefault.style.backgroundColor = "transparent";
    }
  }
  function showNumberOfHoursAsToolTip(event, rowIndex) {
    let lastDateFrom = inputRefs.current[rowIndex][0];
    let lastDateTo = inputRefs.current[rowIndex][1];
    let numWeeks = getWeeks(lastDateFrom.value, lastDateTo.value);
    if (numWeeks > 0)
      return inputRefs.current[rowIndex][3].defaultValue * numWeeks;
    return 0;
  }

  function getBeforeLastItem() {
    const item = coursesData[coursesData.length - 2];

    return item;
  }
  function getLastItem(data) {
    if (data.length > 0) {
      const itemWithMaxId = data.reduce((prev, current) => {
        const prevId = getIdValue(prev.id);
        const currentId = getIdValue(current.id);

        return prevId > currentId ? prev : current;
      });

      if (itemWithMaxId) {
        return itemWithMaxId;
      }
    }

    return null;
  }
  function getIdValue(id) {
    const idString = typeof id === "string" ? id : id.toString();
    const parsedId = parseInt(idString.replace(/^\*/, ""), 10);
    return isNaN(parsedId) ? id : parsedId;
  }

  const handleOpenDialog = () => {
    //setReload(!reload);

    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnPopUpStdInfoSave = () => {
    setIsDialogOpen(false);
  };
  function handlePopUpStudent() {
    if (studentId > 0) {
      handleOpenDialog();
    }
  }
  function handleAddNewRecord(data) {
    // props.handleSavedError("");
    setSelectedRow([]);

    if (!data) {
      const items = [];
      items.push({
        id: "*1",
        branchId,
        studentId,
        courseId,
        dateFrom: "",
        dateTo: "",
        numberHours: "",
        numberHoursWeekly: "",
        teacherId: 0,
        priceId: 0,
      });
      setCoursesData(items);
      return;
    }
    // if (!data || data.length === 0) return;
    var lastDateTo = "",
      DateFrom = "";

    const itemWithMaxId = getLastItem(data);
    if (itemWithMaxId) {
      lastDateTo = itemWithMaxId.dateTo;
      DateFrom = increaseDateByOneDay(lastDateTo);
    }
    var newId = getMaxId(data) + 1;
    setCoursesData([
      ...data,
      {
        id: "*" + newId.toString(),
        branchId,
        studentId,
        courseId,
        dateFrom: DateFrom,
        dateTo: "",
        numberHours: "",
        numberHoursWeekly: "",
        teacherId: 0,
        priceId: 0,
      },
    ]);
  }
  const generateReport = (coursesData, teacherData, priceData) => {
    if (!coursesData || coursesData.length === 0) return null;

    const studentName = coursesData[0]?.studentName;
    const courseName = coursesData[0]?.courseName;
    const fileName = studentName + "_" + courseName;

    const filteredHeader = [
      "dateFrom",
      "dateTo",
      "numberHours",
      "numberHoursWeekly",
      "teacherId",
      "priceId",
    ];

    const rows = coursesData
      .filter((course) => course.teacherId !== 0 || course.priceId !== 0)
      .map((course) => {
        const filteredCourse = {};
        filteredHeader.forEach((header) => {
          if (course.hasOwnProperty(header)) {
            filteredCourse[header] = course[header];
          }
        });
        return filteredCourse;
      });

    const rowsWithTeacherAndPrice = rows.map((row) => {
      if (row.hasOwnProperty("teacherId")) {
        const teacher = teacherData.find(
          (teacher) => teacher.id === row.teacherId
        );
        if (teacher) {
          row.teacherName = teacher.teacherName;
          delete row.teacherId; // Optionally remove the teacherId property
        }
      }

      if (row.hasOwnProperty("priceId")) {
        const price = priceData.find((price) => price.id === row.priceId);
        if (price) {
          row.priceName = price.priceName + " (" + price.priceValue + ")";
          delete row.priceId; // Optionally remove the priceId property
        }
      }

      return row;
    });

    return {
      headers,
      rowsWithTeacherAndPrice,
      fileName,
    };
  };
  function handleExportTable() {
    const report = generateReport(coursesData, teacherData, priceData);
    ToExcelByRows(
      report.headers,
      report.rowsWithTeacherAndPrice,
      report.fileName
    );
  }

  function handleCopyHeadersAndRows() {
    try {
      const TableWithHeaderAndRows = generateReport(
        coursesData,
        teacherData,
        priceData
      );

      const headersText = headers.join("\t");
      const rowsText = TableWithHeaderAndRows.rowsWithTeacherAndPrice
        .map((row) => {
          const rowData = Object.values(row);
          return rowData.join("\t");
        })
        .join("\n");
      const clipboardText = headersText + "\n" + rowsText;
      copyToClipboard(clipboardText);
    } catch (ex) {
      console.log("Error By Coppying Rows, ", ex.toString());
    }
  }

  function GetRecordStatus(intersectionState) {
    if (intersectionState === 2) return "Ja";
    if (intersectionState === 1) return "T.";
    return "";
  }
  function getMaxId(data) {
    const maxId = data.reduce((max, item) => {
      const itemId =
        typeof item.id === "string" ? item.id.replace("*", "0") : item.id;
      const numericId = Number(itemId);
      return numericId > max ? numericId : max;
    }, 0);

    return maxId;
  }

  // write
  function writeCellValue(rowIndex) {
    if (selectedRow !== []) setSelectedRow([]);
    //console.log("write Cell:  Index:", rowIndex);

    if (rowIndex === 0 || rowIndex > coursesData.length) return;

    for (let index = 0; index < (tableRef.current?.rows.length || 0); index++) {
      if (tableRef.current?.rows[index]?.cells[0]) {
        tableRef.current.rows[index].cells[0].innerText = ""; //"&rarr;"
      }
    }

    if (tableRef.current?.rows[rowIndex]?.cells[0]) {
      tableRef.current.rows[rowIndex].cells[0].innerHTML =
        '<span class="arrow-icon"></span>'; //"&rarr;"
    }

    var lastCell = tableRef.current?.rows[rowIndex]?.cells[1];
    if (tableRef.current?.rows[rowIndex]?.cells[0]) {
      // if new Added

      if (getValue(lastCell.innerText) === 0) {
        tableRef.current.rows[rowIndex].cells[0].innerHTML = "&#9658;&#9733";
        tableRef.current.rows[rowIndex].cells[0].classList.add("arrow-icon-2");
      }
    }
  }
  const handleCellFirstColumnClick = (rowIndex) => {
    writeCellValue(rowIndex);
    setSelectedRow(rowIndex === selectedRow ? null : rowIndex);
    console.log("rowIndex Click: ", rowIndex);
  };
  function handleKeyDownAndDelete(e, rowIndex, id) {
    var itemIsCalculated = coursesData.find(
      (x) => x.id === id && x.intersectionState > 0
    );
    //if (itemIsCalculated) return;
    if (e.key === "Delete" && selectedRow !== null) {
      console.log("Delete pressed");
      if (selectedRow !== null && selectedRow !== [] && !itemIsCalculated) {
        removeSelectedRow(id);
        if (rowIndex === 1) {
          rowIndex = 1;
          writeCellValue(rowIndex + 1);
          setSelectedRow(rowIndex);
        } else {
          writeCellValue(rowIndex - 1);
          setSelectedRow(rowIndex - 1);
        }
        var firstCell = tableRef.current?.rows[rowIndex - 1]?.cells[0];
        if (rowIndex === 1)
          firstCell = tableRef.current?.rows[rowIndex + 1]?.cells[0];
        if (firstCell) {
          firstCell.focus();
        }
      }
    } else if (e.key === "ArrowUp" && rowIndex > 1) {
      console.log("rowIndex:", rowIndex);
      var currentRow = tableRef.current?.rows[rowIndex];
      console.log(currentRow);
      writeCellValue(rowIndex - 1);
      setSelectedRow(rowIndex - 1);

      const firstCell = tableRef.current?.rows[rowIndex - 1]?.cells[0];
      const secCell = tableRef.current?.rows[rowIndex - 1]?.cells[1];
      console.log("secCell: at  ", rowIndex - 1, "= ", secCell.innerText);
      if (firstCell) {
        firstCell.focus();
      }
    } else if (
      e.key === "ArrowDown" &&
      rowIndex < (tableRef.current?.rows.length - 1 || 0)
    ) {
      console.log("rowIndex:", rowIndex);

      console.log(currentRow);
      writeCellValue(rowIndex + 1);
      setSelectedRow(rowIndex + 1);

      const firstCell = tableRef.current?.rows[rowIndex + 1]?.cells[0];
      const secCell = tableRef.current?.rows[rowIndex + 1]?.cells[1];
      console.log("secCell: at  ", rowIndex + 1, "= ", secCell?.innerText);
      if (firstCell) {
        firstCell.focus();
      }
    }
    // to put cursor next the editable cell
    else if (e.key === "ArrowRight") {
      setSelectedRow([]);

      const firstCell = tableRef.current?.rows[rowIndex]?.cells[2];

      console.log("firstCell: at  ", rowIndex, "= ", firstCell?.innerText);
      if (firstCell) {
        firstCell.focus();
        inputRefs.current[rowIndex][0]?.focus();
        inputRefs.current[rowIndex][0]?.select();
      }
    }
  }
  const removeSelectedRow = (id) => {
    const item = coursesData.find(
      (item) => item.id === id && !item.id.toString().startsWith("*")
    );
    if (item) {
      setCoursesDataToDelete([...coursesDataToDelete, item]);
    }
    setCoursesData((prevData) =>
      prevData.filter((row, index) => index + 1 !== selectedRow)
    );
    console.log("coursesData: ", coursesData);
    setSelectedRow([]);
  };

  function isEmptyRow(rowIndex) {
    const rowsCount = tableRef.current.rows.length;
    if (rowsCount - 1 !== rowIndex) return true;
    const row = tableRef.current.rows[rowIndex];
    var countEmpty = 0;
    for (let i = 2; i < row.cells.length; i++) {
      const inputElement = row.cells[i];
      const value = inputElement.querySelector("input")?.value;
      const selectElementValue = inputElement.querySelector("select")?.value;
      if (typeof value != "undefined" && value.trim() === "") {
        countEmpty = countEmpty + 1;
      }
      if (
        typeof selectElementValue != "undefined" &&
        selectElementValue.trim() === ""
      ) {
        countEmpty = countEmpty + 1;
      }
    }
    const lastRow = tableRef.current.rows[tableRef.current.rows.length - 1];
    var lastRowCountEmpty = 0;
    for (let i = 2; i < row.cells.length; i++) {
      const inputElement = lastRow.cells[i];
      const value = inputElement.querySelector("input")?.value;
      const selectElementValue = inputElement.querySelector("select")?.value;
      if (typeof value != "undefined" && value.trim() === "") {
        lastRowCountEmpty = lastRowCountEmpty + 1;
      }
      if (
        typeof selectElementValue != "undefined" &&
        selectElementValue.trim() === ""
      ) {
        lastRowCountEmpty = lastRowCountEmpty + 1;
      }
    }
    if (lastRowCountEmpty > 0) return true; // no Empty
    return countEmpty > 0 ? true : false; // true if no empty
  }
  function isRecordEmpty(record) {
    // const isDateFromEmpty = record.dateFrom.trim() === "";
    const isOtherFieldsEmpty =
      (record.dateTo.trim() === "" || record.dateTo.includes("undefined")) &&
      getValue(record.priceId.toString()) === 0 &&
      getValue(record.teacherId.toString()) === 0 &&
      getValue(record.numberHours.toString().trim()) === 0 &&
      getValue(record.numberHoursWeekly.toString().trim()) === 0;

    return isOtherFieldsEmpty;
  }
  async function RemoveAllEmptyItems() {
    const filteredData = coursesData.filter(
      (item) =>
        item.dateFrom.trim() !== "" ||
        item.dateTo.trim() !== "" ||
        getValue(item.priceId.toString()) !== 0 ||
        getValue(item.teacherId.toString()) !== 0 ||
        getValue(item.numberHours.toString().trim()) !== 0 ||
        getValue(item.numberHoursWeekly.toString().trim()) !== 0
    );

    console.log("RemoveAllEmptyItems>> ", filteredData);
    setCoursesData(() => {
      return filteredData;
    });
    return coursesData;
  }
  async function IsValidItems() {
    var isValid = true;

    // props.handleSavedError("");
    coursesData.forEach((item, index) => {
      if (!isRecordEmpty(item)) {
        if (index === tableRef.current.rows.length - 1) return;
        var row = tableRef.current.rows[index + 1];
        if (typeof row === "undefined") return;
        const dateFrom = row.cells[2].querySelector("input");
        const dateTo = row.cells[3].querySelector("input");
        const numHours = row.cells[4].querySelector("input");
        const numHoursWeekly = row.cells[5].querySelector("input");
        const teacherId = row.cells[6].querySelector("select");
        const priceId = row.cells[7].querySelector("select");

        dateFrom.style.backgroundColor = "transparent";
        dateTo.style.backgroundColor = "transparent";
        numHours.style.backgroundColor = "transparent";
        numHoursWeekly.style.backgroundColor = "transparent";
        teacherId.style.backgroundColor = "transparent";
        priceId.style.backgroundColor = "transparent";

        if (item.dateFrom.trim() === "" || !isValidDate(item.dateFrom)) {
          if (dateFrom) dateFrom.style.backgroundColor = "yellow";
          isValid = false;
        }
        if (
          item.dateTo.trim() === "" ||
          !isValidDate(item.dateTo) ||
          isDate2LessDate1(item.dateFrom, item.dateTo)
        ) {
          if (dateTo) dateTo.style.backgroundColor = "yellow";
          isValid = false;
        }
        if (getValue(item.numberHours) <= 0) {
          if (numHours) numHours.style.backgroundColor = "yellow";
          isValid = false;
        }
        if (getValue(item.numberHoursWeekly) <= 0) {
          if (numHoursWeekly) numHoursWeekly.style.backgroundColor = "yellow";
          isValid = false;
        }
        if (getValue(item.numberHoursWeekly) > getValue(item.numberHours)) {
          if (numHours) numHours.style.backgroundColor = "yellow";
          isValid = false;
        }

        if (getValue(item.teacherId) === 0) {
          if (teacherId) teacherId.style.backgroundColor = "yellow";
          isValid = false;
        }
        if (getValue(item.priceId) === 0) {
          if (priceId) priceId.style.backgroundColor = "yellow";
          isValid = false;
        }
      }
    });
    return isValid;
  }
  function getMinMaxDate(dataArray) {
    let minDate = null;
    let maxDate = null;
    let minDateFormated = null;
    let maxDateFormatted = null;

    dataArray.forEach((data) => {
      const dateFromComponents = data.dateFrom.split("/");
      const currentDateFrom = new Date(
        parseInt(dateFromComponents[2]), // Year
        parseInt(dateFromComponents[1]) - 1, // Month (subtract 1 because months are 0-based)
        parseInt(dateFromComponents[0]) // Day
      );

      const dateToComponents = data.dateTo.split("/");
      const currentDateTo = new Date(
        parseInt(dateToComponents[2]), // Year
        parseInt(dateToComponents[1]) - 1, // Month (subtract 1 because months are 0-based)
        parseInt(dateToComponents[0]) // Day
      );

      if (!minDate || currentDateFrom < minDate) {
        minDate = currentDateFrom;
        minDateFormated = formatDate(minDate);
      }

      if (!maxDate || currentDateTo > maxDate) {
        maxDate = currentDateTo;
        maxDateFormatted = formatDate(maxDate);
      }
    });

    return {
      minDateFormated,
      maxDateFormatted,
    };
  }
  async function handleCheckAll() {
    props.handleSavedError("");
    RemoveAllEmptyItems();
    await IsValidItems();
  }
  async function handleRefresh() {
    // setIsHidden(false);
    //props.handleSaved(false);

    props.handleSavedError("");
    setSelectedRow([]);
    setFirstTime(false);
    setCoursesDataToDelete([]);

    props.handleResultCount(coursesData.length);

    setReload(!reload);
    await handleCheckAll();
  }
  async function handleOnSave() {
    setStatusWait("Bitte warten");
    await doSaveApi();
    setStatusWait("");
  }

  async function handleOpenAbrechnung() {
    var minMaxDate = getMinMaxDate(coursesData);
    if (isDate(minMaxDate.minDateFormated)) {
      setStorageItem("Calculation", "dateFrom", minMaxDate.minDateFormated);
    }
    if (isDate(minMaxDate.maxDateFormatted)) {
      setStorageItem("Calculation", "dateTo", minMaxDate.maxDateFormatted);
    }

    setStorageItem("Calculation", "regionId", getValue(regionId));
    setStorageItem("Calculation", "branchId", getValue(branchId));
    setStorageItem("Calculation", "studentId", getValue(studentId));
    setStorageItem("Calculation", "readFromAuftrag", true);

    window.open("/calculations", "_blank");
  }
  async function handleOpenDetails() {
    setIsDetailsDialogOpen(true);
  }
  function handleCloseDetailDialog() {
    setIsDetailsDialogOpen(false);
  }

  async function doSaveApi() {
    props.handleSaved(false);
    props.handleSavedError("");

    await RemoveAllEmptyItems();

    if (IsValidItems()) {
      const coursesDataToUpdate = JSON.parse(JSON.stringify(coursesData));

      if (
        JSON.stringify(coursesDataToUpdate) ===
        JSON.stringify(coursesDataOriginal)
      ) {
      }

      coursesDataToUpdate.forEach((record) => {
        record.dateFrom = toJsonDateTimeHandle(record.dateFrom);

        record.dateTo = toJsonDateTimeHandle(record.dateTo);
      });
      coursesDataToUpdate.sort(
        (a, b) => new Date(a.dateTo) - new Date(b.dateTo)
      );

      // delete
      var isSaved = await deleteCourses();
      if (!isSaved) {
        props.handleSaved(false);
        return;
      }

      isSaved = await updateAndAddCourses(coursesDataToUpdate);
      if (!isSaved) {
        props.handleSaved(false);
        return;
      }

      if (isSaved) {
        handleRefresh();
        props.handleSaved(true);
      }

      // parse coursesData, convert to date yyyy-mm-dd , integer : 01 --> 1
      // Do Save API
    } else {
      props.handleSavedError("Bitte beheben Sie die Fehler in der Tabelle!");
      console.log("please fix the errors in the table!");
    }
  }
  async function updateAndAddCourses(coursesDataToUpdate) {
    var res;
    for (const record of coursesDataToUpdate) {
      if (Number.isInteger(record.id)) {
        // update
        res = await Put("TrackerStudent/Order", record.id, record);
        if (!res.isSuccess) {
          props.handleSavedError(res.Error);
          return false;
        }
      } else {
        // add new
        record.id = 0;
        if (isRecordEmpty(record)) continue;
        res = await Post("TrackerStudent/Order", record);
        if (!res.isSuccess) {
          props.handleSavedError(res.Error);
          return false;
        }
      }
    }
    return true;
  }

  async function deleteCourses() {
    var res;
    for (const record of coursesDataToDelete) {
      if (record.id === 0) continue;
      res = await Delete("TrackerStudent/Order", record.id);
      if (!res.isSuccess) {
        handleRefresh();
        props.handleSavedError(res.Error);
        return false;
      }
    }
    return true;
  }
  function handleRowClick(item) {
    try {
      setSelectedDateFrom(toJsonDate(item.dateFrom));
      setSelectedDateTo(toJsonDate(item.dateTo));
      setLastUpdatedUserDate(item.userDate);
    } catch (ex) {
      console.log("Error to select date/from/to: ", ex.toString());
    }
  }
  function handleRowDoubleClick(item) {
    handleRowClick(item);
    handleOpenDetails();
  }
  function isValidSelectedDate() {
    try {
      var isValidDates = isDate(selectedDateFrom) && isDate(selectedDateTo);
      return isValidDates;
    } catch (ex) {
      console.log("Error to Validate date/from/to: ", ex.toString());
    }
  }
  return (
    // hidden={!props.Visible}
    <div className="containers">
      <br />
      <div>
        <Button
          disabled={props.StudentId === 0}
          onClick={(e) => handlePopUpStudent()}
          variant="outlined"
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
        >
          * Info
        </Button>

        <StudentDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleOnPopUpStdInfoSave}
          //reload={true}
          StudentId={studentId}
        />

        <Button
          disabled={props.Disabled}
          onClick={(e) => handleAddNewRecord(coursesData)}
          variant="outlined"
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
        >
          +Neu
        </Button>

        <Button
          disabled={props.Disabled}
          variant="outlined"
          onClick={handleCheckAll}
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
          startIcon={<CheckIcon />}
        >
          Überprüfen
        </Button>
        <Button
          disabled={props.Disabled}
          variant="outlined"
          onClick={handleRefresh}
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
        <Button
          disabled={props.Disabled}
          variant="outlined"
          onClick={handleOnSave}
          sx={{
            fontWeight: "bold",
            color: "darkblue", // Set the font color here
            mr: 1,
            "&:hover": { bgcolor: "#dfeaf9" },
          }}
          startIcon={<SaveIcon />}
        >
          Speichern
        </Button>

        <Button
          disabled={props.StudentId === 0}
          variant="outlined"
          onClick={handleOpenAbrechnung}
          sx={{
            mr: 1,
            "&:hover": { bgcolor: "#e6fcef", borderColor: "#bff5d5" },
          }}
          startIcon={<TableViewOutlinedIcon />}
        >
          Abrechnung {"==>"}
        </Button>
        <br />
        <Button
          disabled={props.Disabled}
          variant="outlined"
          onClick={handleOpenDetails}
          sx={{
            margin: "2px",
            mr: 1,
            "&:hover": { bgcolor: "#dfeaf9", borderColor: "#dfeaf9" },
          }}
          startIcon={<TocIcon />}
        >
          Details
        </Button>
        <Button
          disabled={props.Disabled}
          // className="custom-button"
          onClick={(e) => handleCopyHeadersAndRows()}
          variant="outlined"
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
          startIcon={<CopyIcon />}
        >
          Copy
        </Button>

        <Button
          disabled={props.Disabled}
          // className="custom-button"
          onClick={(e) => handleExportTable()}
          variant="outlined"
          sx={{ mr: 1, "&:hover": { bgcolor: "#dfeaf9" } }}
          startIcon={<Export />}
        >
          XLSX
        </Button>

        <Label
          className="status-label"
          style={{
            width: "200px",
            fontSize: "20px",
            fontWeight: "bold",
            backgroundColor: "yellow",
          }}
        >
          {statusWait}
        </Label>
      </div>
      <br />

      <div>
        <LegendDataTable />
      </div>
      <br />
      <div
        style={{
          maxHeight: "580px",
          overflowY: "auto",
          border: "2px solid #cccccc",
          borderRadius: "4px",
        }}
      >
        <table id="table" className="tablex" ref={tableRef}>
          <thead>
            <tr>
              <th></th>
              <th style={{ display: "none" }}>ID</th>
              <th>Von</th>
              <th>Zum</th>
              <th>Anzahl(Std)</th>
              <th>W/S</th>
              <th>Lehrkraft</th>
              <th>E/G (€)</th>
              <th style={{ display: "none" }}>B?</th>
            </tr>
          </thead>
          <tbody>
            {coursesData &&
              coursesData.map((item, index) => {
                const rowIndex = index + 1;
                inputRefs.current[rowIndex] = [];

                return (
                  <tr
                    key={item.id}
                    // onClick={() => handleDoubleClick(rowIndex)}
                    className={
                      rowIndex === selectedRow
                        ? "selected"
                        : item.intersectionState === 2
                        ? "lightgreen-row"
                        : item.intersectionState === 1
                        ? "lightgreenwhite-row"
                        : getValue(item.id) === 0
                        ? "lightblue-row"
                        : ""
                    }
                    onClick={(e) => handleRowClick(item)}
                  >
                    <td
                      className="cell-header"
                      tabIndex={0}
                      style={{ width: "55px", cursor: "pointer" }}
                      onClick={() => handleCellFirstColumnClick(rowIndex)}
                      onKeyDown={(event) =>
                        handleKeyDownAndDelete(event, rowIndex, item.id)
                      }
                      onDoubleClick={(e) => handleRowDoubleClick(item)}
                    ></td>
                    <td style={{ display: "none" }}>{item.id}</td>
                    <td>
                      <input
                        autoFocus={index === coursesData.length - 1}
                        readOnly={item.intersectionState > 0}
                        name="dateFrom"
                        className="editable-cell"
                        style={{ width: "100px" }}
                        ref={(ref) => (inputRefs.current[rowIndex][0] = ref)}
                        type="text"
                        value={item.dateFrom}
                        autoComplete="off"
                        onChange={(event) => handleCellChange(event, item.id)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, rowIndex, 0, item.id)
                        }
                        onClick={(event) => handleCellClick(event, rowIndex)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onBlur={(event) =>
                          handleCellLostFocusDate(event, item.id, rowIndex)
                        }
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        // onSelect={(event) => handleSelect(event)}
                      />
                    </td>
                    <td>
                      <input
                        name="dateTo"
                        readOnly={item.intersectionState > 0}
                        className="editable-cell"
                        style={{ width: "100px" }}
                        ref={(ref) => (inputRefs.current[rowIndex][1] = ref)}
                        type="text"
                        value={item.dateTo}
                        autoComplete="off"
                        onChange={(event) => handleCellChange(event, item.id)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, rowIndex, 1, item.id)
                        }
                        onClick={(event) => handleCellClick(event, rowIndex)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onBlur={(event) =>
                          handleCellLostFocusDate(event, item.id, rowIndex)
                        }
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </td>
                    <td>
                      {/* <span name={"numberHoursSpan" + rowIndex}> */}
                      <input
                        className="editable-cell"
                        name="numberHours"
                        readOnly={item.intersectionState > 0}
                        style={{ width: "85px" }}
                        autoComplete="off"
                        ref={(ref) => (inputRefs.current[rowIndex][2] = ref)}
                        value={item.numberHours}
                        onChange={(event) => handleCellChange(event, item.id)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, rowIndex, 2, item.id)
                        }
                        onClick={(event) => handleCellClick(event, rowIndex)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onBlur={(event) =>
                          handleCellLostFocusHoursWeekly(event, rowIndex)
                        }
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                      />

                      {/* </span> */}
                    </td>
                    <td>
                      <input
                        name="numberHoursWeekly"
                        className="editable-cell"
                        readOnly={item.intersectionState > 0}
                        style={{ width: "80px" }}
                        autoComplete="off"
                        ref={(ref) => (inputRefs.current[rowIndex][3] = ref)}
                        type="text"
                        value={item.numberHoursWeekly}
                        onChange={(event) =>
                          handleCellChange(event, item.id, "numberHoursWeekly")
                        }
                        onKeyDown={(event) =>
                          handleKeyDown(event, rowIndex, 3, item.id)
                        }
                        onClick={(event) => handleCellClick(event, rowIndex)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onBlur={(event) =>
                          handleCellLostFocusHoursWeekly(event, rowIndex)
                        }
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </td>
                    <td>
                      <select
                        name="teacherId"
                        className="custom-select"
                        disabled={item.intersectionState > 0}
                        style={{ width: "200px", cursor: "pointer" }}
                        //onChange={(e) => handleCityChange(index, e)}
                        onChange={(event) => handleCellChange(event, item.id)}
                        value={item.teacherId}
                        ref={(ref) => (inputRefs.current[rowIndex][4] = ref)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onKeyDown={(event) =>
                          handleKeyDownSelect(event, rowIndex, 4)
                        }
                      >
                        <option value=""></option>
                        {teacherData.map((teacher) => {
                          return (
                            <option
                              id={teacher.id}
                              key={teacher.id}
                              value={teacher.id}
                              label={teacher.teacherName}
                            >
                              {teacher.id}
                            </option>
                          );
                        })}
                      </select>
                    </td>

                    <td>
                      <select
                        disabled={item.intersectionState > 0}
                        name="priceId"
                        className="custom-select"
                        style={{ width: "90px", cursor: "pointer" }}
                        //onChange={(e) => handleCityChange(index, e)}
                        onChange={(event) => handleCellChange(event, item.id)}
                        value={item.priceId}
                        ref={(ref) => (inputRefs.current[rowIndex][5] = ref)}
                        onFocus={(event) =>
                          handleCellFocus(event, rowIndex, item.id)
                        }
                        onKeyDown={(event) =>
                          handleKeyDownSelect(event, rowIndex, 5)
                        }
                      >
                        <option value=""></option>
                        {priceData.map((price) => {
                          return (
                            <option
                              id={price.id}
                              key={price.id}
                              value={price.id}
                              label={
                                price.priceName + " (" + price.priceValue + ")"
                              }
                            >
                              {price.id}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td style={{ display: "none" }}>
                      {GetRecordStatus(item.intersectionState)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <Footer
        TotalCalculatedHours={totalCalculatedHours}
        TotalHours={totalHours}
      ></Footer>

      <Box>
        {isDetailsDialogOpen && isValidSelectedDate() && (
          <CalculationDetailsDialog
            BranchId={props.BranchId}
            StudentId={props.StudentId}
            CourseId={props.CourseId}
            DateFrom={selectedDateFrom} // "2022-09-15"
            DateTo={selectedDateTo} //"2024-12-31"
            UserDate={lastUpdatedUserDate} // 2023-08-27 5:00:01
            onClose={handleCloseDetailDialog}
          />
        )}
      </Box>
    </div>
  );
}

export default EditableDataTable;
