import { configureStore } from "@reduxjs/toolkit";
import branchReducer from "./slices/branch-slice";
import regionReducer from "./slices/region-slice";
import tokenReducer from "./slices/token-slice";

export const store = configureStore({
  reducer: {
    branch: branchReducer,
    region: regionReducer,
    token: tokenReducer,
  },
});
