import React from "react";

const LegendDataTable = () => {
  return (
    <div style={{ paddingLeft: "20px", display: "flex", flexDirection: "row" }}>
      {/* <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        { <div
          style={{
            backgroundColor: "white",
            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>  }
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>
          Nicht Berechnet
        </div>
      </div> */}
      {/* <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: "lightblue",
            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>Neu</div>
      </div> */}
      <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0, 255, 0, 0.2)",
            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>
          Berechnet
        </div>
      </div>

      <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 255, 0, 0.2), white)",

            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>
          Teil.-Berechnet
        </div>
      </div>

      <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: "yellow",
            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>
          Fehler
        </div>
      </div>
      {/* <div
        style={{
          border: "0px solid darkgray",
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: "lightcoral",
            width: "20px",
            height: "20px",
            border: "1px solid darkgray",
          }}
        ></div>
        <div style={{ flex: 1, fontSize: "12px", paddingLeft: "5px" }}>
          Abgelaufen
        </div>
      </div> */}
    </div>
  );
};

export default LegendDataTable;
