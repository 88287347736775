import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { GridRowModes, DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { Get } from "../../Common/WebRequest";

import { getStorageItem, getToDay, slice } from "../../Common/Utility";
import EditToolbar from "./EditToolbar";
import columnsBuilder from "./columnsBuilder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import { blue } from "@mui/material/colors";
import { Typography } from "@mui/material";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Padding } from "@mui/icons-material";

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,

  exportFileName: PropTypes.any,
};

export default function AuditGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [auditLogData, setAuditLogData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const dateFrom = useRef();
  const dateTo = useRef();

  const columns = columnsBuilder({});
  let exportFileName = "AuditLogData";
  const [userName, setUserName] = useState("");
  useEffect(() => {
    (async () => {
      let user = await getStorageItem("login", "userName");

      setUserName(user);
      await loadLogData();
    })();
  }, [userName, dateFrom, dateTo]);

  async function loadLogData() {
    setError("");
    setAuditLogData([]);
    if (userName === "") return;
    let res = await Get(
      "AuditLog/" +
        userName +
        "/" +
        dateFrom.current.value +
        "/" +
        dateTo.current.value
    );
    if (res.isSuccess) {
      setAuditLogData(res.result);
    } else {
      if (res.result && !res.result.includes("200/No Results"))
        setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    }
  }

  async function handleShow(e) {
    e.preventDefault();

    await loadLogData();

    console.log(dateFrom.current.value, dateTo.current.value);
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "850px",
        "& .Mui-even": {
          backgroundColor: "#FCFFEF",
        },
        "& .Mui-odd": {
          backgroundColor: "#FCFCFC",
        },
        "& .MuiDataGrid-cell:hover": {
          backgroundColor: "#80B3FF",
        },
        "&& .MuiDataGrid-row:hover": {
          backgroundColor: "#cbe0ff",
        },
      }}
    >
      <br></br>
      <div className="App" style={{ backgroundColor: "white" }}>
        <Typography
          variant="headline"
          component="h2"
          align="left"
          color={blue[500]}
        >
          Audit Logs
        </Typography>
      </div>
      <br />
      <form>
        <label>
          Von
          <input
            className="form-control"
            id="from"
            type="date"
            ref={dateFrom}
            defaultValue={getToDay()}
          ></input>
        </label>
        <label>-</label>
        <label>
          Zum
          <input
            className="form-control"
            id="to"
            type="date"
            ref={dateTo}
            defaultValue={getToDay()}
          ></input>
        </label>
        &nbsp;&nbsp;&nbsp;
        <button className="btn btn-primary" onClick={handleShow}>
          Vorschu
        </button>
      </form>
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <HighlightOffIcon></HighlightOffIcon> {slice(error, 100, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          <SuccessIcon></SuccessIcon> {slice(successMsg, 100, "...")}
        </div>
      )}

      <DataGrid
        rowSelection={false}
        density="compact"
        rows={auditLogData}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={5}
        // hideFooterPagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
          filter: {
            filterModel: {
              items: [
                { field: "actionDescription", operator: "contains", value: "" },
              ],
            },
          },
        }}
        rowModesModel={rowModesModel}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setAuditLogData,
            setRowModesModel,
            exportFileName,
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
      />
    </Box>
  );
}
