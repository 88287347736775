import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useEffect } from "react";
import {
  base64Encode,
  capitalizeFirstLetter,
  getStorageItem,
} from "../../Common/Utility";
import { useNavigate } from "react-router-dom";
import { Put } from "../../Common/WebRequest";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      setUserName(await getStorageItem("login", "userName"));
      setUserId(await getStorageItem("login", "userId"));
    })();
  }, []);

  async function handleSubmit() {
    if (!userName || userName.length === 0) {
      navigate("/loginPage");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwörter stimmen nicht überein!");
      return;
    }

    if (newPassword.length < 5 || newPassword.length > 20) {
      setErrorMessage("Das Passwort muss zwischen 5 und 20 Zeichen lang sein!");
      return;
    }

    // Call your API here to submit the password change
    // changePasswordApiCall(currentPassword, newPassword);
    var dtoLogin = {
      id: userId,
      userName: userName,

      password: base64Encode(currentPassword) + ";" + base64Encode(newPassword),
    };
    var res = await Put("Login/UpdatePassword", userId, dtoLogin);
    if (res.isSuccess) {
      handleClear();
      setSuccessMessage("Ihr Kennwort wurde erfolgreich geändert!");
    } else {
      setErrorMessage("Fehler beim Ändern Ihres Passwortes!" + res.Error);
    }
  }

  const handleClear = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrorMessage("");
  };

  const handleClose = () => {
    navigate("/home"); // Navigate to '/home'
  };

  return (
    <Container maxWidth="sm">
      <br />
      <Typography variant="h6">Kennwort ändern</Typography>
      <form>
        <TextField
          label="Nutzername"
          value={capitalizeFirstLetter(userName)}
          required
          fullWidth
          disabled
          margin="normal"
        />
        <TextField
          autoFocus
          autoComplete="new-password"
          //autoComplete="off"
          required
          id="currentpassword"
          name="currentpassword"
          label="Aktuelles Passwort"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Neues Kennwort"
          id="newpassword"
          name="newpassword"
          required
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          error={errorMessage !== ""}
          helperText={errorMessage}
        />
        <TextField
          id="confirmpassword"
          name="confirmpassword"
          required
          label="Neues Passwort bestätigen"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          error={errorMessage !== ""}
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginRight: "10px" }}
        >
          Speichern
        </Button>

        <Button variant="outlined" onClick={handleClose}>
          Schließen
        </Button>
      </form>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage("")}
      >
        <div>
          <Alert onClose={() => setSuccessMessage("")} severity="success">
            {successMessage}
          </Alert>
        </div>
      </Snackbar>
    </Container>
  );
};

export default UserChangePasswordForm;
