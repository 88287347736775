import { Route, Routes } from "react-router-dom";
import TrackerStudentMain from "./Pages/TrackerStudents/TrackerStudentMain.tsx";

import Home from "./Pages/Home";

import RegionsGrid from "./Pages/Regions/RegionsGrid";
import BranchesGrid from "./Pages/Branches/BranchesGrid";
import CompanyGrid from "./Pages/Company/CompanyGrid";
import CoursesGrid from "./Pages/Courses/CoursesGrid";
import TeachersGrid from "./Pages/Teachers/TeachersGrid";
import StudentsGrid from "./Pages/Students/StudentsGrid";
import PricesGrid from "./Pages/Prices/PricesGrid";
import InvoicesNumbersGrid from "./Pages/InvoicesNumber/InvoicesNumbersGrid";
import StudentCalculationTable from "./Pages/CalculationView/StudentCalculationTable";
import Calculation from "./Pages/Calculation/Calculation";
import SystemLogs from "./Pages/SystemLog/SystemLogs";
import LoginPage from "./Pages/Login/LoginPage.js";

import HelpDocuments from "./Pages/Help/HelpDocuments.js";
import { PdfFilesHistory } from "./Pages/PdfHistory/PdfFilesHistory.js";

import UserProfile from "./Pages/UserProfile/UserProfile.js";
import NotFoundPage from "./Pages/NotMappedPages/NotFoundPage.js";
import ControlPanel from "./Pages/ControlPanel/ControlPanel.js";
import AuditGrid from "./Pages/AuditLog/AuditGrid.js";
import SystemUpdate from "./Pages/SystemUpdate/SystemUpdate.js";
import NotFoundPdf from "./Pages/NotMappedPages/NotFoundPdf.js";
import PasswordRecoveryForm from "./Pages/PasswordRecovery/PasswordRecoveryForm.js";
import EmailSent from "./Pages/PasswordRecovery/EmailSent.js";

import PasswordResetForm from "./Pages/PasswordRecovery/PasswordResetForm.js";
import PasswordResetInvalid from "./Pages/PasswordRecovery/PasswordResetInvalid.js";

function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/NotFoundPdf" element={<NotFoundPdf />} />
      <Route path="/PasswordRecovery" element={<PasswordRecoveryForm />} />
      <Route path="/EmailSent" element={<EmailSent />} />

      <Route path="/PasswordResetForm" element={<PasswordResetForm />} />
      <Route path="/PasswordResetInvalid" element={<PasswordResetInvalid />} />

      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/LoginPage" element={<LoginPage />} />
      <Route path="/Regions" element={<RegionsGrid />} />
      <Route path="/Branches" element={<BranchesGrid />} />
      <Route path="/Companies" element={<CompanyGrid />} />
      <Route path="/Courses" element={<CoursesGrid />} />
      <Route path="/Teachers" element={<TeachersGrid />} />
      <Route path="/Students" element={<StudentsGrid />} />
      <Route path="/Prices" element={<PricesGrid />} />
      <Route path="/Invoicenumbers" element={<InvoicesNumbersGrid />} />

      <Route path="/TrackerStudents" element={<TrackerStudentMain />} />
      <Route path="/Calculations" element={<Calculation />} />
      <Route path="/CalculationsView" element={<StudentCalculationTable />} />

      <Route path="/PdfFilesHistory" element={<PdfFilesHistory />} />

      <Route path="/ControlPannel" element={<ControlPanel />} />

      <Route path="/AuditLogs" element={<AuditGrid />} />

      <Route path="/UserProfile" element={<UserProfile />} />
      <Route path="/SystemUpdates" element={<SystemUpdate />} />

      <Route path="/SystemLogs" element={<SystemLogs />} />
      <Route path="/Help" element={<HelpDocuments />} />
    </Routes>
  );
}

export default MainRouter;
