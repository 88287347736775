import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const DialogBoxWithResult = ({ open, onClose, title, text, result }) => {
  const isSuccess = result === "success";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isSuccess ? (
            <CheckCircleIcon
              sx={{
                color: "green",
                fontSize: 32,
                verticalAlign: "middle",
                marginRight: 1,
              }}
            />
          ) : (
            <ErrorIcon
              sx={{
                color: "red",
                fontSize: 32,
                verticalAlign: "middle",
                marginRight: 1,
              }}
            />
          )}
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxWithResult;
