//import "./App.css";
import { useNavigate } from "react-router-dom";

import MainRouter from "./MainRouter";
import NavSideBar from "./components/SideBars/NavSideBar";
import TitleBar from "./components/NavBars/TitleBar";
import LoginPage from "./Pages/Login/LoginPage";
import { useEffect, useState } from "react";
import handleRefreshToken from "./AppHandleRefreshToken";
import { clearCacheData } from "./Common/Utility";
import PasswordRecoveryForm from "./Pages/PasswordRecovery/PasswordRecoveryForm";
import EmailSent from "./Pages/PasswordRecovery/EmailSent";
import PasswordResetForm from "./Pages/PasswordRecovery/PasswordResetForm";
import PasswordResetInvalid from "./Pages/PasswordRecovery/PasswordResetInvalid";

const loginData = JSON.parse(localStorage.getItem("login"));

const TwoHour = 2 * 60 * 60 * 1000; // Refresh Token

//const Sec10 = 6 * 1000; // Refresh Token

function App() {
  const [marginLeft, setMarginLeft] = useState("295px");
  const [width, setWidth] = useState("291px");
  const [userLogout, setUserLogout] = useState(false);
  // Function to clear complete cache data

  const handleNavToggle = (isLogout) => {
    setUserLogout(isLogout);
    if (isLogout) {
      setMarginLeft("0px");
      setWidth(0);
      return;
    }
    if (marginLeft === "295px") {
      //setMarginLeft("103px");
      //setWidth("90px");

      setMarginLeft("0px");
      setWidth("0px");
    } else {
      setMarginLeft("295px");
      setWidth("291px");
    }
  };

  useEffect(() => {
    (async () => {
      clearCacheData();
      if (loginData && loginData.token !== "") {
        setInterval(handleRefreshToken, TwoHour);
      }
    })();
  }, []);

  const navigate = useNavigate();

  // Check if the current route is "/passwordrecovery"

  const isPasswordRecoveryRoute =
    window.location.pathname === "/passwordrecovery";

  const isEmailSentRoute = window.location.pathname === "/emailsent";
  const isPasswordResetRoute = window.location.pathname === "/passwordreset";
  const isPasswordResetInvalid =
    window.location.pathname === "/PasswordResetInvalid";

  if (isPasswordRecoveryRoute) {
    return <PasswordRecoveryForm />;
  }
  if (isEmailSentRoute) {
    return <EmailSent />;
  }
  if (isPasswordResetRoute) {
    return <PasswordResetForm />;
  }

  if (isPasswordResetInvalid) {
    return <PasswordResetInvalid />;
  }

  if (!loginData || loginData.token === "") {
    console.log("Not logged");

    return (
      <div className="App">
        <div style={{ marginLeft: "1px" }}></div>

        <LoginPage onNavToggle={handleNavToggle} />
        <NavSideBar width="0px" />
      </div>
    );
  } else {
    return (
      <>
        <div className="App">
          <div>
            <NavSideBar width={width} />
          </div>
          <div
            style={{
              marginLeft: marginLeft,
              marginRight: "1px",
              paddingLeft: "1px",
            }}
          >
            <TitleBar onNavToggle={handleNavToggle} />
            <div style={{ paddingLeft: "15px" }}>
              <MainRouter />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default App;
