import React from "react";

import TreeViewTracker from "./TreeViewAuftrag/TreeViewTracker";

import { useState } from "react";
import "./TrackerStudentMain.css";
import "./TrackerCourses/TrackerCourses.css";

import TrackerCoursesGrid from "./TrackerCourses/TrackerCoursesGrid";

function TrackerStudentMain() {
  const [queryId, setQueryId] = useState(0);
  const [regionId, setRegionId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [courseId, setCourseId] = useState(0);
  const[courseSaved,setCourseSaved] = useState(false);
  const[resultCount,setResultCount] = useState(0); 
  
//------------- Selected Student  ----------------------------------------
  const [selectedStudentsRowsData, setSelectedStudentsRowsData] = useState([]);
  const [selectedStudentsDefaultIds, setSelectedStudentsDefaultIds] = useState(
    [] as number[]
  );
  const [isSelectedStudent, setIsSelectedStudent] = useState(false);
//---------------- Selected Course
  const [selectedCoursesRowsData, setSelectedCoursesRowsData] = useState([]);
  const [selectedCoursesDefaultIds, setSelectedCoursesDefaultIds] = useState(
    [] as number[]
  );
  const [isSelectedCourse, setIsSelectedCourse] = useState(false);
  const[isFirstTime,setIsFirstTime] = useState(false);

  function handleStudentsAdded(selectedStudents) {
    setSelectedStudentsRowsData(selectedStudents);
     
    console.log("Selected ====> Students Added +", selectedStudents);
    if (!Array.isArray(selectedStudents)) return;
    const studentIds = selectedStudents?.map((student) => student.id);
    setSelectedStudentsDefaultIds(studentIds);
    setIsSelectedStudent(() => {
      return !isSelectedStudent;
    });
  }

  function handleCoursesAdded(selectedCourses) {

    setSelectedCoursesRowsData(selectedCourses);
     
    console.log("Selected ====> Courses Added ++ ", selectedCourses);
    if (!Array.isArray(selectedCourses)){
      setSelectedCoursesDefaultIds([]);
      setIsSelectedCourse(() => {
        return !isSelectedCourse;
      });
      return;
    }
  console.log("course added");
  
    const coursesIds = selectedCourses?.map((course) => course.id);
    if(coursesIds.length===0){ 
      setSelectedCoursesDefaultIds(()=>{return [];});
    }
    else 
    { 
    setSelectedCoursesDefaultIds(()=>{return coursesIds;});
    }
    setIsSelectedCourse(() => {
      return !isSelectedCourse;
    });
    console.log("SelectedCoursesDefaultIds:: ",selectedCoursesDefaultIds); 
    
    return selectedCoursesDefaultIds
  }

  function handleCourseClick(regionId, branchId, studentId, courseId, queryId) {
    setRegionId(regionId);
    setBranchId(branchId);
    setStudentId(studentId);
    setCourseId(courseId);
    setQueryId(queryId);
    setCourseSaved(false)
    setIsFirstTime(true);
    console.log("CourseId == ", courseId);
    console.log("queryId == ", queryId);
  }

  function handleIsFirstTime(value)
  {
    setIsFirstTime(value);
  }
  function handleStudentClick(regionId, branchId, studentId, courseIds) {
    setSelectedCoursesDefaultIds(courseIds);
    setRegionId(regionId);
    setBranchId(branchId);
    setStudentId(studentId);
    setCourseId(0);
    console.log("Student == ", studentId);
  }

  function handleBranchClick(regionId, branchId, studentIds) {
    setSelectedStudentsDefaultIds(studentIds);
    setStudentId(0);
    setCourseId(0);
    setBranchId(branchId);
    setRegionId(regionId);
    console.log("Branch ID == ", branchId);
    if(studentIds.length===0)
    {
      //setIsSelectedStudent(false)
      //setBranchId(0);
    }
  }
  function handleRegionClick(regionId) {
    setRegionId(regionId);
    setBranchId(0);
    setStudentId(0);
    setCourseId(0);
    console.log("Region Id = ", regionId);
  }

  function handleCourseSaved( value)
  {
    setCourseSaved(value)
    console.log("---->  Course Saved: ", value);    
  }

  function handleResultCount(count)
  {
    setResultCount(count);
  }

  return (
    <>
    <div className="custom-scroll">
      <table>
        <tbody>
          <tr>
            <td>
              <TreeViewTracker
                id={"tree"}
                onRegionClick={handleRegionClick}
                onBranchClick={handleBranchClick}
                onStudentClick={handleStudentClick}
                onCourseClick={handleCourseClick}

                onCoursesAdded={handleCoursesAdded} 
                onStudentsAdded={handleStudentsAdded} 

                onCourseSaved={handleCourseSaved} 
                RegionId={regionId}
                BranchId={branchId}
                StudentId={studentId}
                CourseId={courseId}
                CourseSaved= {courseSaved}
                ResultCount ={resultCount}

                selectedStudentsToBeAddedToTree={selectedStudentsRowsData}
                selectedStudentsDefaultIds={selectedStudentsDefaultIds}
                isSelectedStudent={isSelectedStudent}

                selectedCoursesToBeAddedToTree={selectedCoursesRowsData}
                selectedCoursesDefaultIds={selectedCoursesDefaultIds}
                isSelectedCourse={isSelectedCourse}

              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td className="sidebyside">
              <TrackerCoursesGrid
                QueryId={queryId}
                RegionId={regionId}
                BranchId={branchId}
                StudentId={studentId}
                CourseId= { courseId}  
                onCourseSaved={handleCourseSaved}// true/false
                onResultCount ={handleResultCount}
                ResultCount ={resultCount}                                 
                selectedStudentsDefaultIds={selectedStudentsDefaultIds}
                onStudentsAdded={handleStudentsAdded} // handleCloseStudentDialog --> Grid

                selectedCoursesDefaultIds={selectedCoursesDefaultIds}
                onCoursesAdded={handleCoursesAdded} // handleCloseCourseDialog --> Grid
                IsFirstTime={isFirstTime}
                handleIsFirstTime={handleIsFirstTime}

              ></TrackerCoursesGrid>
            </td>
          </tr>
        </tbody>
      </table>
      </div>     
    </>
  );
}

export default TrackerStudentMain;
