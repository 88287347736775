import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import ImportIcon from "@mui/icons-material/PublishOutlined";
import ImportFileIcon from "@mui/icons-material/DescriptionOutlined";
import ImportTemplateIcon from "@mui/icons-material/ContentCopyOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  GridRowModes,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  DataGrid,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import { Delete, Get, Post, Put } from "../../Common/WebRequest";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import { getValue, isDate, slice, toJsonDateTime } from "../../Common/Utility";
import columnBuilder from "./columnBuilder";
import StudentPdfFilesGrid from "../../components/StudentPdfFiles/StudentPdfFilesGrid";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { GetCSV } from "../../Common/WebRequestCsv";
import StudentDialog from "./StudentDialog";
import { WebRequestPdfViewer } from "../../Common/WebRequestPdfViewer";
import { DownloadPdf } from "../../Common/WebRequestPdf";
export default function StudentsGrid() {
  const options = ["Import", "Template"];
  const [selectedRows, setSelectedRows] = useState([]);
  const CustomButton = ({ onClick, children }) => {
    return (
      <Button variant="text" onClick={onClick} startIcon={<ImportIcon />}>
        {children}
      </Button>
    );
  };

  function DefinedToolbar(props) {
    const {
      setStudentsData,
      setRowModesModel,
      handleError,
      handleSuccess,
      handleAddUpdateStudent,
    } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState("");
    let navigate = useNavigate();
    const handleAddClick = () => {
      const id = 0;
      setStudentsData((oldRows) => [
        ...oldRows,
        { id, studentName: "", regionId: 0, branchId: 0, isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "studentName" },
      }));
    };

    function handleRefresh() {
      navigate(0);
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    async function handleOptionClick(option) {
      // onOptionClick(option);
      setAnchorEl(null);

      if (option === "Import") {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".csv, .txt";
        fileInput.addEventListener("change", handleFileInputChange);
        fileInput.click();
      } else if (option === "Template") {
        await handleDownloadClick();
      }
    }

    async function handleFileInputChange(event) {
      const file = event.target.files[0];
      setSelectedFile(file);
      await handleFileLoad(file);
      /*const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsText(file);*/
    }

    async function handleFileLoad(file) {
      handleError("");
      handleSuccess("");
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        //await Post("Student/csv/upload", formData, "No Header");
        const loginData = JSON.parse(localStorage.getItem("login"));
        const token = loginData?.token;
        await fetch(global.config.URL.Path + "/Student/csv/upload", {
          headers: {
            Authorization: "Bearer " + token,
          },
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.isSuccess) {
              console.log("File uploaded successfully:", data);

              handleSuccess(data.displayMessage);
            } else {
              console.log("Error:", data);
              handleError(data.displayMessage);
            }
          })
          .catch((error) => {
            setError("Error uploading file:", error);
            console.error(error);
            handleError(error);

            // Handle the error here.
          });
      }
    }

    async function handleDownloadClick() {
      await GetCSV("Student/csv", "students-template.csv");
    }

    async function handleDownloadPDFJobCenterClick() {
      if (selectedRows.length > 0) {
        // Map to get studentName and regionCenter
        let selectedRowsData = selectedRows.map((row) => ({
          name: row.studentName,
          regionCenter: row.regionCenter,
        }));

        let downloadErrors = []; // Array to store names with errors

        for (let { name, regionCenter } of selectedRowsData) {
          let fileName = `Bestätigung_Jobcenter__${name}.pdf`;
          if (regionCenter) {
            fileName = `Bestätigung_RegionHannover__${name}.pdf`;
          }
          try {
            let errorMsg = await DownloadPdf(
              `Student/download-pdf-job-center/${fileName}`
            );
            if (errorMsg) {
              downloadErrors.push(name + "*******");
            }
          } catch (error) {
            // Store the student's name if an error occurs
            downloadErrors.push(name);
            console.error("Error fetching PDF for:", name, error);
          }
        }

        // Handle errors if there are any
        if (downloadErrors.length > 0) {
          let errorMessages = `Failed to download PDFs for: ${downloadErrors.join(
            ", "
          )}`;
          handleError(errorMessages); // Call handleError with the collected error messages
          throw new Error(errorMessages);
        }
      }
    }

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={(e) => handleAddUpdateStudent(0)}
        >
          Neu
        </Button>
        <Button
          color="primary"
          disabled={selectedRows.length === 0}
          startIcon={<DownloadIcon />}
          onClick={handleDownloadPDFJobCenterClick}
        >
          Download
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Schüler-in",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
        <CustomButton onClick={handleClick}>Import CSV</CustomButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleOptionClick(option)}>
              {option === "Import" && (
                <ListItemIcon>
                  <ImportFileIcon fontSize="small" />
                </ListItemIcon>
              )}
              {option === "Template" && (
                <ListItemIcon>
                  <ImportTemplateIcon fontSize="small" />
                </ListItemIcon>
              )}

              {option}
            </MenuItem>
          ))}
        </Menu>
        <GridToolbarQuickFilter />
        <Button
          color="primary"
          startIcon={<SyncIcon />}
          onClick={handleRefresh}
        >
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  DefinedToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setStudentsData: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    handleAddUpdateStudent: PropTypes.func.isRequired,
  };

  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [studentsData, setStudentsData] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    (async () => {
      let res = await Get("student");
      let regions = await Get("region");
      let branches = await Get("branch");

      if (res.isSuccess) {
        setStudentsData(res.result);
        setRegionData(regions.result);
        setBranchData(branches.result);
      } else setError(res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        studentsData.find((x) => x.id === id).studentName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  function onRowsSelectionHandler(ids) {
    const selectedRowsData = ids.map((id) =>
      studentsData.find((row) => row.id === id)
    );
    setSelectedRows(selectedRowsData);
  }
  const handlePdfJobCenterClick = (studentId) => async (event) => {
    event.stopPropagation();
    try {
      console.log("handlePdfJobCenterClick  click ===> ", studentId);
      if (studentId > 0) {
        let props = { path: "Student/pdf/" + studentId };
        await WebRequestPdfViewer(props);
      }
    } catch (error) {
      console.error("Error loading the PDF:", error);
      setError(error);
    }
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "studentName" },
    });
  };

  async function handleOnRowDoubleClick(params, event) {
    if (params.row) {
      setStudentId(params.row.id);
      handleAddUpdateStudent(params.row.id);
    }
  }

  async function handleAddUpdateStudent(studentId) {
    if (studentId === 0) {
      setStudentId(0);
      handleOpenDialog();
    } else if (studentId > 0) {
      handleOpenDialog();
    }
  }

  const handleOpenDialog = () => {
    //setReload(!reload);
    setReload(!reload);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveStudentData = () => {
    setReload(!reload);
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setStudentsData(
      studentsData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      if (isDate(updatedRow.birthDate))
        updatedRow.birthDate = toJsonDateTime(updatedRow.birthDate);
      if (isDate(updatedRow.expiryDate))
        updatedRow.expiryDate = toJsonDateTime(updatedRow.expiryDate);

      if (getValue(updatedRow.zip) === 0) updatedRow.zip = 0;

      res = await Post("student", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      if (isDate(newRow.birthDate))
        newRow.birthDate = toJsonDateTime(newRow.birthDate);
      if (isDate(newRow.expiryDate))
        newRow.expiryDate = toJsonDateTime(newRow.expiryDate);
      if (getValue(updatedRow.zip) === 0) updatedRow.zip = 0;
      res = await Put("student", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Der neue Schüler/in wurde hinzugefügt.")
        : setSuccessMsg("Der Schüler/in wurde aktualisiert.");
    } else {
      setError(res.Error + "/" + res.ErrorDetail);
      setReload(!reload);
      console.log("Error: ##### ", res.Error);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("student", id);
    if (!res.isSuccess) {
      setError(res.Error + "/" + res.ErrorDetail);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Der Schüler/in wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = studentsData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setStudentsData(studentsData.filter((row) => row.id !== id));
    }
  };

  const handleError = (err) => {
    console.log("handleError");

    console.log(err);
    setError(err);
  };

  const handleSuccess = (successMessage) => {
    setSuccessMsg(successMessage);
    setReload(!reload);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new student:", newRow);
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  async function handleRowClick(params, event) {
    //event.defaultMuiPrevented = true;
    if (params.row) {
      setStudentId(() => {
        return params.row.id;
      });
    }
    console.log("Row clicked: Row=", params.row.id);
  }
  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <h2>Schüler/in</h2>
      <br></br>
      {error.length > 0 && !error.includes("No Results") && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 150, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      <Box
        sx={{
          width: "100%",
          height: "600px",
          "& .Mui-even": {
            backgroundColor: "#f3f7ff",
          },
          "& .Mui-odd": {
            backgroundColor: "#EEEEEE",
          },
          "& .MuiDataGrid-cell:hover": {
            backgroundColor: "#80b3ff",
          },
          "&& .MuiDataGrid-row:hover": {
            backgroundColor: "#cce0ff",
            /*  "@media (hover: none)": {
              backgroundColor: "transparent",
            },*/
            // color: "red"
          },
        }}
      >
        {" "}
        <StudentDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleSaveStudentData}
          reload={!reload}
          StudentId={studentId}
        />
        <DataGrid
          rowSelection={true}
          checkboxSelection
          disableRowSelectionOnClick
          density="compact"
          rows={studentsData}
          columns={columnBuilder({
            GridRowModes,
            rowModesModel,
            handlePdfJobCenterClick,
            handleEditClick,
            handleCancelClick,
            handleDeleteClick,
            handleSaveClick,
            branchData,
            regionData,
          })}
          editMode="row"
          rowsPerPageOptions={10}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100, page: 0 },
            },
            filter: {
              filterModel: {
                items: [
                  { field: "studentName", operator: "contains", value: "" },
                ],
              },
            },
          }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          onRowDoubleClick={handleOnRowDoubleClick}
          //  onEditCellChange={handleEditCellChange}
          //onCellEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleError}
          slots={{
            toolbar: DefinedToolbar,
          }}
          slotProps={{
            toolbar: {
              setStudentsData,
              setRowModesModel,
              handleError,
              handleSuccess,
              handleAddUpdateStudent,
            },
          }}
          onRowClick={handleRowClick}
          getRowId={(row) => row.id}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "250px",
        }}
      >
        <StudentPdfFilesGrid StudentId={studentId}></StudentPdfFilesGrid>
      </Box>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
