import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  DataGrid,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import { Delete, Get, Post, Put } from "../../Common/WebRequest";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { useNavigate } from "react-router-dom";
import { isDate, showDateOnly, slice } from "../../Common/Utility";

function EditToolbar(props) {
  const { setInvoicesNumbersData, setRowModesModel } = props;

  let navigate = useNavigate();
  const handleAddClick = () => {
    const id = 0;
    setInvoicesNumbersData((oldRows) => [
      ...oldRows,
      { id, invoicesNumberName: "", branchId: 0, isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "invoicesNumberName" },
    }));
  };

  function handleRefresh() {
    //navigate(setInvoicesNumbersData(Get("invoicesNumber".result)));
    // setRefresh(!refresh);
    navigate(0);
  }

  function handleSaveClicks() {
    console.log("Save Click ==>");
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
        Neu
      </Button>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: "RechnungsNummerListe",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />

      <GridToolbarQuickFilter />
      <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setInvoicesNumbersData: PropTypes.func.isRequired,
};

export default function InvoicesNumbersGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [invoicesNumbersData, setInvoicesNumbersData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  const [branchData, setBranchData] = useState([]);

  useEffect(() => {
    (async () => {
      let res = await Get("Rechnungsnummer");
      let branches = await Get("branch");

      if (res.isSuccess) {
        setInvoicesNumbersData(res.result);
        setBranchData(branches.result);
      } else setError(res.Status + "/" + res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        invoicesNumbersData.find((x) => x.id === id).invoicesNumberName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    console.log("Cell lost focus / exit  edit ==> ", params, event);
    //console.log("event.target.value :: ******** ", params, event.target.value);
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "invoicesNumberName" },
    });
    console.log("## Edit clicked -- id=", id);
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setInvoicesNumbersData(
      invoicesNumbersData.map((row) =>
        row.id === newRow.id ? updatedRow : row
      )
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      res = await Post("Rechnungsnummer", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      res = await Put("Rechnungsnummer", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Der neue Rechnungsnummer wurde hinzugefügt.")
        : setSuccessMsg("Der Rechnungsnummer wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error);
      console.log("Error: ##### ", res.Error);
      setReload(!reload);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
    //setInvoicesNumbersData(invoicesNumbersData.filter((row) => row.id !== id));
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("Rechnungsnummer", id);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);

      setReload(!reload);
    } else {
      setSuccessMsg("Der Rechnungsnummer wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = invoicesNumbersData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setInvoicesNumbersData(
        invoicesNumbersData.filter((row) => row.id !== id)
      );
    }
  };
  const handleError = (err) => {
    console.log("handleError");

    console.log(err);
    setError(err.message);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();
    // await apiRef.current.stopRowEditMode({ id });
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new invoicesNumber:", newRow);
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);
    /* setInvoicesNumbersData(
      invoicesNumbersData.map((invoicesNumber) => {
        if (invoicesNumber.id === newRow.id) return newRow;
        return invoicesNumber;
      })
    );*/

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  function handleRegionValue(e) {
    setInvoicesNumbersData({
      ...invoicesNumbersData,
      region: {
        id: e.target.value,
        regionName: e.target.options[e.target.selectedIndex].label,
      },
    });
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70, editable: false },

    {
      field: "branchId",
      headerName: "Abrechnungstelle *",
      type: "singleSelect",
      editable: true,
      width: 250,

      getOptionValue: (branch) => branch?.id,
      getOptionLabel: (branch) => branch?.branchName,
      valueOptions: branchData /* loaded branches from GET API*/,

      valueFormatter: function (params) {
        if (branchData) {
          let branch = branchData.find((branch) => branch.id === params.value);
          if (typeof branch?.branchName !== "undefined")
            return branch.branchName;
        }
        //return "";
      },
    },

    {
      field: "rechnungsnummer",
      headerName: "Rechnungsnummer *",
      width: 280,
      type: "text",
      editable: true,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 100,
      type: "text",
      editable: false,
    },

    {
      field: "userDate",
      headerName: "Datum",
      width: 110,
      type: "date",
      editable: false,
      valueFormatter: function (params) {
        let userDate = params.value;
        if (isDate(userDate)) {
          return showDateOnly(userDate);
        }
        //return showDateOnly(params.row.birthDate);
      },
    },
    {
      field: "isActive",
      headerName: "Aktiv *",
      width: 80,
      type: "boolean",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("invoicesNumbersData ", invoicesNumbersData);

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <h2>Rechnungsnummer</h2>
      <br></br>
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 150, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      <DataGrid
        rowSelection={false}
        density="compact"
        hideFooterPagination
        rows={invoicesNumbersData}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={5}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        //onCellEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setInvoicesNumbersData, setRowModesModel },
        }}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
