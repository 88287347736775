import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {} from "@mui/x-data-grid";
import EditableDataTable from "../EditableDataTable/EditableDataTable";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../../Common/WebRequest";
import { getValue, slice } from "../../../Common/Utility";
import StudentsDialog from "../CourseAndStudentDialog/StudentsDialog";
import CourseDialog from "../CourseAndStudentDialog/CourseDialog";
import CourseIcon from "@mui/icons-material/AutoStories";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import "./TrackerHeader.css";

export default function TrackerCoursesGrid(props) {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [branchName, setBranchName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseSavedError, setCourseSavedError] = useState("");
  const [openStudentDialog, setOpenStudentDialog] = useState(false);
  const [openCourseDialog, setOpenCourseDialog] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    (async () => {
      setSuccessMsg("");
      setCourseSavedError("");
      setBranchName("");
      setStudentName("");
      setCourseName("");

      if (props.BranchId > 0) {
        let res = await Get("Branch", props.BranchId);

        if (res.isSuccess) {
          setBranchName(res.result.branchName);
        }
      }
      if (props.StudentId > 0) {
        let res = await Get("Student", props.StudentId);

        if (res.isSuccess) {
          setStudentName(res.result.studentName);
        }
      }
      if (props.CourseId > 0) {
        let res = await Get("Course", props.CourseId);

        if (res.isSuccess) {
          setCourseName(res.result.courseName);
        } else setCourseName("");
      }
      if (props.BranchId === 0 && props.RegionId > 0) {
        let res = await Get("Region", props.RegionId);

        if (res.isSuccess) {
          setBranchName(res.result.regionName);
        }
      }
    })();
  }, [
    props.RegionId,
    props.BranchId,
    props.StudentId,
    props.CourseId,
    props.selectedCoursesDefaultIds,
    visible,
  ]);
  const handleClickOpenStudentDialog = () => {
    setOpenStudentDialog(true);
  };

  // ===> selectedRowsData
  // 1- close student-Dialog
  // 2- receive selectedStudents  from student-Dialog
  // 3- pass it to Main Component. (TrackerStudentMain)
  const handleCloseStudentDialog = (selectedStudents) => {
    setOpenStudentDialog(false);
    props.onStudentsAdded(selectedStudents);
  };
  const handleCancelStudentDialog = () => {
    setOpenStudentDialog(false);
  };

  const handleClickOpenCourseDialog = () => {
    setOpenCourseDialog(true);
  };

  const handleCloseCourseDialog = (selectedCourses) => {
    setOpenCourseDialog(false);
    props.onCoursesAdded(selectedCourses);
  };

  const handleCancelCourseDialog = () => {
    setOpenCourseDialog(false);
  };

  const handleCourseSaved = (isSaved) => {
    props.onCourseSaved(isSaved);
    if (isSaved) {
      setSuccessMsg("Der Datensatz wurde gespeichert!");
      setCourseSavedError("");
    }
  };
  const hadleCourseSavedError = (error) => {
    setSuccessMsg("");
    setCourseSavedError(error);
  };

  const handleResultCount = (count) => {
    props.onResultCount(count);
    // setVisible(count > 0);
  };

  return (
    <Box
      sx={{
        marginTop: "10px",
        marginLeft: "10px",
        height: "100%",
        width: "100%",
        border: "2px solid #ccc",
        borderRadius: 2,
        paddingBottom: "30px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="panel">
        <span className="tag tag-black">{branchName}</span>

        {branchName !== "" && (
          <span className="tag tag-blue">{studentName}</span>
        )}
        {branchName !== "" && <span className="tag tag-red">{courseName}</span>}
      </div>
      {courseSavedError.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {slice(courseSavedError, 200, "...")}
        </div>
      )}
      {courseSavedError.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      &nbsp;
      <Button
        disabled={getValue(props.BranchId) === 0}
        variant="contained"
        onClick={handleClickOpenStudentDialog}
        endIcon={<PersonAddIcon />}
      >
        + Schüler/in hinzufügen
      </Button>
      &nbsp;&nbsp;
      <Button
        disabled={getValue(props.StudentId) === 0}
        onClick={handleClickOpenCourseDialog}
        variant="contained"
        endIcon={<CourseIcon />}
      >
        + Kurs hinzufügen
      </Button>
      <StudentsDialog
        open={openStudentDialog}
        currBranchId={props.BranchId}
        handleClose={handleCloseStudentDialog}
        handelCancel={handleCancelStudentDialog}
        selectedStudentsDefaultIds={props.selectedStudentsDefaultIds}
      />
      <CourseDialog
        open={openCourseDialog}
        currStudentId={props.StudentId}
        handleClose={handleCloseCourseDialog}
        handelCancel={handleCancelCourseDialog}
        selectedCoursesDefaultIds={props.selectedCoursesDefaultIds}
      />
      <EditableDataTable
        Visible={visible}
        Disabled={getValue(props.CourseId) === 0}
        RegionId={props.RegionId}
        BranchId={props.BranchId}
        StudentId={props.StudentId}
        CourseId={props.CourseId}
        handleSaved={handleCourseSaved}
        //CourseSaved={props.CourseSaved}
        handleSavedError={hadleCourseSavedError}
        handleResultCount={handleResultCount}
        IsFirstTime={props.IsFirstTime}
        handleIsFirstTime={props.handleIsFirstTime}
      />
    </Box>
  );
}
