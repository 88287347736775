import PropTypes from "prop-types";
import Box from "@mui/material/Box";
//import makeStyles from "@material-ui/styles";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";

import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  DataGrid,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import { Delete, Get, Post, Put } from "../../Common/WebRequest";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { useNavigate } from "react-router-dom";
import { slice } from "../../Common/Utility";

//-------------------------------------------------------- style
/*const useStyles = makeStyles((theme) => ({
  userCell: {
    "& > div": {
      color: theme.palette.error.main,
    },
  },
}));*/
function EditToolbar(props) {
  const { setCoursesData, setRowModesModel } = props;

  let navigate = useNavigate();
  const handleAddClick = () => {
    const id = 0;
    setCoursesData((oldRows) => [
      ...oldRows,
      { id, courseName: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "courseName" },
    }));
  };

  function handleRefresh() {
    //navigate(setCoursesData(Get("course".result)));
    // setRefresh(!refresh);
    navigate(0);
  }

  function handleSaveClicks() {
    console.log("Save Click ==>");
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
        Neu
      </Button>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector>
        <GridToolbarDensitySelector />
      </GridToolbarDensitySelector>
      <GridToolbarExport
        csvOptions={{
          fileName: "Nachhilfefächer",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />

      <GridToolbarQuickFilter />
      <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setCoursesData: PropTypes.func.isRequired,
};

export default function CoursesGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [coursesData, setCoursesData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    (async () => {
      let res = await Get("course");

      if (res.isSuccess) setCoursesData(res.result);
      else setError(res.Status + "/" + res.Error);
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setType(type);
    setId(id);

    setDeleteMessage(
      `Möchten Sie „${
        coursesData.find((x) => x.id === id).courseName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    console.log("Cell lost focus / exit  edit ==> ", params, event);
    //console.log("event.target.value :: ******** ", params, event.target.value);
  };

  const handleEditClick = (id) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "courseName" },
    });
    console.log("## Edit clicked -- id=", id);
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setCoursesData(
      coursesData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    if (updatedRow.id === 0) {
      updatedRow.isNew = true;
      res = await Post("course", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      res = await Put("course", updatedRow.id, newRow);

      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      setReload(!reload);
      updatedRow.isNew
        ? setSuccessMsg("Das neue Fach wurde hinzugefügt.")
        : setSuccessMsg("Das Fach wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      console.log("Error: ##### ", res.Error);
    }
  }
  const handleDeleteClick = (id) => () => {
    showDeleteModal("", id);
    //setCoursesData(coursesData.filter((row) => row.id !== id));
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("course", id);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Die Kurs wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = coursesData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setCoursesData(coursesData.filter((row) => row.id !== id));
    }
  };
  const handleError = (err) => {
    console.log("handleError");

    console.log(err);
    setError(err.message);
  };
  const handleSaveClick = (id) => async (event) => {
    event.stopPropagation();
    // await apiRef.current.stopRowEditMode({ id });
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log("Save click ===> ", id);
  };
  async function processRowUpdate(newRow) {
    console.log("new course:", newRow);
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);
    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //const classes = useStyles();
  const columns = [
    { field: "id", headerName: "ID", width: 50, editable: false },
    {
      field: "courseName",
      headerName: "Fach *",
      type: "text",
      editable: true,
      flex: 1,
      /*cellClassName: classes.userCell,
      renderCell: ({ row }) => <Box lineHeight="normal">{row.courseName}</Box>,*/
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("coursesData ", coursesData);

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <h2>Nachhilfefächer</h2>
      <br></br>
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 100, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br></br>
      <DataGrid
        density="compact"
        rowSelection={false}
        rows={coursesData}
        columns={columns}
        editMode="row"
        hideFooterPagination
        rowsPerPageOptions={20}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 20 },
          },
          filter: {
            filterModel: {
              items: [{ field: "courseName", operator: "contains", value: "" }],
            },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        //onCellEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setCoursesData, setRowModesModel },
        }}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={id}
        message={deleteMessage}
      />
    </Box>
  );
}
