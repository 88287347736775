import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../Common/WebRequest";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { generateUUID, getValue, slice } from "../../Common/Utility";
import columnBuilder from "./columnBuilder";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DropDownBranch from "../../components/DropDown/Branch/DropDownBranch";
import { blue } from "@mui/material/colors";
import DropDownRegion from "../../components/DropDown/Region/DropDownRegion";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import { WebRequestPdfViewer } from "../../Common/WebRequestPdfViewer";

export const PdfFilesHistory = () => {
  let navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [regionId, setRegionId] = useState(-1);
  const [branchId, setBranchId] = useState(-1);
  const [error, setError] = useState("");

  const [pdfHistorysData, setpdfHistorysData] = useState([]);

  let withAll = true; // @ALL   --> Region:@All , Branch:@All
  function doFilter(res) {
    const filteredPdfHistories =
      branchId === -1
        ? res
        : res.filter((students) => students.branchId === branchId);

    return filteredPdfHistories;
  }

  function handleRefresh() {
    navigate(0);
  }

  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "PDF-Dateien",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
          <Button
            color="primary"
            startIcon={<SyncIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  }
  useEffect(() => {
    (async () => {
      let res = await Get(
        "Calculation/PdfHistory/" + regionId + "/" + branchId
      );

      if (res.isSuccess) {
        setpdfHistorysData(doFilter(res.result));
      } else setError(res.Status + "/" + res.Error);
    })();
  }, [regionId, branchId]);

  async function handleOnCellClick(params) {
    //apiRef.current.selectRow(params.rechnungsnummer);
    if (params.field === "pdfFileName") {
      //  handleDownloadPdfFile(params.value);
      let fileName = params.value;
      if (fileName.length > 0) {
        let props = { path: "Calculation/pdf/" + fileName };
        await WebRequestPdfViewer(props);
      }
    }
  }
  function handleRegionValue(e) {
    setRegionId((currentRegionId) => {
      return getValue(e.target.value);
    });
    //setStudentName("");
    if (withAll) {
      //-1  is : @All
      setBranchId(-1);
    }
    setpdfHistorysData([]);
    setError("");
  }
  function handleBranchValue(e) {
    setBranchId((currentBranchId) => {
      return getValue(e.target.value);
    });
    //setStudentName("");
    console.log("Selected Branch = ", branchId);
    setpdfHistorysData([]);
    setError("");
  }
  function handleOnBranchPopulate(branchIds) {
    if (branchIds.length > 0) {
      setBranchId(branchIds[0]);
      console.log("handleOnPopulate: ", branchIds);
    }
    //setBranchesIds(branches);
  }
  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br></br>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            color={blue[500]}
          >
            PDF-Dateien
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2 }}
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Region
          </Typography>
          <FormControl>
            <DropDownRegion
              regionID={regionId}
              onChange={handleRegionValue}
              withAll={withAll}
              // onPopulate={handleOnPopulate}
            />
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginRight: 2 }}
          >
            Abrechnungstelle
          </Typography>
          <FormControl>
            <DropDownBranch
              regionID={regionId}
              branchID={branchId}
              onChange={handleBranchValue}
              onPopulate={handleOnBranchPopulate}
              withAll={withAll}
            />
          </FormControl>
        </Box>
      </Box>
      <br />
      <br></br>
      {error.length > 0 && !error.includes("No Results") && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 150, "...")}
        </div>
      )}
      <DataGrid
        rowSelection={false}
        density="compact"
        rows={pdfHistorysData}
        columns={columnBuilder()}
        onCellClick={handleOnCellClick}
        rowsPerPageOptions={10}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
          filter: {
            filterModel: {
              items: [
                { field: "studentName", operator: "contains", value: "" },
              ],
            },
          },
        }}
        slots={{ toolbar: DefinedToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        //   onRowClick={handleRowClick}
        getRowId={(row) => generateUUID()}
      />
    </Box>
  );
};
